import React from "react";
import options from "./options";
import { route, purge_user, select_dashboard_item } from "../../redux/actions";
import { connect } from "react-redux";
import axios from "axios";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import LogoLoader from "../../components/LogoLoader";

class MenuMobile extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loggingOut: Boolean indicating whether the user is in the process of logging out
       * loaded: Boolean indicating whether userInfo has been loaded into application state
       */
      menuShown: false,
      loggingOut: false,
      loaded: false,
    };
  }

  componentDidMount() {
    /**
     * Set bootstrap offCanvas into state so that it can be toggled open/closed at will
     * Check if user info exists in application state. If so, load
     */
    if (!this.state.loaded && this.props.userInfo.role) this.load();
  }

  componentDidUpdate() {
    /**
     * When component updates, check if user info exists in application state
     * If so, and component is not already loaded, load
     */
    if (!this.state.loaded && this.props.userInfo.role) this.load();
  }

  load = () =>
    this.setState({
      ...this.state,
      loaded: true,
    });

  selectOption = (option) => {
    /**
     * Fired when the user selects a menu item
     * Put the selection into state, then hide the menu
     */
    this.props.select_dashboard_item(option);
    this.state.offCanvas.hide();
  };

  logout = () => {
    /**
     * Fired when the user clicks the logout button at the bottom
     * Purge the user and redirect to the login page
     */
    if (!this.state.loggingOut)
      this.setState(
        {
          ...this.state,
          loggingOut: true,
        },
        () =>
          axios
            .post("/auth/logout")
            .then(() => {
              this.state.offCanvas.hide();
              this.props.purge_user();
              this.props.route("/login");
            })
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  loggingOut: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  setShowMenu = (option) =>
    this.setState({
      ...this.state,
      menuShown: option,
    });

  toggleShowMenu = () =>
    this.setState({
      ...this.state,
      menuShown: !this.state.menuShown,
    });

  selectItem = (item) =>
    this.setState(
      {
        ...this.state,
        menuShown: false,
      },
      () => this.props.select_dashboard_item(item)
    );

  render() {
    return (
      <>
        <MDBBtn
          className="mb-4"
          onClick={() => this.setShowMenu(true)}
          style={{ backgroundColor: "#607D8B" }}
          size="lg"
        >
          <i className="fas fa-bars me-2"></i>
          {this.props.dashboardItemSelected.toUpperCase()}
        </MDBBtn>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            animationDirection="left"
            show={this.state.menuShown}
            setShow={this.state.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="fullscreen">
              <MDBModalContent className="h-100 d-flex flex-column">
                <MDBModalHeader>
                  <MDBModalTitle>Dashboard</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => this.setShowMenu(false)}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="fg-1">
                  {this.props.userInfo.role && this.state.loaded ? (
                    <MDBListGroup>
                      {options
                        .find((o) => o.category === this.props.userInfo.role)
                        .options.map((option) => (
                          <MDBRipple>
                            <MDBListGroupItem
                              onClick={() => this.selectItem(option.id)}
                              action
                              active={
                                option.id === this.props.dashboardItemSelected
                              }
                              className="cursor-pointer"
                            >
                              {option.icon}
                              {option.text}
                            </MDBListGroupItem>
                          </MDBRipple>
                        ))}
                    </MDBListGroup>
                  ) : (
                    <div className="d-flex justify-content-center mt-2">
                      <LogoLoader />
                    </div>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  {this.state.loggingOut ? (
                    <MDBBtn
                      color="danger"
                      size="lg"
                      block
                      className="w-100"
                      disabled
                    >
                      <Spinner size="sm" className="me-2" />
                      Working
                    </MDBBtn>
                  ) : (
                    <MDBBtn
                      onClick={this.logout}
                      color="danger"
                      size="lg"
                      block
                      className="w-100"
                    >
                      Logout
                    </MDBBtn>
                  )}
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  purge_user,
  select_dashboard_item,
})(MenuMobile);
