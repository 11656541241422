import React from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { connect } from "react-redux";
import { route } from "../redux/actions";
import { forgot_password_schema } from "../utilities/validations";
import t from "../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import Spinner from "../components/Spinner";

/**
 * This is the Forgot Password page
 * The user enters their username and email here to receive an email with instructions for resetting their password
 */

/**
 * Initial state
 * ChangeHandler
 * fire changehandler on mount
 * change validation
 * validate on submit
 * change to form
 */

const fields = [
  {
    text: "Username",
    id: "username",
  },
  {
    text: "Email Address",
    id: "email",
  },
];

class ForgotPassword extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * working: Boolean indicating whether the request is pending on the server
       * exit: framer-motion exit transition
       */
      working: false,
      exit: this.getExit(props),
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
    };
  }

  componentDidMount() {
    /**
     * Set exit transition to default values upon mount
     */
    this.setState(
      {
        ...this.state,
        exit: t.fade_out,
      },
      () =>
        this.changeHandler({
          target: {
            name: "",
          },
        })
    );
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  getExit = (props) => {
    /**
     * If the user is coming from the login page, fade in from the right
     * Otherwise, fade in
     */
    const h = props.historyStack;
    if (h[h.length - 1] === "/login") return t.fade_out_right;
    else return t.fade_out;
  };

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.submit();
  };

  submit = () => {
    /**
     * Make sure password reset request is not already in the process of being made
     * Remove Invalid Feedback from previous unsuccessful attempts, if any
     * Create object from user input
     * Validate the object
     * If object is invalid, add Invalid Feedback for all invalid entries
     * Create form data object, send to server
     * If successful, redirect to /check-email
     * If unsuccessful, alert general error
     */
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            forgot_password_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await h.getRecaptcha(
              this.props.googleReCaptchaProps
            );
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append("captchaKey", captchaKey);
            axios
              .post("/auth/forgot-password", fd)
              .then((res) => {
                if (res.data.success) this.props.route("/check-email");
                else
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => alert(res.data.error)
                  );
              })
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  back = () =>
    this.setState(
      {
        /**
         * Fired when the user clicks the Back button
         * Set the component to fade out to the right, then navigate to the intended endpoint
         */
        ...this.state,
        exit: t.fade_out_right,
      },
      () => this.props.route("/login")
    );

  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          forgot_password_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error);
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );

  pressEnter = (e) => {
    /**
     *Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.submit();
  };

  render() {
    return (
      <motion.div
        className="py-4"
        transition={t.transition}
        exit={this.state.exit}
        animate={t.normalize}
        initial={this.state.exit}
      >
        <MDBContainer>
          <h1 className="display-4 text-center">Forgot Password</h1>
          <hr></hr>
          <div className="mx-auto mt-2 form-containers">
            <MDBValidation id="form" onSubmit={this.submit}>
              {fields.map((i) => (
                <MDBValidationItem
                  key={i.id}
                  className="pb-4"
                  feedback={
                    this.state.inputs.find((input) => input.id === i.id).error
                  }
                  invalid={true}
                >
                  <MDBInput
                    name={i.id}
                    onChange={this.changeHandler}
                    id={i.id}
                    label={i.text}
                    size="lg"
                    className={
                      !this.state.inputs.find((input) => input.id === i.id)
                        .invalid
                        ? "mb-0"
                        : 0
                    }
                    onKeyPress={this.pressEnter}
                  />
                </MDBValidationItem>
              ))}
            </MDBValidation>
            {this.state.working ? (
              <MDBBtn
                color="primary"
                size="lg"
                className="w-100"
                block
                disabled
              >
                <Spinner size="sm" className="me-2" />
                Working
              </MDBBtn>
            ) : (
              <MDBBtn
                color="primary"
                onClick={this.submit}
                size="lg"
                block
                className="w-100"
              >
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
            <MDBBtn
              onClick={this.back}
              color="dark"
              size="lg"
              className="w-100 mt-4"
              block
            >
              <i className="fas fa-chevron-left me-2"></i>Back
            </MDBBtn>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(
  withGoogleReCaptcha(ForgotPassword)
);
