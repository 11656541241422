import React from "react";
import { connect } from "react-redux";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBTooltip,
  MDBContainer,
  MDBBtn,
  MDBBadge,
  MDBInputGroup,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import h from "../../utilities/helpers";
import Report from "./content/Report";
import Remove from "./content/Remove";
import RemoveManifesto from "./content/RemoveManifesto";
import RestoreModal from "./content/RestoreModal";
import axios from "axios";
import { Link } from "react-router-dom";
import { update_image, set_comment_page, route } from "../../redux/actions";
import LogoLoader from "../../components/LogoLoader";
import { Buffer } from "safe-buffer";

class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * bbCopied: Boolean - Whether the bbCode with the image link has been copied
       * directLinkCopied: Boolean - Whether the direct link to the image has been copied
       * reportOpen: Boolean - Whether the Report Image form is opened
       * removeOpen: Boolean - Whether the Remove Image form is opened
       * removeManifesto: Boolean - Whether the Remove Manifesto form is opened
       * imageReported: Boolean - Whether the image has been reported
       * imageRemoved: Boolean - Whether the image has been removed
       * modalShown: Boolean - Whether the Restore Image modal is shown
       * manifestoRestoring: Boolean - Whether the manifesto is in the process of being restored
       * tooltipComment: tooltipComment: JSX - Content of comment with comment_id state.commentHover
       * commentHover: Number - comment_id of quoted comment that the user is hovering over with their mouse
       */
      bbCopied: false,
      directLinkCopied: false,
      reportOpen: false,
      removeOpen: false,
      removeManifestoOpen: false,
      imageReported: false,
      imageRemoved: false,
      modalShown: false,
      manifestoRestoring: false,
      tooltipComment: <></>,
      commentHover: 0,
      playing: false,
      playButtonSize: 0,
      videoDimensions: {
        height: 0,
        width: 0,
      },
      downloading: false,
    };
  }

  /**
   * Copies the direct link of the image (or video) to the clipboard
   */
  copyDirectLink = () => {
    const string =
      this.props.imageInfo.image_id === 4506
        ? "https://feednana.com/fatfuck5.mp4"
        : document.getElementById("direct-link").value;
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed";
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const copyHotkey = isMac ? "⌘C" : "CTRL+C";
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      directLinkCopied: true,
    });
  };

  /**
   * Copies the bbCode of the image to the clipboard
   */
  copyBBCode = () => {
    const string = document.getElementById("bb-code").value;
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed";
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const copyHotkey = isMac ? "⌘C" : "CTRL+C";
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      bbCopied: true,
    });
  };

  /**
   *
   * @param {Event Object} e - Javascript click event or similar
   * @param {String} user - Username of user that was clicked
   *
   * Fired when a user is clicked
   * Navigates to that user's profile
   */
  goToProfile = (e, user) => {
    e.preventDefault();
    this.props.route(`/u/${user}`);
  };

  /**
   *
   * @param {Object} comment - Comments document
   * @returns The user's name, along with a badge to indicate their role
   */
  getBadge = () => {
    switch (this.props.imageInfo.badge) {
      case "Chadmin":
        return (
          <span className="name-chadmin">
            <Link
              style={{ color: "var(--mdb-yellow)" }}
              onClick={(e) => this.goToProfile(e, this.props.imageInfo.user)}
              to={`/u/${this.props.imageInfo.user}`}
            >
              {this.props.imageInfo.user}
            </Link>
            <MDBBadge className="ms-2 badge-chadmin">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/meltrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Chadmin</p>
                {this.props.imageInfo.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Janny":
        return (
          <span className="name-janny">
            <Link
              style={{ color: "var(--mdb-green)" }}
              onClick={(e) => this.goToProfile(e, this.props.imageInfo.user)}
              to={`/u/${this.props.imageInfo.user}`}
            >
              {this.props.imageInfo.user}
            </Link>
            <MDBBadge className="ms-2 badge-janny">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/thomastrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Janny</p>
                {this.props.imageInfo.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Verified":
        return (
          <span className="name-verified">
            <Link
              style={{ color: "rgb(28, 202, 255)" }}
              onClick={(e) => this.goToProfile(e, this.props.imageInfo.user)}
              to={`/u/${this.props.imageInfo.user}`}
            >
              {this.props.imageInfo.user}
            </Link>
            <MDBBadge className="ms-2 badge-verified">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/verifiedlogotrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Verified</p>
                {this.props.imageInfo.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      default:
        console.log("oob badge", this.props.imageInfo.badge);
        return <></>;
    }
  };

  toggleRemoveForm = () =>
    this.setState({
      ...this.state,
      removeOpen: !this.state.removeOpen,
    });

  toggleRemoveManifestoForm = () =>
    this.setState({
      ...this.state,
      removeManifestoOpen: !this.state.removeManifestoOpen,
    });

  toggleReportForm = () =>
    this.setState({
      ...this.state,
      reportOpen: !this.state.reportOpen,
    });

  setImageReported = () =>
    this.setState({
      ...this.state,
      imageReported: true,
    });

  setShowModal = (option) =>
    this.setState({
      ...this.state,
      modalShown: option,
    });

  toggleShowModal = () =>
    this.setState({
      ...this.state,
      modalShown: !this.state.modalShown,
    });

  /**
   * Fired when the user restores a removed manifesto
   * Only runs if identical process if not already running
   * Makes request to server
   * Updates image in application state
   * Emits mod-action socket event
   */
  restoreManifesto = () => {
    if (!this.state.manifestoRestoring)
      this.setState(
        {
          ...this.state,
          manifestoRestoring: true,
        },
        () =>
          axios
            .post(
              h.checkJanny(this.props.userInfo)
                ? "/support/restore/manifesto"
                : "/support/restore/manifesto-own",
              {
                commentSection: this.props.imageInfo.commentSection,
                imageID: this.props.imageInfo.image_id,
              }
            )
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  manifestoRestoring: false,
                },
                () => {
                  this.props.update_image(res.data.image);
                  this.props.socket.emit("mod-action");
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  manifestoRestoring: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  renderCommentAvatar = (comment) => {
    if (comment.thumbnail)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <div
            className="fit-images"
            style={{
              backgroundImage: `url("/api/image-id/${comment.avatar}")`,
            }}
          ></div>
        </div>
      );

    if (comment.avatar_type)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <h6 className="file-labels-generic">{comment.avatar_type}</h6>
        </div>
      );

    if (comment.avatar)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <div
            className="fit-images"
            style={{
              backgroundImage: `url("/api/image-id/${comment.avatar}")`,
            }}
          ></div>
        </div>
      );

    return <></>;
  };

  /**
   *
   * @param {Number} postNumber - ref Comments.comment_id
   *
   * Renders a comment that was quoted with the double hashes (##)
   * If the comment is in the comment section, pull the comment data from state and render
   * Else, render a spinner while the comment data is fetched from the server
   */
  renderTooltipComment = (postNumber) => {
    let comment = this.props.imageInfo.comments.find(
      (comment) => comment.comment_id === postNumber
    );
    if (comment)
      this.setState({
        ...this.state,
        commentHover: postNumber,
        tooltipComment: (
          <>
            {comment.removed && !h.checkJanny(this.props.userInfo) ? (
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard
                  className={`mx-auto ${comment.removed ? "card-removed" : ""}`}
                >
                  <MDBCardBody className="d-flex">
                    <MDBCardText
                      className={`messages text-break ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      Comment Removed
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            ) : (
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard
                  className={`mx-auto ${comment.removed ? "card-removed" : ""}`}
                >
                  <MDBCardHeader className="d-flex align-items-end">
                    <MDBCardTitle
                      className={`mb-0 ${comment.removed ? "text-light" : ""}`}
                    >
                      {comment.badge ? (
                        this.getBadge(comment)
                      ) : (
                        <>
                          <span
                            className={
                              comment.removed ? "text-light" : "text-secondary"
                            }
                          >
                            {comment.name}
                          </span>{" "}
                          (ID:{" "}
                          <span
                            className="text-light"
                            style={{
                              backgroundColor: `var(${comment.poster_css_color})`,
                            }}
                          >
                            {comment.poster_id}
                          </span>
                          )
                        </>
                      )}
                      <span className="mb-0 ms-2">
                        {h.makeDateHR(new Date(comment.timestamp))} at{" "}
                        {h.getTimeHR(new Date(comment.timestamp))}
                      </span>
                    </MDBCardTitle>
                    <MDBCardTitle
                      className={`ms-2 mb-0 text-gb ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      #{comment.comment_id}
                    </MDBCardTitle>
                  </MDBCardHeader>
                  <MDBCardBody className="d-flex">
                    {this.renderCommentAvatar(comment)}
                    <MDBCardText
                      className={`messages text-break ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      {this.parseComment_displayOnly(comment.comment_text)}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            )}
          </>
        ),
      });
    else
      this.setState(
        {
          ...this.state,
          commentHover: postNumber,
          tooltipComment: (
            <>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard className="mx-auto">
                  <MDBCardBody className="d-flex justify-content-center align-items-center">
                    <LogoLoader />
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            </>
          ),
        },
        () =>
          axios
            .get(`/api/comment/${postNumber}`)
            .then((res) => {
              comment = res.data.comment;
              if (this.state.commentHover === postNumber)
                this.setState({
                  ...this.state,
                  tooltipComment: (
                    <>
                      {comment.removed && !h.checkJanny(this.props.userInfo) ? (
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard
                            className={`mx-auto ${
                              comment.removed ? "card-removed" : ""
                            }`}
                          >
                            <MDBCardBody className="d-flex">
                              <h5 className="text-center text-light p-0">
                                Comment removed
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      ) : (
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard
                            className={`mx-auto ${
                              comment.removed ? "card-removed" : ""
                            }`}
                          >
                            <MDBCardHeader className="d-flex align-items-end">
                              <MDBCardTitle
                                className={`mb-0 ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                {comment.badge ? (
                                  this.getBadge(comment)
                                ) : (
                                  <>
                                    <span
                                      className={
                                        comment.removed
                                          ? "text-light"
                                          : "text-secondary"
                                      }
                                    >
                                      {comment.name}
                                    </span>{" "}
                                    (ID:{" "}
                                    <span
                                      className="text-light"
                                      style={{
                                        backgroundColor: `var(${comment.poster_css_color})`,
                                      }}
                                    >
                                      {comment.poster_id}
                                    </span>
                                    )
                                  </>
                                )}
                                <span className="mb-0 ms-2">
                                  {h.makeDateHR(new Date(comment.timestamp))} at{" "}
                                  {h.getTimeHR(new Date(comment.timestamp))}
                                </span>
                              </MDBCardTitle>
                              <MDBCardTitle
                                className={`ms-2 mb-0 text-gb ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                #{comment.comment_id}
                              </MDBCardTitle>
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex">
                              {this.renderCommentAvatar(comment)}
                              <MDBCardText
                                className={`messages text-break ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                {this.parseComment_displayOnly(
                                  comment.comment_text
                                )}
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      )}
                    </>
                  ),
                });
            })
            .catch((err) => {
              console.log(err);
              if (this.state.commentHover === postNumber) {
                if (err.response.status === 404)
                  this.setState({
                    ...this.state,
                    tooltipComment: (
                      <>
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard className="mx-auto">
                            <MDBCardBody className="d-flex justify-content-center align-items-center">
                              <h5 className="text-center">
                                Comment does not exist
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      </>
                    ),
                  });
                else
                  this.setState({
                    ...this.state,
                    tooltipComment: (
                      <>
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard className="mx-auto">
                            <MDBCardBody className="d-flex justify-content-center align-items-center">
                              <h5 className="text-center">
                                An error occurred while fetching the comment
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      </>
                    ),
                  });
              }
            })
      );
  };

  /**
   *
   * @param {String} text - The text of a comment
   *
   * Performs the same task as parseComment, but without the links or tooltips. Visual effects only.
   *
   * @returns JSX array with parsed comment
   */
  parseComment_displayOnly = (text) => {
    let returnArray = [];
    text.split("##").forEach((segment) => {
      if (segment) {
        let postNumber = "";
        let stop = false;
        segment.split("").forEach((character) => {
          if (!stop && h.isNumeric(character)) postNumber += character;
          else stop = true;
        });
        if (postNumber) {
          returnArray.push(
            <span className="text-primary links-generic-light text-decoration-none cursor-pointer fw-bold">
              {"##" + postNumber}
            </span>
          );
          returnArray.push(
            segment
              .split(postNumber)
              .filter((piece, i) => i !== 0)
              .join(postNumber)
          );
        } else returnArray.push(segment);
      }
    });
    return returnArray;
  };

  /**
   *
   * @param {Event} e - Javascript click event or similar
   * @param {Number} commentID - ref Comments.comment_id
   *
   * Fired when the user clicks on a quoted comment
   * If the comment is not in the comment section, route to /comment/commentID
   * If the comment is in the comment section and in the document, scroll to it
   * If the comment is in the comment section and not in the document, switch to the appropriate page and scroll to it
   */
  clickQuotedComment = (e, commentID) => {
    e.preventDefault();
    window.location.hash = `#comment-${commentID}`;
    commentID = Number(commentID);
    document
      .getElementById(`tooltip-comment-${commentID}`)
      .classList.add("d-none");
    const comment = this.props.imageInfo.comments.find(
      (c) => c.comment_id === commentID
    );
    if (comment) {
      const element = document.getElementById(`comment-${commentID}`);
      if (element) element.scrollIntoView();
      else {
        const index = this.props.imageInfo.comments.indexOf(comment) + 1;
        const page = Math.ceil(index / 50);
        const maxPages = Math.ceil(this.props.imageInfo.comments.length / 50);
        this.setState(
          {
            ...this.state,
            rightButtonExit:
              this.commentPage > page
                ? t.normalize
                : page === maxPages
                ? t.fade_out_minimize
                : t.bob_right,
            leftButtonExit:
              this.commentPage > page
                ? page === 1
                  ? t.fade_out_minimize
                  : t.bob_left
                : t.normalize,
            contentExit:
              this.commentPage > page ? t.fade_out_right : t.fade_out_left,
          },
          () => {
            this.props.set_comment_page(page);
            this.scrollToComment(commentID);
          }
        );
      }
    } else this.props.route(`/comment/${commentID}`);
  };

  /**
   *
   * @param {Number} commentID - ref Comments.comment_id
   *
   * Hit when the user clicks on a quoted comment
   * If it is already in the document, scroll to it
   * Else, wait and try again (could happen due to framer-motion transitions)
   */
  scrollToComment = (commentID) =>
    setTimeout(() => {
      const comment = document.getElementById(`comment-${commentID}`);
      if (comment) comment.scrollIntoView();
      else this.scrollToComment(commentID);
    }, 400);

  /**
   *
   * @param {String} text - The text of a manifesto
   *
   * Parses the string for double hashes (##) which are used to quote comments
   * Replaces them with links to comments, and tooltips with previews
   *
   * @returns JSX array with parsed comment
   */
  parseManifesto = (text) => {
    let returnArray = [];
    text.split("##").forEach((segment) => {
      if (segment) {
        let postNumber = "";
        let stop = false;
        segment.split("").forEach((character) => {
          if (!stop && h.isNumeric(character)) postNumber += character;
          else stop = true;
        });
        if (postNumber) {
          returnArray.push(
            <MDBTooltip
              tag="span"
              wrapperProps={{
                className:
                  "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold",
              }}
              title={this.state.tooltipComment}
              onShow={() => this.renderTooltipComment(Number(postNumber))}
              className="comment-tooltips ms-2"
              id={`tooltip-comment-${postNumber}`}
            >
              <Link
                to={`/comment/${postNumber}`}
                onClick={(e) => this.clickQuotedComment(e, postNumber)}
              >
                ##
              </Link>
              <span style={{ cursor: "text" }}>{postNumber}</span>
            </MDBTooltip>
          );
          returnArray.push(
            segment
              .split(postNumber)
              .filter((piece, i) => i !== 0)
              .join(postNumber)
          );
        } else returnArray.push(segment);
      }
    });
    return returnArray;
  };

  /**
   * Triggered when the user clicks the play button on a video
   * Plays the video
   */
  playMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.state.playing)
      this.setState(
        (curr) => ({
          ...curr,
          playing: true,
        }),
        () => document.getElementById("img-main").play()
      );
  };

  download = () =>
    this.setState(
      (curr) => ({
        ...curr,
        downloading: true,
      }),
      () => {
        const url = `${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`;
        console.log("download");
        try {
          let contentType;
          fetch(url, {
            method: "get",
            responseType: "arraybuffer",
            headers: { "Cache-Control": "no-cache" },
          })
            .then((response) => {
              contentType = response.headers.get("content-type");
              return response.arrayBuffer();
            })
            .then((buffer) => {
              const data = Buffer.from(buffer, "binary").toString("base64");
              if (data) {
                const iOS =
                  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                  !window.MSStream;
                const isChrome =
                  navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
                  navigator.vendor.toLowerCase().indexOf("google") > -1;
                let iOSVersion = [];
                if (iOS) {
                  iOSVersion = navigator.userAgent
                    .match(/OS [\d_]+/i)[0]
                    .substr(3)
                    .split("_")
                    .map((n) => parseInt(n));
                }
                const attachmentData = data;
                const attachmentName = url.split("/").pop();

                const binary = atob(attachmentData.replace(/\s/g, ""));
                const len = binary.length;
                const buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (let i = 0; i < len; i++) {
                  view[i] = binary.charCodeAt(i);
                }
                const linkElement = document.createElement("a");
                try {
                  let hrefUrl = "";
                  let blob = "";
                  if (!contentType) contentType = "application/octet-stream";
                  if (iOS && !isChrome && iOSVersion[0] <= 12) {
                    blob = "data:" + contentType + ";base64," + data;
                    hrefUrl = blob;
                  } else {
                    if (iOS && !isChrome) {
                      contentType = "application/octet-stream";
                    }
                    blob = new Blob([view], { type: contentType });
                    hrefUrl = window.URL.createObjectURL(blob);
                  }

                  linkElement.setAttribute("href", hrefUrl);
                  linkElement.setAttribute("target", "_blank");
                  if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
                    linkElement.setAttribute("download", attachmentName);
                  }
                  const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                  });
                  linkElement.dispatchEvent(clickEvent);
                  this.setState((curr) => ({
                    ...curr,
                    downloading: false,
                  }));
                } catch (err) {
                  console.log("download error", err);
                  this.setState(
                    (curr) => ({
                      ...curr,
                      downloading: false,
                    }),
                    () => {
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = url.split("/").pop();
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }
                  );
                }
              }
            })
            .catch((err) => {
              console.log("download error", err);
              this.setState(
                (curr) => ({
                  ...curr,
                  downloading: false,
                }),
                () => {
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = url.split("/").pop();
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }
              );
            });
        } catch (err) {
          console.log("download error", err);
          this.setState(
            (curr) => ({
              ...curr,
              downloading: false,
            }),
            () => {
              const a = document.createElement("a");
              a.href = url;
              a.download = url.split("/").pop();
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          );
        }
      }
    );

  /**
   * Triggered when the user stops playing an audio or video file
   * Stops the media
   */
  stopMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((curr) => ({
      ...curr,
      playing: false,
    }));
  };

  /**
   * Triggered when the user plays or pauses an audio file
   * Toggles the play
   *
   */
  toggleMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(
      (curr) => ({
        ...curr,
        playing: !this.state.playing,
      }),
      () => {
        if (this.state.playing) document.getElementById("img-main").play();
        else document.getElementById("img-main").pause();
      }
    );
  };

  renderContent = () => {
    if (this.props.imageInfo.image_id === 4506)
      return (
        <video
          id="img-main"
          className="d-block mx-auto my-2 max-w-95"
          src="https://f.feednana.com/random/fatfuck5.mp4"
          controls
        ></video>
      );
    const extension = String(
      this.props.imageInfo.filename
        .split(".")
        [this.props.imageInfo.filename.split(".").length - 1].split("?")[0]
    ).toLowerCase();
    if (this.props.imageInfo.type) {
      switch (this.props.imageInfo.type) {
        case "audio":
          if (
            ["mp3", "webm", "aac", "wav", "flac", "wma", "ogg"].includes(
              extension
            )
          ) {
            return (
              <motion.audio
                src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
                id="img-main"
                controls={true}
                onPause={this.stopMedia}
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
                className="d-block mx-auto"
                onCanPlay={this.setLoaded}
              />
            );
          } else {
            return (
              <embed
                src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
                className="w-100 text-bluesteel mx-auto d-block"
                style={{ height: "50vh", zIndex: 1000 }}
                type={this.props.imageInfo.mimeType}
              ></embed>
            );
          }
        case "video":
          if (["mp4", "webm", "mov"].includes(extension)) {
            return (
              <video
                style={{ height: "50vh" }}
                className="max-w-100 max-h-100 rounded-6 mx-auto d-block"
                src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
                id="img-main"
                controls={true}
                onPause={this.stopMedia}
                onCanPlay={this.setLoaded}
              />
            );
          } else {
            return (
              <embed
                src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
                className="w-100 text-bluesteel mx-auto d-block"
                style={{ height: "50vh", zIndex: 1000 }}
                type={this.props.imageInfo.mimeType}
              ></embed>
            );
          }

        case "image":
          return (
            <img
              id="img-main"
              style={{ maxHeight: "1000px" }}
              className="img-fluid mx-auto d-block cursor-pointer"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`
                )
              }
              src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
            />
          );
        default:
          return (
            <embed
              src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
              className="w-100 text-bluesteel mx-auto d-block"
              style={{ height: "50vh", zIndex: 1000 }}
              type={this.props.imageInfo.mimeType}
            ></embed>
          );
      }
    }

    return (
      <img
        id="img-main"
        style={{ maxHeight: "1000px" }}
        className="img-fluid mx-auto d-block cursor-pointer"
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`
          )
        }
        src={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
      />
    );
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={this.props.contentExit}
        animate={t.normalize}
        initial={this.props.contentExit}
      >
        <RestoreModal
          socket={this.props.socket}
          modalShown={this.state.modalShown}
          setShowModal={this.setShowModal}
          toggleShowModal={this.toggleShowModal}
        />
        <MDBContainer className="mt-4">
          <hr></hr>
          <div className="row">
            <div className="col-6">{this.renderContent()}</div>
            <div className="col-6">
              <MDBBtn
                color="light"
                size="lg"
                onClick={this.download}
                disabled={this.state.downloading}
              >
                {this.state.downloading ? (
                  <>
                    <Spinner className="me-2" size="sm" color="dark" />
                    Downloading
                  </>
                ) : (
                  <>
                    <i className="fas fa-download me-2"></i>
                    Download
                  </>
                )}
              </MDBBtn>
              <h5 className="mt-2 text-center">Direct Link</h5>
              {this.props.imageInfo.image_id === 4506 ? (
                <MDBTooltip
                  wrapperClass="d-block mx-auto text-unset text-overflow-ellipsis cursor-pointer"
                  wrapperProps={{
                    onClick: this.copyDirectLink,
                  }}
                  tag="div"
                  title={
                    this.state.directLinkCopied ? "Copied" : "Click to Copy"
                  }
                >
                  <MDBInputGroup textAfter={<i className="fas fa-copy" />}>
                    <input
                      id="direct-link"
                      value={`https://f.feednana.com/random/fatfuck5.mp4`}
                      readOnly
                      className="form-control text-overflow-ellipsis cursor-pointer"
                      type="text"
                      placeholder=""
                    />
                  </MDBInputGroup>
                </MDBTooltip>
              ) : (
                <MDBTooltip
                  wrapperClass="d-block mx-auto text-unset text-overflow-ellipsis cursor-pointer"
                  wrapperProps={{
                    onClick: this.copyDirectLink,
                  }}
                  tag="div"
                  title={
                    this.state.directLinkCopied ? "Copied" : "Click to Copy"
                  }
                >
                  <MDBInputGroup textAfter={<i className="fas fa-copy" />}>
                    <input
                      id="direct-link"
                      value={`${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}`}
                      readOnly
                      className="form-control text-overflow-ellipsis cursor-pointer"
                      type="text"
                      placeholder=""
                    />
                  </MDBInputGroup>
                </MDBTooltip>
              )}
              <hr></hr>
              {this.props.imageInfo.image_id === 4506 ||
              (this.props.imageInfo.type &&
                this.props.imageInfo.type !== "image") ? (
                <></>
              ) : (
                <>
                  <h5 className="mt-2 text-center">BBcode</h5>
                  <MDBTooltip
                    wrapperClass="d-block mx-auto text-unset text-overflow-ellipsis cursor-pointer"
                    wrapperProps={{
                      onClick: this.copyBBCode,
                    }}
                    tag="div"
                    title={this.state.bbCopied ? "Copied" : "Click to Copy"}
                  >
                    <MDBInputGroup textAfter={<i className="fas fa-copy" />}>
                      <input
                        id="bb-code"
                        value={`[img]${process.env.REACT_APP_IMAGE_HOST}/files/${this.props.imageInfo.filename}[/img]`}
                        readOnly
                        className="form-control text-overflow-ellipsis cursor-pointer"
                        type="text"
                        placeholder=""
                      />
                    </MDBInputGroup>
                  </MDBTooltip>
                </>
              )}
            </div>
          </div>
        </MDBContainer>
        <MDBContainer fluid>
          <div className="d-flex justify-content-between align-items-start my-4">
            <h5>
              {h.numberWithCommas(this.props.imageInfo.views)} View
              {this.props.imageInfo.views !== 1 ? "s" : ""}
            </h5>
            {!this.props.imageInfo.removed ? (
              <div>
                {h.checkJanny(this.props.userInfo) ||
                this.props.userInfo._id === this.props.imageInfo.userID ? (
                  <>
                    <MDBBtn
                      onClick={this.toggleRemoveForm}
                      color="dark"
                      className="d-block ms-auto"
                    >
                      Remove File
                    </MDBBtn>
                    <Remove
                      socket={this.props.socket}
                      show={this.state.removeOpen}
                    />
                  </>
                ) : (
                  <>
                    {this.state.imageReported ? (
                      <motion.h5
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                        className="text-end text-danger"
                      >
                        File Reported
                      </motion.h5>
                    ) : (
                      <>
                        <MDBBtn
                          onClick={this.toggleReportForm}
                          color="dark"
                          className="d-block ms-auto"
                        >
                          Report
                        </MDBBtn>
                        <Report
                          show={this.state.reportOpen}
                          setImageReported={this.setImageReported}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {h.checkJanny(this.props.userInfo) ||
                this.props.userInfo._id === this.props.imageInfo.userID ? (
                  <>
                    <MDBBtn
                      onClick={() => this.setShowModal(true)}
                      color="danger"
                    >
                      Restore File
                    </MDBBtn>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <hr></hr>
          <h5 className="text-center mb-4">
            Posted by{" "}
            {this.props.imageInfo.badge ? (
              this.getBadge()
            ) : (
              <>
                <span className="text-secondary">
                  {this.props.imageInfo.user}
                </span>{" "}
                <span className="text-nowrap">
                  (ID:{" "}
                  <span
                    className="text-light"
                    style={{
                      backgroundColor: `var(${this.props.imageInfo.poster_css_color})`,
                    }}
                  >
                    {this.props.imageInfo.poster_id}
                  </span>
                  )
                </span>
              </>
            )}{" "}
            on {h.makeDateHR(new Date(this.props.imageInfo.timestamp))} at{" "}
            {h.getTimeHR(new Date(this.props.imageInfo.timestamp))}
          </h5>
        </MDBContainer>

        {String(this.props.imageInfo.manifesto).length ? (
          <MDBContainer>
            <MDBCard
              className={`mt-4 shadow-3-strong ${
                this.props.imageInfo.manifesto_removed ? "card-removed" : ""
              }`}
            >
              <MDBCardHeader>
                <MDBCardTitle
                  className={`text-center ${
                    this.props.imageInfo.manifesto_removed ? "text-light" : ""
                  }`}
                >
                  Manifesto
                </MDBCardTitle>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBCardText
                  className={`messages ${
                    this.props.imageInfo.manifesto_removed ? "text-light" : ""
                  }`}
                >
                  {this.parseManifesto(this.props.imageInfo.manifesto)}
                </MDBCardText>
              </MDBCardBody>
              {h.checkJanny(this.props.userInfo) ||
              this.props.userInfo._id === this.props.imageInfo.userID ? (
                <MDBCardFooter>
                  {!this.props.imageInfo.manifesto_removed ? (
                    <div className="d-flex justify-content-end">
                      <div className="w-max-content">
                        <MDBBtn
                          size="sm"
                          onClick={this.toggleRemoveManifestoForm}
                          color="dark"
                          className="d-block ms-auto"
                        >
                          Remove Manifesto
                        </MDBBtn>
                        <RemoveManifesto
                          socket={this.props.socket}
                          show={this.state.removeManifestoOpen}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <h5 className="text-light messages">
                        Removed:{" "}
                        {this.props.imageInfo.manifesto_removed_reason ===
                        "other"
                          ? this.props.imageInfo.manifesto_removed_details
                          : h.getRemovedReason(
                              this.props.imageInfo.manifesto_removed_reason
                            )}
                      </h5>
                      {this.state.manifestoRestoring ? (
                        <MDBBtn
                          disabled
                          size="sm"
                          style={{ backgroundColor: "var(--mdb-green)" }}
                        >
                          <Spinner size="sm" className="me-2"></Spinner>
                          Restoring
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          size="sm"
                          onClick={this.restoreManifesto}
                          style={{ backgroundColor: "var(--mdb-green)" }}
                        >
                          Restore Manifesto
                        </MDBBtn>
                      )}
                    </div>
                  )}
                </MDBCardFooter>
              ) : (
                <></>
              )}
            </MDBCard>
          </MDBContainer>
        ) : (
          <></>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  update_image,
  set_comment_page,
  route,
})(Content);
