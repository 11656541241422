import React from "react";
import { connect } from "react-redux";
import {
  MDBCollapse,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBBtn,
} from "mdb-react-ui-kit";
import { report_schema } from "../../../utilities/validations";
import axios from "axios";
import {
  set_image,
  update_comment,
  update_profile_comment,
} from "../../../redux/actions";
import Spinner from "../../../components/Spinner";

const fields = [
  {
    text: "Explain",
    id: "details",
    type: "text",
  },
];

class Remove extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * inputs: Array - The input data (values, errors, etc)
       * otherSelected: Boolean - Whether the "Other" option is selected as the removal reason (will open up a text input)
       * working: Boolean - Whether the comment is in the process of being removed
       * removeReason: String - The reason for the comment removal
       * actionTimeout: false | Interval that adds collapse classes to MDBCollapse (fixes ui bug caused by collapse within collapse)
       */
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      otherSelected: false,
      working: false,
      removeReason: "",
      actionTimeout: false,
    };
  }

  /**
   * Run a blank input change
   */
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in the Other text input
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          report_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document
                .getElementById(
                  input.id + `-remove_comment_form-${this.props.commentID}`
                )
                .setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(
                      input.id + `-remove_comment_form-${this.props.commentID}`
                    )
                    .setCustomValidity(input.error);
                else
                  document
                    .getElementById(
                      input.id + `-remove_comment_form-${this.props.commentID}`
                    )
                    .setCustomValidity("");
              })
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Reset inputs
   * Emit mod-action event via socket
   * Update comment in application state
   */
  submit = () => {
    document
      .getElementById(`remove_comment_form-${this.props.commentID}`)
      .classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document
        .getElementById(
          input.id + `-remove_comment_form-${this.props.commentID}`
        )
        .setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
          removeReason: "other",
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            report_schema.validateSync(data, {
              abortEarly: false,
            });
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append("commentID", this.props.commentID);
            if (this.props.profile)
              fd.append("user", this.props.profileInfo.username);
            else fd.append("imageID", this.props.imageInfo.image_id);
            fd.append("reason", "other");
            axios
              .post("/support/remove/comment", fd)
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    removeReason: "",
                  },
                  () => {
                    this.props.socket.emit("mod-action");
                    if (this.props.profile)
                      this.props.update_profile_comment(res.data.comment);
                    else this.props.update_comment(res.data.comment);
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    removeReason: "",
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
                removeReason: "",
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Hit when the user selects the Other option as the reason for removal
   * Must manually add/remove the MDBCollapse classes or else it will cause ui bugs (MDB issue)
   */
  toggleOtherSelected = () => {
    clearInterval(this.state.actionTimeout);
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          otherSelected: !this.state.otherSelected,
        },
        () => {
          if (this.state.otherSelected) {
            setTimeout(() => {
              const outer = document.getElementById(
                `collapse-outer-remove-${this.props.commentID}`
              );
              const inner = document.getElementById(
                `collapse-inner-remove-${this.props.commentID}`
              );
              outer.style.height = `${
                Number(outer.style.height.split("px")[0]) +
                Number(inner.style.height.split("px")[0])
              }px`;
              outer.classList.remove("show");
              outer.classList.remove("collapse");
              outer.classList.add("collapsing");
              this.setState({
                ...this.state,
                actionTimeout: setTimeout(() => {
                  if (this.props.show) {
                    outer.classList.add("show");
                    outer.classList.add("collapse");
                    outer.classList.remove("collapsing");
                  }
                }, 1000),
              });
            }, 50);
          } else {
            const outer = document.getElementById(
              `collapse-outer-remove-${this.props.commentID}`
            );
            const inner = document.getElementById(
              `collapse-inner-remove-${this.props.commentID}`
            );
            outer.style.height = `${
              Number(outer.style.height.split("px")[0]) -
              Number(inner.style.height.split("px")[0])
            }px`;
            outer.classList.remove("show");
            outer.classList.remove("collapse");
            outer.classList.add("collapsing");
            this.setState({
              ...this.state,
              actionTimeout: setTimeout(() => {
                if (this.props.show) {
                  outer.classList.add("show");
                  outer.classList.add("collapse");
                  outer.classList.remove("collapsing");
                }
              }, 1000),
            });
          }
        }
      );
  };

  /**
   *
   * @param {String} reason - Reason for the comment removal
   *
   * Fired when the user selects a removal reason that is not Other
   *
   * Works only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Emit mod-action event via socket
   * Update comment in application state
   */
  submitSimple = (reason) => {
    if (!this.state.working)
      this.setState(
        {
          ...this.state,
          removeReason: reason,
          working: true,
        },
        async () => {
          let submission;
          if (this.props.profile)
            submission = {
              reason: reason,
              commentID: this.props.commentID,
              user: this.props.profileInfo.username,
            };
          else
            submission = {
              reason: reason,
              commentID: this.props.commentID,
              imageID: this.props.imageInfo.image_id,
            };
          axios
            .post("/support/remove/comment", submission)
            .then((res) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                  removeReason: "",
                },
                () => {
                  this.props.socket.emit("mod-action");
                  if (this.props.profile)
                    this.props.update_profile_comment(res.data.comment);
                  else this.props.update_comment(res.data.comment);
                }
              )
            )
            .catch((err) =>
              this.setState(
                {
                  ...this.state,
                  working: false,
                  removeReason: "",
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            );
        }
      );
  };

  render() {
    return (
      <MDBCollapse
        id={`collapse-outer-remove-${this.props.commentID}`}
        show={this.props.show || this.state.working}
      >
        <MDBBtn
          size="sm"
          onClick={() => this.submitSimple("fed")}
          disabled={this.state.working}
          className="d-block ms-auto"
          rippleColor="primary"
          color="link"
        >
          {this.state.removeReason === "fed" ? (
            <Spinner size="sm" className="me-2"></Spinner>
          ) : (
            <></>
          )}
          Terrorism/Fed
        </MDBBtn>
        <MDBBtn
          size="sm"
          onClick={() => this.submitSimple("spam")}
          disabled={this.state.working}
          className="d-block ms-auto"
          rippleColor="primary"
          color="link"
        >
          {this.state.removeReason === "spam" ? (
            <Spinner size="sm" className="me-2"></Spinner>
          ) : (
            <></>
          )}
          Spam
        </MDBBtn>
        <MDBBtn
          size="sm"
          disabled={this.state.working}
          onClick={this.toggleOtherSelected}
          className="d-block ms-auto"
          rippleColor={this.state.otherSelected ? "light" : "primary"}
          color={this.state.otherSelected ? "warning" : "link"}
        >
          Other
        </MDBBtn>
        <MDBCollapse
          id={`collapse-inner-remove-${this.props.commentID}`}
          show={this.state.otherSelected}
        >
          <MDBValidation
            className="mt-2"
            id={`remove_comment_form-${this.props.commentID}`}
            method="dialog"
            name={`remove_comment_form-${this.props.commentID}`}
          >
            {fields.map((i) => (
              <MDBValidationItem
                key={i.id}
                className="pb-2"
                feedback={
                  this.state.inputs.find((input) => input.id === i.id).error
                }
                invalid={true}
              >
                <MDBTextArea
                  name={i.id}
                  onChange={this.changeHandler}
                  id={i.id + `-remove_comment_form-${this.props.commentID}`}
                  label={i.text}
                  size="lg"
                  className={
                    !this.state.inputs.find((input) => input.id === i.id)
                      .invalid
                      ? "mb-0"
                      : 0
                  }
                  onKeyPress={this.pressEnter}
                />
              </MDBValidationItem>
            ))}
          </MDBValidation>
          <div className="d-flex pb-4">
            <MDBBtn
              size="sm"
              onClick={this.submit}
              disabled={this.state.working}
              className="d-block"
              color="dark"
            >
              {this.state.removeReason === "other" ? (
                <>
                  <Spinner size="sm" className="me-2"></Spinner>
                  Sending
                </>
              ) : (
                <>Submit</>
              )}
            </MDBBtn>
            {this.state.working ? (
              <></>
            ) : (
              <MDBBtn
                disabled={this.state.working}
                size="sm"
                onClick={this.toggleOtherSelected}
                className="d-block ms-2"
                color="light"
              >
                Cancel
              </MDBBtn>
            )}
          </div>
        </MDBCollapse>
      </MDBCollapse>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_image,
  update_comment,
  update_profile_comment,
})(Remove);
