import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { route, set_nsfw_modal } from "../redux/actions";
import t from "../utilities/transitions";
import h from "../utilities/helpers";
import {
  MDBSelect,
  MDBContainer,
  MDBBtn,
  MDBPopover,
  MDBPopoverBody,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";
import Spinner from "../components/Spinner";
import BrowseNav from "./browse/BrowseNav";
import BrowsePage from "./browse/BrowsePage";
import { StaticRouter, Switch, Route } from "react-router-dom";
import LogoLoader from "../components/LogoLoader";

const mainOptions = [
  {
    label: "Latest Posts",
    id: "latest-posts",
  },
  {
    label: "Latest Comments",
    id: "latest-comments",
  },
  {
    label: "Popular",
    id: "popular",
  },
];

const popularOptions = [
  {
    label: "All Time",
    id: "all-time",
  },
  {
    label: "Year(s)",
    id: "years",
  },
  {
    label: "Month(s)",
    id: "months",
  },
  {
    label: "Weeks(s)",
    id: "weeks",
  },
  {
    label: "Day(s)",
    id: "days",
  },
];

class Browse extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * leftButtonExit: Object - framer-motion exit animation for the left nav button
       * rightButtonExit: Object - framer-motion exit animation for the right nav button
       * contentExit: Object - framer-motion exit animation for the images
       * contentEnter: Object - framer-motion entrance animation for the images
       * sortBy: String - one of mainOptions ids
       * popular: String - one of popularOptions ids
       * offset: Number - The offset of the popular selection
       * inputs: Object - The current inputs that may or may not have been submitted
       * loaded: Boolean - whether the initial load has complete
       * images: Array - List of images returned from load
       * page: Number - The page of images that the user is currently on
       * browseStack: Array - List of all browse pages that the user has been through
       * showApplyButton: Boolean indicating whether the green apply button should be shown
       * applying: Boolean indicating whether changes are in the process of being applied
       * offsetPopoverOpen: Boolean indicating whether the offset selector is open
       * softLoading: Boolean indicating whether there is some loading taking place in the background
       */
      leftButtonExit: t.fade_out_minimize,
      rightButtonExit: t.fade_out_minimize,
      contentExit: t.fade_out,
      contentEnter: t.fade_out,
      sortBy: "latest-posts",
      popular: "all-time",
      offset: 1,
      inputs: {
        sortBy: "latest-posts",
        popular: "all-time",
        offset: 1,
      },
      loaded: false,
      images: [],
      page: 1,
      browseStack: [1],
      showApplyButton: false,
      applying: false,
      offsetPopoverOpen: false,
      softLoading: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  /**
   *
   * @param {MouseEvent} e - Event object from the mousedown event added to document.body when the page selector was opened
   *
   * Close the page selector
   * Remove the event listener when done
   */
  closeOffsetPopover = (e) => {
    if (!(e.target && e.target.classList.contains("offset-popover-triggers")))
      this.setState(
        {
          ...this.state,
          offsetPopoverOpen: false,
        },
        () =>
          document.body.removeEventListener(
            "mousedown",
            this.closeOffsetPopover
          )
      );
  };

  /**
   * Hit when the user taps or clicks the offset number
   * Opens the offset selector
   * Adds an event listener to close the offset selector when the user clicks outside it
   *
   */
  showOffsetPopover = () =>
    this.setState(
      {
        ...this.state,
        offsetPopoverOpen: true,
      },
      () =>
        setTimeout(
          () =>
            document.body.addEventListener(
              "mousedown",
              this.closeOffsetPopover
            ),
          500
        )
    );

  /**
   * Loads the data with the parameters currently in state
   * Performed once on mount, then can be done subsequent times after parameters are adjusted
   */
  load = () => {
    const page = this.state.page;
    axios
      .get(
        `/browse/${this.state.sortBy}/${this.state.popular}/${this.state.offset}/${page}`
      )
      .then((res) =>
        this.setState(
          {
            ...this.state,
            loaded: true,
            applying: false,
            softLoading: false,
            images: res.data.results,
            showApplyButton: false,
          },
          () => {
            let nsfwModal = false;
            if (!this.props.userInfo.nsfwAccepted)
              res.data.results.forEach((image) => {
                if (image.nsfw & !this.props.showNsfwModal && !nsfwModal) {
                  nsfwModal = true;
                  this.props.set_nsfw_modal(true);
                }
              });
          }
        )
      )
      .catch((err) => {
        console.log(err);
        setTimeout(this.load, 1000);
      });
  };

  /**
   *
   * @param {Select Option} e - Sort parameter (Popularity/Age/etc)
   */
  setSort = (e) => {
    if (!this.state.applying)
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          sortBy: e.value,
          popular: "all-time",
          offset: 1,
        },
        showApplyButton: true,
      });
  };

  /**
   *
   * @param {Select Option} e - Timeframe (All Time/Years/Months/etc)
   */
  setPopular = (e) => {
    if (!this.state.applying)
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          popular: e.value,
        },
        showApplyButton: true,
      });
  };

  /**
   *
   * @param {Boolean} increment - Whether or not the user clicked the + button
   */
  setOffset = (increment) => {
    if (!this.state.applying)
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          offset: increment
            ? this.state.inputs.offset + 1
            : this.state.inputs.offset > 1
            ? this.state.inputs.offset - 1
            : this.state.inputs.offset,
        },
        showApplyButton: true,
      });
  };

  /**
   * Fired when the user clicks the green Apply Changes button
   * Applies the changes, then reloads the data with the new parameters
   */
  apply = () => {
    if (!this.state.applying)
      this.setState(
        {
          ...this.state,
          applying: true,
          ...this.state.inputs,
          leftButtonExit: t.fade_out_minimize,
          rightButtonExit: t.fade_out_minimize,
          contentExit: t.fade_out,
          contentEnter: t.fade_out,
        },
        () => this.load()
      );
  };

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.apply();
  };

  /**
   * Fired when the user clicks the left nav button
   */
  back = () => {
    if (!this.state.applying && this.state.loaded)
      this.setState(
        {
          /**
           * Sets the correct exit transition for the button and body
           * If stage is greater than 1, go down one stage, otherwise route to the home page
           */
          ...this.state,
          rightButtonExit:
            this.state.page ===
            Math.ceil(this.props.imageInfo.comments.length / 48)
              ? t.fade_out_minimize
              : t.normalize,
          leftButtonExit:
            this.state.page > 2 ? t.bob_left : t.fade_out_minimize,
          contentExit: t.fade_out_right,
          contentEnter: t.fade_out_left,
          softLoading: true,
        },
        () =>
          this.setState(
            {
              ...this.state,
              page: this.state.page - 1,
              browseStack: [...this.state.browseStack, this.state.page],
            },
            () => this.load()
          )
      );
  };

  /**
   * Fired when the user clicks the right nav button
   */
  next = () => {
    if (!this.state.applying && this.state.loaded)
      this.setState(
        {
          ...this.state,
          rightButtonExit: t.bob_right,
          leftButtonExit:
            this.state.page === 1 ? t.fade_out_minimize : t.normalize,
          contentExit: t.fade_out_left,
          contentEnter: t.fade_out_right,
          softLoading: true,
        },
        () =>
          this.setState(
            {
              ...this.state,
              page: this.state.page + 1,
              browseStack: [...this.state.browseStack, this.state.page],
            },
            () => this.load()
          )
      );
  };

  /**
   *
   * @param {Number} index - Index of the page selector popover
   *
   * Fired when the user clicks the Go button in the page selector
   * Navigates to the page that was entered in the page selector input
   */
  go = (index) => {
    if (!this.state.applying && this.state.loaded) {
      let pageNumber = document.getElementById(`page-number-${index}`).value;
      if (h.isNumeric(pageNumber)) {
        pageNumber = Number(pageNumber);
      } else pageNumber = 1;
      if (this.state.page !== pageNumber)
        this.setState(
          {
            ...this.state,
            rightButtonExit:
              this.state.page > pageNumber ? t.normalize : t.bob_right,
            leftButtonExit:
              this.state.page > pageNumber
                ? pageNumber === 1
                  ? t.fade_out_minimize
                  : t.bob_left
                : t.normalize,
            contentExit:
              this.state.page > pageNumber ? t.fade_out_right : t.fade_out_left,
            contentEnter:
              this.state.page < pageNumber ? t.fade_out_right : t.fade_out_left,
            softLoading: true,
          },
          () =>
            this.setState(
              {
                ...this.state,
                page: pageNumber,
                browseStack: [...this.state.browseStack, this.state.page],
              },
              () => this.load()
            )
        );
    }
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBContainer className="mt-4" fluid>
          <h1 className="text-center display-4">
            {
              mainOptions.find((option) => option.id === this.state.sortBy)
                .label
            }
          </h1>
          <div
            className="browse-nav-containers"
            style={{ width: "250px", maxWidth: "90%" }}
          >
            <StaticRouter location={`/${this.state.page}`}>
              <AnimatePresence exitBeforeEnter>
                <Switch key={this.state.page}>
                  <Route exact path="/:page">
                    <BrowseNav
                      page={this.state.page}
                      changePage={this.changePage}
                      browseStack={this.state.browseStack}
                      setPage={this.setPage}
                      key={this.state.page}
                      leftButtonExit={this.state.leftButtonExit}
                      rightButtonExit={this.state.rightButtonExit}
                      back={this.back}
                      next={this.next}
                      go={this.go}
                      applying={this.state.applying}
                      loaded={this.state.loaded}
                      index={1}
                    />
                  </Route>
                </Switch>
              </AnimatePresence>
            </StaticRouter>
          </div>
          <div style={{ width: "250px", maxWidth: "90%" }}>
            <MDBSelect
              label="Sort By"
              data={mainOptions.map((option) => ({
                text: option.label,
                value: option.id,
                defaultSelected: option.id === this.state.inputs.sortBy,
              }))}
              onValueChange={this.setSort}
              disabled={this.state.applying}
            />
            {this.state.inputs.sortBy === "popular" ? (
              <>
                {this.state.inputs.popular !== "all-time" ? (
                  <div
                    className="mt-4"
                    style={{ width: "190px", maxWidth: "90%" }}
                  >
                    <small className="text-center d-block mx-auto">From</small>
                    <div className="d-flex justify-content-between align-items-center">
                      <MDBBtn
                        color="link"
                        onClick={() => this.setOffset(false)}
                        className="text-nowrap"
                        disabled={this.state.applying}
                      >
                        <i className="fas fa-chevron-left"></i>
                        <i className="fas fa-chevron-left"></i>
                      </MDBBtn>
                      <div className="mx-auto">
                        <MDBPopover
                          color="link"
                          placement="top"
                          btnChildren={
                            <h4
                              style={{ fontSize: "2rem" }}
                              className="display-6 text-center text-default my-0 offset-popover-triggers"
                            >
                              {this.state.inputs.offset}
                            </h4>
                          }
                          btnClassName="text-unset px-2 mx-2 offset-popover-triggers"
                          className="mb-2 w-max-content max-w-max-content offset-popover-triggers"
                          rippleColor="primary"
                          onShow={this.showOffsetPopover}
                          isOpen={this.state.offsetPopoverOpen}
                        >
                          <MDBPopoverBody className="offset-popover-triggers">
                            <div className="d-flex align-items-center offset-popover-triggers">
                              <div className="mx-2 offset-popover-triggers">
                                <div className="d-flex justify-content-center offset-popover-triggers">
                                  <MDBInput
                                    min="1"
                                    defaultValue={this.state.inputs.offset}
                                    label="Page"
                                    type="number"
                                    id="page-number"
                                    style={{
                                      width: "5rem",
                                    }}
                                    onKeyPress={this.pressEnter}
                                    className="offset-popover-triggers"
                                  />
                                </div>
                                {this.state.applying ? (
                                  <MDBBtn
                                    disabled
                                    color="success"
                                    className="d-block mx-auto mt-3"
                                  >
                                    <Spinner size="sm"></Spinner>
                                  </MDBBtn>
                                ) : (
                                  <MDBBtn
                                    onClick={this.apply}
                                    className="d-block mx-auto mt-3"
                                    color="success"
                                  >
                                    Apply
                                  </MDBBtn>
                                )}
                              </div>
                            </div>
                          </MDBPopoverBody>
                        </MDBPopover>
                      </div>
                      <MDBBtn
                        color="link"
                        onClick={() => this.setOffset(true)}
                        className="text-nowrap"
                        disabled={this.state.applying}
                      >
                        <i className="fas fa-chevron-right"></i>
                        <i className="fas fa-chevron-right"></i>
                      </MDBBtn>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <MDBSelect
                  label="Sort Direction"
                  data={popularOptions.map((option) => ({
                    text: option.label,
                    value: option.id,
                    defaultSelected: option.id === this.state.inputs.popular,
                  }))}
                  onValueChange={this.setPopular}
                  className="mt-4"
                  disabled={this.state.applying}
                />
              </>
            ) : (
              <></>
            )}
            {this.state.showApplyButton && this.state.loaded ? (
              <>
                {this.state.applying ? (
                  <MDBBtn disabled color="success" block className="mt-3">
                    <Spinner size="sm" className="me-2" />
                    Applying
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    block
                    color="success"
                    onClick={this.apply}
                    className="mt-3"
                  >
                    Apply
                  </MDBBtn>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {this.state.loaded && !this.state.applying ? (
            <div className="row">
              {this.state.images.length ? (
                <StaticRouter location={`/${this.state.page}`}>
                  <AnimatePresence exitBeforeEnter>
                    <Switch key={this.state.page}>
                      <Route exact path="/:page">
                        <BrowsePage
                          page={this.state.page}
                          browseStack={this.state.browseStack}
                          key={this.state.page}
                          images={this.state.images}
                          contentExit={this.state.contentExit}
                          contentEnter={this.state.contentEnter}
                        />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </StaticRouter>
              ) : (
                <h2 className="text-center">
                  No files found with these specifications
                </h2>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-4">
              <LogoLoader className="my-5" />
            </div>
          )}
          {this.state.loaded && this.state.images.length ? (
            <div
              className="browse-nav-containers"
              style={{ width: "250px", maxWidth: "90%" }}
            >
              <StaticRouter location={`/${this.state.page}`}>
                <AnimatePresence exitBeforeEnter>
                  <Switch key={this.state.page}>
                    <Route exact path="/:page">
                      <BrowseNav
                        page={this.state.page}
                        changePage={this.changePage}
                        browseStack={this.state.browseStack}
                        setPage={this.setPage}
                        key={this.state.page}
                        leftButtonExit={this.state.leftButtonExit}
                        rightButtonExit={this.state.rightButtonExit}
                        back={this.back}
                        next={this.next}
                        go={this.go}
                        applying={this.state.applying}
                        loaded={this.state.loaded}
                        index={2}
                      />
                    </Route>
                  </Switch>
                </AnimatePresence>
              </StaticRouter>
            </div>
          ) : (
            <></>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_nsfw_modal })(Browse);
