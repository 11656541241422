import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import h from "../utilities/helpers";
import "./home/home.css";
import {
  MDBBtn,
  MDBRipple,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBProgress,
  MDBProgressBar,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { image_schema } from "../utilities/validations";
import { upload_images, set_series, route } from "../redux/actions";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import EmojiPicker from "../components/EmojiPicker";
import Count from "../components/Count";
import axios from "axios";
import { Uploader } from "../utilities/upload";
import Spinner from "../components/Spinner";
import LogoLoader from "../components/LogoLoader";
import { Collapse } from "@mui/material";
import exifr from "exifr";

/**
 * This is the Upload page
 */

const MAX_TOTAL_FILE_SIZE = 1572864000;
const MAX_FILE_COUNT = 15;

const fields = [
  {
    id: "name",
    text: "Name",
    type: "input",
  },
  {
    id: "manifesto",
    text: "Manifesto",
    type: "textarea",
  },
];

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * files: Array - List of files that have been selected by the user
       * showDetails: Boolean - Whether the Details form is shown
       * inputs: Array - The input data (values, errors, etc)
       * imageHovered: String - md5 hash of the image that the user has their mouse over
       * uploading: Boolean indicating whether the user is in the process of uploading files
       * processing: Boolean indicating whether files have been selected and md5 hashes are being generated
       */
      files: [],
      showDetails: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      imageHovered: "",
      uploading: false,
      processing: false,
      selectingFiles: false,
    };
  }

  componentDidMount() {
    /**
     * Add paste event listener
     * Prepare drop area
     * Blank changeHandler
     */
    document.onpaste = this.pasteFile;
    this.setDropArea();
    if (this.props.uploading)
      this.setState({
        ...this.state,
        uploading: true,
      });
    else
      this.changeHandler({
        target: {
          name: "",
        },
      });
  }

  /**
   *
   * @param {Object} prevProps - Previous this.props object
   * @param {Object} prevState - Previous this.state object
   *
   * Any time the number of files in state changes, prime the upload space for drag/drop files
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.files.length && this.state.files.length) ||
      (prevState.files.length && !this.state.files.length)
    ) {
      this.setDropArea();
    }
    if (this.props.uploading && !this.state.uploading)
      this.setState({
        ...this.state,
        uploading: true,
      });
  }

  /**
   * Triggered any time state.files changes
   * Prepares the upload space for files to be dragged/dropped
   */
  setDropArea = () => {
    const dropArea = this.state.files.length
      ? document.getElementById("file-upload-container-files")
      : document.getElementById("file-upload-container");
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.preventDefaults, false);
    });
    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(
        eventName,
        () => dropArea.classList.add("upload-highlight"),
        false
      );
    });

    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(
        eventName,
        () => dropArea.classList.remove("upload-highlight"),
        false
      );
    });
    dropArea.addEventListener("drop", this.dropFiles, false);
  };

  preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   *
   * @param {Event Object} e - Drop event
   *
   * User drags/drops files into form
   */
  dropFiles = (e) => {
    this.setState(
      {
        ...this.state,
        selectingFiles: true,
      },
      () => {
        const fileArray = [].slice.call(e.dataTransfer.files);
        this.processFiles({
          target: {
            files: fileArray,
          },
        });
      }
    );
  };

  processFile = (file, thumbnail) =>
    new Promise(async (resolve, reject) => {
      try {
        const handleError = (e) => {
          console.log("error", e);
          reject();
        };

        const handleComplete = (key) => {
          resolve(key.split("/")[key.split("/").length - 1]);
        };

        new Uploader({
          file: thumbnail ? file.thumbnail : file.file,
          handleProgress: thumbnail ? () => {} : this.progressHandler,
          handleError,
          handleComplete,
          thumbnail,
          initializeResponse: file.initializeResponse,
        }).start();
      } catch (err) {
        console.log("processFile Error", err);
        reject();
      }
    });

  /**
   *
   * @param {Event Object} e - Paste event
   *
   * User pastes files from elsewhere
   */
  pasteFile = (e) => {
    this.setState(
      {
        ...this.state,
        selectingFiles: true,
      },
      async () => {
        const fileArray = [].slice.call(e.clipboardData.files);
        this.processFiles({
          target: {
            files: fileArray,
          },
        });
      }
    );
  };

  /**
   * Fired when the user clicks the Insert Files button
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the files are selected
   */
  selectFiles = () => {
    h.hideToolTips();
    if (!this.state.working && !this.state.selectingFiles) {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.style.visibility = "hidden";
      input.style.position = "fixed";
      document.body.appendChild(input);
      input.onchange = (e) => this.processFiles(e, input);
      input.click();
    }
  };

  processFiles = (e, input) =>
    this.setState(
      (curr) => ({
        ...curr,
        selectingFiles: true,
      }),
      async () => {
        try {
          let files = [];
          const fileArray = Array.from(e.target.files);
          for (let i = 0; i < fileArray.length; i++) {
            let file = fileArray[i];
            if (file.size > MAX_TOTAL_FILE_SIZE) throw "Max size exceeded";
            try {
              if (!file.type) {
                file = new File([file], file.name, {
                  type: h.getFileType(file),
                });
              }
              const extension = file.name
                .split(".")
                [file.name.split(".").length - 1].toLowerCase();
              try {
                if (
                  ["jpg", "jpeg", "tiff", "heic", "heif"].includes(extension)
                ) {
                  const exifData = await exifr.parse(file);
                  if (exifData) {
                    if (["heic", "heif"].includes(extension)) {
                      file = await h.convertToJpeg(file);
                    }
                    const exifData = await exifr.parse(file);
                    if (exifData) file = await h.stripExif(file);
                  }
                }
              } catch (err) {
                console.log("remove exif error", err, file);
                alert(
                  `There was an error removing the EXIF data from ${file.name}, but the file can still be processed with EXIF data remaining.`
                );
              }

              const md5 = await h.getMD5(file);
              const thumbnail = file.type.includes("video")
                ? await h.getVideoThumbnail(file)
                : false;
              files.push({
                name: file.name,
                file: file,
                path: URL.createObjectURL(file),
                md5: md5,
                size: file.size,
                type: file.type,
                thumbnail,
              });
            } catch (err) {
              console.log("Error processing file", err, file);
              throw "An error occurred. Please try again later";
            }
          }
          this.setState(
            (curr) => ({
              ...curr,
              files: [
                ...curr.files,
                ...files.filter(
                  (file) => !this.state.files.find((f) => f.md5 === file.md5)
                ),
              ].filter((file, f) => f < Number(MAX_FILE_COUNT)),
              processingFiles: false,
              selectingFiles: false,
            }),
            () => {
              if (input) document.body.removeChild(input);
              const lastFile = document.getElementById(
                `file-${this.state.files[this.state.files.length - 1].md5}`
              );
              if (lastFile) lastFile.scrollIntoView();
            }
          );
        } catch (err) {
          console.log("select files error", err);
          this.setState(
            (curr) => ({
              ...curr,
              selectingFiles: false,
            }),
            () => alert(err)
          );
        }
      }
    );

  scroll = (e) => {
    document.getElementById("image-container").scrollLeft +=
      100 * Math.sign(e.deltaY);
  };

  /**
   * Fired when the user clicks the Details button
   * Toggles the Details form
   * If open, scroll into view
   *
   */
  toggleDetails = () =>
    this.setState(
      {
        ...this.state,
        showDetails: !this.state.showDetails,
      },
      () =>
        setTimeout(() => {
          if (this.state.showDetails)
            document.getElementById("hidden_check").scrollIntoView();
        }, 500)
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Run image upload redux script
   */
  submit = () => {
    document.getElementById("form").classList.add("was-validated");
    if (!this.state.files.length) alert("You must select at least one file");
    else {
      let invalidInputs = this.state.inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) =>
        document.getElementById(input.id).setCustomValidity(input.error)
      );
      if (!this.state.working && !invalidInputs.length)
        this.setState(
          {
            ...this.state,
            working: true,
          },
          async () => {
            const body = Object.fromEntries(
              this.state.inputs.map((input) => [input.id, input.value])
            );
            try {
              document.getElementById("root").scrollTop = 0;
              const captchaKey = await h.getRecaptcha(
                this.props.googleReCaptchaProps
              );
              body.captchaKey = captchaKey;
              const processedFiles = [];
              if (this.state.files.length) {
                if (
                  this.state.files.reduce((prev, curr) => prev + curr.size, 0) >
                  Number(MAX_TOTAL_FILE_SIZE)
                )
                  throw `Max total file size exceeded. (Max: ${Math.round(
                    Number(MAX_TOTAL_FILE_SIZE) / (1024 * 1024)
                  )}MB)`;
                this.setState((curr) => ({
                  ...curr,
                  processingFiles: {
                    indicator: (
                      <>
                        <Spinner size="sm" className="me-2" />
                        Processing Files
                      </>
                    ),
                    processed: [],
                    current: "",
                    progress: 0,
                  },
                }));

                const multipartRequests = await h.getMultipartRequests(
                  this.state.files
                );
                for (let f = 0; f < this.state.files.length; f++) {
                  let file = this.state.files[f];
                  const processed = {};
                  this.setState((curr) => ({
                    ...curr,
                    processingFiles: {
                      ...curr.processingFiles,
                      indicator: (
                        <>
                          <Spinner size="sm" className="me-2" />
                          Processing Files {f + 1}/{this.state.files.length}
                        </>
                      ),
                      current: file.md5,
                      progress: 0,
                      processed: curr.processingFiles.current
                        ? [
                            ...curr.processingFiles.processed,
                            curr.processingFiles.current,
                          ]
                        : curr.processingFiles.processed,
                    },
                  }));

                  const type = file.type.split("/")[0].toLowerCase();
                  processed.mimeType = file.type;
                  switch (type) {
                    case "audio":
                      processed.type = "audio";
                      break;
                    case "video":
                      processed.type = "video";
                      break;
                    case "image":
                      processed.type = "image";
                      break;
                    default:
                      processed.type = "other";
                  }
                  try {
                    const initializeResponse = multipartRequests.find(
                      (request) =>
                        request.md5 === file.md5 &&
                        !request.fileKey.includes("thumbnails")
                    );
                    processed.main = await this.processFile({
                      ...file,
                      initializeResponse,
                    });

                    if (type === "video") {
                      const initializeResponse = multipartRequests.find(
                        (request) =>
                          request.md5 === file.md5 &&
                          request.fileKey.includes("thumbnails")
                      );
                      processed.thumbnail = await this.processFile(
                        {
                          ...file,
                          initializeResponse,
                        },
                        true
                      );
                    } else if (type === "image") {
                      processed.thumbnail = await h.processThumbnail(
                        `files/${processed.main}`
                      );
                    }
                    processedFiles.push(processed);
                  } catch (err) {
                    console.log("file error", err);
                  }
                }

                this.setState((curr) => ({
                  ...curr,
                  processingFiles: {
                    ...curr.processingFiles,
                    indicator: (
                      <>
                        <Spinner className="me-2" size="sm" />
                        Sending
                      </>
                    ),
                    current: "",
                    progress: 0,
                    processed: [
                      ...curr.processingFiles.processed,
                      curr.processingFiles.current,
                    ],
                  },
                }));

                body.files = processedFiles;

                image_schema.validateSync(body, {
                  abortEarly: false,
                });
                body.commentsDisabled = document.getElementById(
                  "commentsDisabled_check"
                ).checked;
                body.nsfw = document.getElementById("nsfw_check").checked;
                body.singleComments = document.getElementById(
                  "singleComments_check"
                ).checked;
                body.hidden = document.getElementById("hidden_check").checked;
                axios
                  .post("/images/upload", body)
                  .then((res) => {
                    /**
                     * If single image, destination is image page.
                     * If multiple images, destination is a series.
                     * If user is on home page, route to image/series.
                     * Otherwise, open in new tab
                     */
                    console.log(res);
                    if (res.data.imageID) {
                      if (window.location.pathname === "/")
                        this.props.route(`/file/${res.data.imageID}`);
                      else window.open(`/file/${res.data.imageID}`);
                    } else {
                      this.props.set_series(res.data.series);
                      setTimeout(() => {
                        if (window.location.pathname === "/")
                          this.props.route(`/series/${res.data.series._id}`);
                        else window.open(`/series/${res.data.series._id}`);
                      });
                    }

                    if (res.data.alreadyPosted)
                      document.getElementById("already-posted-ref").click();
                    else if (res.data.alreadyPostedMultiple)
                      document
                        .getElementById("already-posted-multiple-ref")
                        .click();
                  })
                  .catch((err) => {
                    console.log("error", err);
                    alert("An error occurred. Please try again later.");
                  });
              }
            } catch (err) {
              this.setState(
                {
                  ...this.state,
                  working: false,
                  uploading: false,
                },
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              );
            }
          }
        );
      else
        this.setState({
          ...this.state,
          showDetails: true,
        });
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          image_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          console.log(err);
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error);
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );

  /**
   *
   * @param {Event Object} e
   *
   * Fired when the user clicks the Remove Files button
   * Clears all the files
   */
  clearFiles = (e) => {
    document.getElementById("singleComments_check").checked = true;
    e.stopPropagation();
    this.setState({
      ...this.state,
      files: [],
      imageHovered: "",
      mediaPlaying: "",
    });
  };

  /**
   *
   * @param {String} md5 - md5 hash of the file selected
   *
   * Triggered when the user clicks an audio or video file
   * Stop the file that is currently playing, if any
   * Play the file
   */
  selectMedia = (md5, e) => {
    e?.stopPropagation();
    if (this.state.mediaPlaying)
      document.getElementById(this.state.mediaPlaying)?.pause();
    this.setState(
      (curr) => ({
        ...curr,
        mediaPlaying: this.state.mediaPlaying === md5 ? "" : md5,
      }),
      () => {
        if (this.state.mediaPlaying)
          document.getElementById(this.state.mediaPlaying)?.play();
      }
    );
  };

  /**
   *
   * @param {String} md5 - md5 of the image hovered
   *
   */
  setImageHovered = (md5) =>
    this.setState({
      ...this.state,
      imageHovered: md5,
    });

  progressHandler = (e) => {
    /**
     * Progress bar will move quicker as it gets closer to 100
     */
    this.setState((curr) => ({
      ...curr,
      processingFiles: {
        ...curr.processingFiles,
        progress: Number(e.percentage),
      },
    }));
  };

  /**
   *
   * @param {File} file - Javascript file object
   * @returns A file thumbnail/preview depending on the type of file that it is
   */
  getFileThumbnail = (file) => {
    switch (file.type.split("/")[0]) {
      case "image":
        return (
          <div
            className="fit-images position-relative"
            style={{ backgroundImage: `url("${file.path}")` }}
          >
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white mb-2">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>
                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      case "video":
        const videoPlayable = [
          "video/mp4",
          "video/webm",
          "video/quicktime",
        ].includes(file.type);
        return (
          <MDBRipple
            onClick={
              videoPlayable ? (e) => this.selectMedia(file.md5, e) : () => {}
            }
            className={`h-100 w-100 d-flex justify-content-center align-items-center position-relative ${
              this.state.processingFiles || !videoPlayable
                ? ""
                : "cursor-pointer"
            } position-relative`}
          >
            {videoPlayable ? (
              <video
                className="position-absolute max-w-100 max-h-100"
                src={file.path}
                id={file.md5}
              ></video>
            ) : (
              <>
                {file.thumbnail ? (
                  <img
                    className="position-absolute h-100 d-block mx-auto top-0 start-0 end-0 bottom-0"
                    src={URL.createObjectURL(file.thumbnail)}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>

                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {videoPlayable ? (
                  <>
                    {this.state.mediaPlaying === file.md5 ? (
                      <>
                        {this.state.imageHovered === file.md5 ? (
                          <motion.i
                            transition={t.transition}
                            exit={t.fade_out_scale_1}
                            animate={t.normalize}
                            initial={t.fade_out}
                            style={{
                              color: "rgba(255, 255, 255, 0.5)",
                              zIndex: 30,
                            }}
                            className="fas fa-pause fa-5x"
                          ></motion.i>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        style={{
                          color: "rgba(255, 255, 255, 0.5)",
                          zIndex: 30,
                        }}
                        className="fas fa-play fa-5x"
                      ></motion.i>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </MDBRipple>
        );
      case "audio":
        return (
          <>
            <audio src={file.path} id={file.md5}></audio>
            <MDBRipple
              onClick={(e) => this.selectMedia(file.md5, e)}
              className={`h-100 d-flex w-100 justify-content-center align-items-center ${
                this.state.processingFiles ? "" : "cursor-pointer"
              } position-relative`}
            >
              {this.state.processingFiles ? (
                <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                  {this.state.processingFiles.processed.includes(file.md5) ? (
                    <motion.i
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                      className="fas fa-check-circle text-success fa-2x"
                    ></motion.i>
                  ) : (
                    <>
                      {this.state.processingFiles?.current === file.md5 ? (
                        <motion.div
                          transition={t.transition}
                          initial={t.fade_out}
                          animate={t.normalize}
                          exit={t.fade_out_scale_1}
                          className="w-100 d-flex flex-column justify-content-center align-items-center"
                        >
                          <h5 className="text-center text-white">
                            {this.state.processingFiles.progress >= 100 ? (
                              <motion.span
                                transition={t.transition}
                                initial={t.fade_out}
                                animate={t.normalize}
                                exit={t.fade_out}
                              >
                                Processing
                              </motion.span>
                            ) : (
                              <>
                                <Count
                                  value={this.state.processingFiles.progress}
                                />
                                %
                              </>
                            )}
                          </h5>

                          <MDBProgress
                            className={`w-80 ${
                              this.state.processingFiles.progress >= 100
                                ? "opacity-flash scale-1"
                                : ""
                            }`}
                            style={{
                              height: "3px",
                              transitionDuration: `${
                                0.6 - 0.6 * this.state.processingFiles.progress
                              }`,
                            }}
                          >
                            <MDBProgressBar
                              width={this.state.processingFiles.progress}
                              valuemin={0}
                              valuemax={100}
                              bgColor={
                                this.state.processingFiles.progress >= 100
                                  ? "success"
                                  : "primary"
                              }
                            />
                          </MDBProgress>
                        </motion.div>
                      ) : (
                        <motion.i
                          transition={t.transition}
                          initial={t.fade_out}
                          animate={t.normalize}
                          exit={t.fade_out_scale_1}
                          className="fas fa-ellipsis-h text-light fa-2x"
                        ></motion.i>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {this.state.mediaPlaying === file.md5 ? (
                    <motion.i
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="fas fa-pause fa-5x"
                    ></motion.i>
                  ) : (
                    <motion.i
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="fas fa-play fa-5x"
                    ></motion.i>
                  )}
                </>
              )}
            </MDBRipple>
          </>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center position-relative">
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>

                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <h6 className="file-labels-generic">
                {String(
                  file.name
                    .split(".")
                    [file.name.split(".").length - 1].split("?")[0]
                ).toUpperCase()}
              </h6>
            )}
          </div>
        );
    }
  };

  /**
   *
   * @param {String} md5 - md5 of the file to be removed
   * @param {Event Object} e
   *
   * Fired when the user clicks a red trash can on any of the selected files
   */
  removeFile = (md5, e) => {
    if (this.state.files.length < 3)
      document.getElementById("singleComments_check").checked = true;
    e.stopPropagation();
    this.setState({
      ...this.state,
      files: this.state.files.filter((file) => file.md5 !== md5),
      imageHovered: "",
      mediaPlaying:
        this.state.mediaPlaying === md5 ? "" : this.state.mediaPlaying,
    });
  };

  /**
   *
   * @param {Event Object} e - Javascript event object
   *
   * Prevent the form from submitting if the enter key is pressed
   */
  preventEnterSubmit = (e) => {
    const key = e.charCode || e.keyCode;
    if (key === 13 && e.target.tagName === "INPUT") e.preventDefault();
  };

  /**
   * Add emoji to the comment body if selected
   */
  selectEmoji = (e) => {
    if (!this.state.working)
      this.changeHandler({
        target: {
          value:
            this.state.inputs.find((i) => i.id === "manifesto").value + e.char,
          name: "manifesto",
        },
      });
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4"
      >
        <MDBContainer className="pb-3">
          <>
            <h1 className="text-center display-4">Upload</h1>
            <p className="text-center text-italic my-0">
              <small>
                You can now upload <span className="fw-bold">ANY</span> type of
                file, not just images
              </small>
            </p>
            <hr></hr>
            {this.state.selectingFiles ? (
              <>
                <video
                  id="video-thumbnail-temp"
                  className="opacity-0 position-fixed"
                />
                <canvas
                  id="canvas-thumbnail-temp"
                  className="opacity-0 position-fixed"
                />
                <div className="d-flex justify-content-center align-items-center my-2">
                  <LogoLoader />
                </div>
              </>
            ) : (
              <>
                {this.state.files.length ? (
                  <>
                    <MDBRipple
                      rippleColor="primary"
                      id="file-upload-container-files"
                      onClick={this.selectFiles}
                      className="container-fluid px-0 cursor-pointer"
                    >
                      <MDBBtn
                        onClick={this.clearFiles}
                        color="link"
                        rippleColor="danger"
                        size="lg"
                        className="d-block ms-auto text-danger"
                        disabled={
                          this.state.processingFiles || this.state.working
                        }
                      >
                        <i className="fas fa-times me-2"></i>Clear All
                      </MDBBtn>
                      <div className="row mx-0 justify-content-center">
                        {this.state.files.map((file) => (
                          <div
                            id={`file-${file.md5}`}
                            key={file.md5}
                            className={
                              this.flavor === "main"
                                ? "col-12 col-md-6 col-lg-4 col-xl-3 my-2"
                                : "col-12 col-sm-6 col-md-4 col-xl-3 my-2"
                            }
                          >
                            <div
                              onMouseEnter={() =>
                                this.setImageHovered(file.md5)
                              }
                              onMouseLeave={() => this.setImageHovered("")}
                              style={{ border: "1px solid #607D8B" }}
                              className={`mx-auto p-2 d-flex justify-content-center align-items-center square-15 position-relative ${
                                !this.state.processingFiles &&
                                this.state.imageHovered === file.md5
                                  ? "image-hover"
                                  : ""
                              } ${
                                this.state.processingFiles
                                  ? "file-labels-dark"
                                  : ""
                              }`}
                            >
                              {this.getFileThumbnail(file)}
                              <motion.div
                                transition={t.transition}
                                exit={t.fade_out_scale_1}
                                animate={t.normalize}
                                initial={t.fade_out}
                                className="file-labels-dark position-absolute top-0 m-0 w-100"
                              >
                                <p
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "80%",
                                  }}
                                  className="ms-1 my-1 text-nowrap text-light"
                                >
                                  {file.name}
                                </p>
                              </motion.div>
                              {this.state.imageHovered === file.md5 &&
                              !this.state.processingFiles ? (
                                <motion.div
                                  transition={t.transition}
                                  exit={t.fade_out_scale_1}
                                  animate={t.normalize}
                                  initial={t.fade_out}
                                  className="position-absolute top-0 end-0 m-0 w-100"
                                >
                                  <MDBBtn
                                    onClick={(e) =>
                                      this.removeFile(file.md5, e)
                                    }
                                    className="text-danger p-2 ms-auto d-block"
                                    color="link"
                                    style={{ zIndex: 20 }}
                                    size="lg"
                                    disabled={
                                      this.state.processingFiles ||
                                      this.state.working
                                    }
                                  >
                                    <i className="far fa-trash-alt fa-lg" />
                                  </MDBBtn>
                                </motion.div>
                              ) : (
                                <></>
                              )}
                              <motion.p
                                transition={t.transition}
                                exit={t.fade_out_scale_1}
                                animate={t.normalize}
                                initial={t.fade_out}
                                style={{ textOverflow: "ellipsis" }}
                                className="file-labels-dark position-absolute bottom-0 m-0 w-100 text-center text-light"
                              >
                                {h.getFileSize(file.size)}
                              </motion.p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </MDBRipple>
                  </>
                ) : (
                  <>
                    <MDBRipple
                      rippleColor="primary"
                      onClick={this.selectFiles}
                      id="file-upload-container"
                      className="d-flex justify-content-center align-items-center w-100 cursor-pointer rounded"
                    >
                      <MDBIcon icon="cloud-upload-alt" size="5x" />
                    </MDBRipple>
                  </>
                )}
              </>
            )}
            <hr></hr>
            <div className="d-flex justify-content-center">
              <MDBBtn
                onClick={this.submit}
                className="me-2 d-block"
                color="success"
                size="lg"
                disabled={this.state.processingFiles || this.state.working}
              >
                {this.state.processingFiles ? (
                  this.state.processingFiles.indicator
                ) : (
                  <>
                    {this.state.working ? (
                      <>
                        <Spinner className="me-2" size="sm" />
                        Sending
                      </>
                    ) : (
                      <>
                        <i className="fas fa-paper-plane me-2"></i>Submit
                      </>
                    )}
                  </>
                )}
              </MDBBtn>
              <MDBBtn
                onClick={this.toggleDetails}
                className="ms-3 d-block"
                style={{ backgroundColor: "#1976D2" }}
                size="lg"
                disabled={this.state.processingFiles || this.state.working}
              >
                <i className="fas fa-info-circle me-2"></i>Details
              </MDBBtn>
            </div>
            <Collapse in={this.state.showDetails}>
              <div className="form-containers-lg mt-4">
                <MDBValidation name="form" method="dialog" id="form">
                  {fields.map((i) => (
                    <MDBValidationItem
                      key={i.id}
                      className="pb-4"
                      feedback={
                        this.state.inputs.find((input) => input.id === i.id)
                          .error
                      }
                      invalid={true}
                    >
                      {i.type === "input" ? (
                        <MDBInput
                          name={i.id}
                          onChange={this.changeHandler}
                          id={i.id}
                          label={i.text}
                          size="lg"
                          className={
                            !this.state.inputs.find(
                              (input) => input.id === i.id
                            ).invalid
                              ? "mb-0"
                              : 0
                          }
                        />
                      ) : (
                        <MDBTextArea
                          name={i.id}
                          onChange={this.changeHandler}
                          id={i.id}
                          label={i.text}
                          size="lg"
                          className={
                            !this.state.inputs.find(
                              (input) => input.id === i.id
                            ).invalid
                              ? "mb-0"
                              : 0
                          }
                          style={{ minHeight: "10rem" }}
                          value={
                            this.state.inputs.find(
                              (input) => input.id === "manifesto"
                            ).value
                          }
                        />
                      )}
                    </MDBValidationItem>
                  ))}
                  <div className="d-flex justify-content-between">
                    <div>
                      <MDBCheckbox
                        labelClass="mb-0"
                        name="commentsDisabled"
                        id="commentsDisabled_check"
                        value=""
                        label="Disable Comments"
                      />
                      <MDBCheckbox
                        labelClass="mb-0"
                        name="nsfw"
                        id="nsfw_check"
                        value=""
                        label="Mark NSFW"
                      />
                      <MDBCheckbox
                        disabled={this.state.files.length < 2}
                        labelClass="mb-0"
                        defaultChecked
                        name="singleComments"
                        id="singleComments_check"
                        value=""
                        label="Same Comment Section for All Files"
                      />
                      <MDBCheckbox
                        labelClass="mb-0"
                        name="hidden"
                        id="hidden_check"
                        value=""
                        label="Hide from Browse page"
                      />
                    </div>
                    <div className="d-flex">
                      <EmojiPicker onEmojiSelect={this.selectEmoji} />
                      <p className="text-end mb-0 ms-2">
                        <span
                          className={
                            this.state.inputs.find((i) => i.id === "manifesto")
                              .value.length > 10000
                              ? "text-danger"
                              : ""
                          }
                        >
                          {
                            this.state.inputs.find((i) => i.id === "manifesto")
                              .value.length
                          }
                        </span>
                        /10000
                      </p>
                    </div>
                  </div>
                </MDBValidation>
              </div>
            </Collapse>
          </>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { upload_images, set_series, route })(
  withGoogleReCaptcha(Home)
);
