import React from "react";
import Spinner from "../../../components/Spinner";
import {
  MDBToast,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBInput,
  MDBRipple,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import EmojiPicker from "../../../components/EmojiPicker";
import { connect } from "react-redux";

class AddCommentToast extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  /**
   * Need to validate/fix inputs when shown
   */
  showToast = () => {
    this.props.changeHandler({
      target: {
        value: "",
        name: "",
      },
    });
    setTimeout(this.props.fixMDBInputs, 250);
  };

  render() {
    return (
      <MDBToast
        color={
          this.props.userInfo.userSettings.theme === "default"
            ? "light"
            : "dark"
        }
        position="bottom-right"
        appendToBody
        headerContent={<h5 className="me-auto">Add Comment</h5>}
        id="toast-comment"
        show={this.props.toastShown}
        onHide={this.props.hideToast}
        onShow={this.showToast}
        bodyContent={
          <>
            <MDBValidation
              id="comment_form_toast"
              method="dialog"
              name="comment_form_toast"
            >
              {this.props.fields.map((i) => (
                <MDBValidationItem
                  key={i.id + "-toast"}
                  className="pb-4"
                  feedback={
                    this.props.inputs.find((input) => input.id === i.id).error
                  }
                  invalid={true}
                >
                  {(() => {
                    switch (i.type) {
                      case "text":
                        return (
                          <MDBInput
                            name={i.id}
                            onChange={this.props.changeHandler}
                            id={i.id + "-toast"}
                            label={i.text}
                            size="lg"
                            type={i.type}
                            className={`comment-inputs ${
                              !this.props.inputs.find(
                                (input) => input.id === i.id
                              ).invalid
                                ? "mb-0"
                                : 0
                            }`}
                            value={
                              this.props.inputs.find(
                                (input) => input.id === i.id
                              ).value
                            }
                          />
                        );
                      case "number":
                        return (
                          <div className="w-100 d-flex">
                            <div className="w-50">
                              <MDBInput
                                name={i.id}
                                onChange={this.props.changeHandler}
                                id={i.id + "-toast"}
                                label={i.text}
                                size="lg"
                                type={i.type}
                                min="1"
                                className={`comment-inputs ${
                                  !this.props.inputs.find(
                                    (input) => input.id === i.id
                                  ).invalid
                                    ? "mb-0"
                                    : 0
                                }`}
                                value={
                                  this.props.inputs.find(
                                    (input) => input.id === i.id
                                  ).value
                                }
                              />
                              {this.props.file ? (
                                <div className="mt-2">
                                  <MDBCheckbox
                                    name="commentsDisabled"
                                    id="check-commentsDisabled-toast"
                                    onChange={this.props.changeImageOptions}
                                    checked={
                                      this.props.imageOptions.commentsDisabled
                                    }
                                    className="check-commentsDisabled"
                                    label="Disable Comments"
                                    labelClass="mb-0"
                                  />
                                  <MDBCheckbox
                                    name="nsfw"
                                    id="check-nsfw-toast"
                                    onChange={this.props.changeImageOptions}
                                    checked={this.props.imageOptions.nsfw}
                                    className="check-nsfw"
                                    label="Mark NSFW"
                                    labelClass="mb-0"
                                  />
                                  <MDBCheckbox
                                    name="hidden"
                                    id="check-hidden-toast"
                                    onChange={this.props.changeImageOptions}
                                    checked={this.props.imageOptions.hidden}
                                    className="check-hidden"
                                    label="Hide from Browse"
                                    labelClass="mb-0"
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="w-50">
                              <MDBRipple
                                rippleTag="div"
                                style={{ cursor: "pointer" }}
                                onClick={this.props.selectAvatar}
                                className="border border-dark d-flex justify-content-center align-items-center square-8 mx-auto"
                              >
                                <div
                                  className="fit-images"
                                  style={{
                                    backgroundImage: `url("${
                                      this.props.file
                                        ? this.props.file.path
                                        : "/avatars/blank.webp"
                                    }")`,
                                  }}
                                ></div>
                              </MDBRipple>
                            </div>
                          </div>
                        );
                      case "textarea":
                        return (
                          <>
                            <MDBTextArea
                              name={i.id}
                              onChange={this.props.changeHandler}
                              id={i.id + "-toast"}
                              label={i.text}
                              size="lg"
                              className={`comment-inputs ${
                                !this.props.inputs.find(
                                  (input) => input.id === i.id
                                ).invalid
                                  ? "mb-0"
                                  : 0
                              }`}
                              style={{ minHeight: "10rem" }}
                              value={
                                this.props.inputs.find(
                                  (input) => input.id === i.id
                                ).value
                              }
                            />
                            <div className="d-flex justify-content-between p-2">
                              <EmojiPicker
                                emojiID={1}
                                onEmojiSelect={this.props.selectEmoji}
                              />
                              <p className="text-end m-0">
                                <span
                                  className={
                                    this.props.inputs.find(
                                      (i) => i.id === "body"
                                    ).value.length > 10000
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  {
                                    this.props.inputs.find(
                                      (i) => i.id === "body"
                                    ).value.length
                                  }
                                </span>
                                /10000
                              </p>
                            </div>
                          </>
                        );
                      default:
                        console.log("oob comment form", i.type);
                        return <></>;
                    }
                  })()}
                </MDBValidationItem>
              ))}
            </MDBValidation>
            {this.props.working ? (
              <MDBBtn size="lg" block disabled color="success">
                <Spinner size="sm" className="me-2"></Spinner>Sending
              </MDBBtn>
            ) : (
              <MDBBtn
                onClick={this.props.submit}
                size="lg"
                block
                color="success"
              >
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(AddCommentToast);
