import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import ChadminHeaders from "./users/chadmin/ChadminHeaders";
import ChadminList from "./users/chadmin/ChadminList";
import ChadminInfoModal from "./users/chadmin/ChadminInfoModal";
import NewChadminModal from "./users/chadmin/NewChadminModal";
import JannyHeaders from "./users/janny/JannyHeaders";
import JannyList from "./users/janny/JannyList";
import JannyInfoModal from "./users/janny/JannyInfoModal";
import NewJannyModal from "./users/janny/NewJannyModal";
import VerifiedHeaders from "./users/verified/VerifiedHeaders";
import VerifiedList from "./users/verified/VerifiedList";
import VerifiedInfoModal from "./users/verified/VerifiedInfoModal";
import NewVerifiedModal from "./users/verified/NewVerifiedModal";
import DisabledUsers from "./users/DisabledUsers";
import ToastMisc from "../../../components/notifications/ToastMisc";
import "./users/styles.css";
import {
  MDBContainer,
  MDBListGroup,
  MDBBtn,
  MDBSelect,
} from "mdb-react-ui-kit";
import LogoLoader from "../../../components/LogoLoader";

/**
 * This is the Users tab on the Admin dashboard
 * Users of any type can be added and altered here
 * Users are arranged on a table with 40 entries per page
 */

const itemsPerPage = 40;

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loaded: Boolean indicating whether a request for users from the server has completed
       * categorySelected: String, category of users selected
       * users: Array of users obtained from the server
       * userSelected: Object containing data of the currently selected user
       * infoModal: Bootstrap Modal containing selected user info
       * disabledUsersModal: Bootstrap Modal containing disabled users
       * newUserModal: Bootstrap Modal containing the New User form
       * page: Number, current table page that the user is on
       * maxPages: Number, maximum number of table pages that have lists of users
       * sortBy: String, basis used to sort the users
       * sortDirection: String, sort direction, 'ascending' | 'descending'
       */
      loaded: false,
      categorySelected: "Chadmin",
      users: [],
      userSelected: {},
      page: 1,
      maxPages: 1,
      sortBy: "username",
      sortDirection: "ascending",
      infoModalShown: false,
      disabledModalShown: false,
      newModalShown: false,
      notificationText: "",
      notificationIcon: <></>,
    };
    this.toastRef = React.createRef(null);
  }

  componentDidMount() {
    /**
     * Get the initial list of users
     */
    this.getUsers();
  }

  getUsers = () =>
    this.setState(
      {
        ...this.state,
        loaded: false,
        /**
         * Get a list of users depending on the category selected
         * Set users into state
         * Calculate number of pages based on number of users returned
         */
      },
      () =>
        axios
          .get(`/users/${this.state.categorySelected}`)
          .then((res) =>
            this.setState({
              ...this.state,
              loaded: true,
              users: res.data.users,
              maxPages: Math.ceil(res.data.users.length / itemsPerPage),
            })
          )
          .catch((err) => {
            console.log(err);
            setTimeout(this.getUsers, 1000);
          })
    );

  selectUser = (user) => {
    /**
     * Fired when the user clicks on one of the users in the list
     * Sets the selection into state, then spawns a modal where the user can edit or disable their selection
     */
    this.setState(
      {
        ...this.state,
        userSelected: user,
      },
      () => {
        this.setState({
          ...this.state,
          infoModalShown: true,
        });
      }
    );
  };

  updateUser = (updatedUser, notificationInfo) =>
    this.setState(
      {
        /**
         * Fired by some of the child modals/components
         * Updates a user in the list of users that was updated by the child
         */
        ...this.state,
        users: this.state.users.map((user) =>
          user.username === updatedUser.oldUsername ||
          user.username === updatedUser.username
            ? updatedUser
            : user
        ),
      },
      () => {
        if (notificationInfo)
          this.setState(
            {
              ...this.state,
              notificationText: notificationInfo.text,
              notificationIcon: notificationInfo.icon,
            },
            () => this.toastRef.current.click()
          );
      }
    );

  addUser = (user, notificationInfo) =>
    this.setState(
      {
        /**
         * Fired by some of the child modals/components
         * Adds a user to the list of users that was added by the child
         */
        ...this.state,
        users: [...this.state.users, user],
      },
      () => {
        if (notificationInfo)
          this.setState(
            {
              ...this.state,
              notificationText: notificationInfo.text,
              notificationIcon: notificationInfo.icon,
            },
            () => this.toastRef.current.click()
          );
      }
    );

  disabledUsers = () =>
    this.setState({
      /**
       * Fired when the user clicks the black Disabled Users button
       * Spawns the Disabled Users modal
       */
      ...this.state,
      disabledModalShown: true,
    });

  newUser = () =>
    this.setState({
      /**
       * Fired when the user clicks the black New Users button
       * Spawns the New Users modal
       */
      ...this.state,
      newModalShown: true,
    });

  changeCategory = (e) =>
    this.setState(
      {
        /**
         * Fired when the user category changes
         * Resets state, then fetches users
         */
        ...this.state,
        loaded: false,
        categorySelected: e.value,
        users: [],
        userSelected: {},
        infoModal: "",
        disabledUsersModal: "",
        newUserModal: "",
      },
      () => this.getUsers()
    );

  sortUsers = (users) => {
    /**
     * Receives the users array, then sorts the users based on the sort basis
     */
    switch (this.state.sortBy) {
      case "username":
        if (this.state.sortDirection === "ascending")
          return users.sort((a, b) => a.username.localeCompare(b.username));
        else return users.sort((b, a) => a.username.localeCompare(b.username));
      case "comments":
        if (this.state.sortDirection === "ascending")
          return users.sort((a, b) =>
            `${a.comments}`.localeCompare(`${b.comments}`)
          );
        else
          return users.sort((b, a) =>
            `${a.comments}`.localeCompare(`${b.comments}`)
          );
      case "date":
        if (this.state.sortDirection === "ascending")
          return users.sort(
            (a, b) => new Date(a.creationDate) - new Date(b.creationDate)
          );
        else
          return users.sort(
            (b, a) => new Date(a.creationDate) - new Date(b.creationDate)
          );
      case "email":
        if (this.state.sortDirection === "ascending")
          return users.sort((a, b) => a.email.localeCompare(b.email));
        else return users.sort((b, a) => a.email.localeCompare(b.email));
    }
    return users;
  };

  setSort = (sort) => {
    /**
     * Fired when the user clicks one of the table headers
     * If messages are already sorted by the corresponding header, change the sort direction
     * Else, sort by the corresponding header
     */
    if (sort === this.state.sortBy)
      this.setState({
        ...this.state,
        sortDirection:
          this.state.sortDirection === "ascending" ? "descending" : "ascending",
      });
    else
      this.setState({
        ...this.state,
        sortBy: sort,
      });
  };

  changePage = (increment) =>
    this.setState({
      /**
       * Fired when the user clicks one of the page arrows
       * Changes the page
       */
      ...this.state,
      page: increment ? this.state.page + 1 : this.state.page - 1,
    });

  setDisabledModal = (option) =>
    this.setState({
      ...this.state,
      disabledModalShown: option,
    });

  toggleDisabledModal = () =>
    this.setState({
      ...this.state,
      disabledModalShown: !this.state.disabledModalShown,
    });

  setInfoModal = (option) =>
    this.setState({
      ...this.state,
      infoModalShown: option,
    });

  toggleInfoModal = () =>
    this.setState({
      ...this.state,
      infoModalShown: !this.state.infoModalShown,
    });

  setNewModal = (option) =>
    this.setState({
      ...this.state,
      newModalShown: option,
    });

  toggleNewModal = () =>
    this.setState({
      ...this.state,
      newModalShown: !this.state.newModalShown,
    });

  render() {
    return (
      <div className="h-100 d-flex flex-column p-3">
        <MDBBtn
          id="toast-trigger-users"
          className="d-none"
          color="primary"
          ref={this.toastRef}
        >
          Toast
        </MDBBtn>
        <ToastMisc
          triggerRef={this.toastRef}
          id={"users-toast"}
          icon={this.state.notificationIcon}
          text={this.state.notificationText}
        />
        <div className="d-flex">
          <MDBBtn size="lg" onClick={this.newUser} color="success">
            <i className="fas fa-plus me-2"></i>New User
          </MDBBtn>
          {this.state.users.filter((u) => u.disabled).length ? (
            <MDBBtn
              size="lg"
              onClick={this.disabledUsers}
              color="dark"
              className="ms-2"
            >
              Disabled Users
            </MDBBtn>
          ) : (
            <></>
          )}
        </div>
        <hr></hr>
        <MDBContainer fluid className="px-0">
          <div className="row mx-0">
            <div className="col-lg-3 col-12">
              <div className="form-floating">
                <MDBSelect
                  label="User Role"
                  size="lg"
                  data={[
                    {
                      text: "Chadmins",
                      value: "Chadmin",
                      defaultSelected:
                        this.state.categorySelected === "Chadmin",
                    },
                    {
                      text: "Jannies",
                      value: "Janny",
                      defaultSelected: this.state.categorySelected === "Janny",
                    },
                    {
                      text: "Verified",
                      value: "Verified",
                      defaultSelected:
                        this.state.categorySelected === "Verified",
                    },
                  ]}
                  onValueChange={this.changeCategory}
                  className="my-2 select-users"
                />
              </div>
            </div>
            {this.state.maxPages > 1 ? (
              <div className="col-lg-6 col-12">
                <MDBContainer className="px-0">
                  <div className="row mx-0">
                    <div className="col-3 col-lg-4 d-flex justify-content-end">
                      {this.state.page !== 1 ? (
                        <MDBBtn
                          onClick={() => this.changePage(false)}
                          color="dark"
                        >
                          <i className="fas fa-chevron-left"></i>
                        </MDBBtn>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-6 col-lg-4">
                      <h5 className="text-center mx-4 display-6">
                        Page {this.state.page}
                      </h5>
                    </div>
                    <div className="col-3 col-lg-4">
                      {this.state.page !== this.state.maxPages ? (
                        <MDBBtn
                          onClick={() => this.changePage(true)}
                          color="dark"
                        >
                          <i className="fas fa-chevron-right"></i>
                        </MDBBtn>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </MDBContainer>
              </div>
            ) : (
              <></>
            )}
          </div>
        </MDBContainer>
        {this.state.loaded ? (
          <>
            <div className="fg-1 users-container-dashboard">
              <MDBContainer className="d-flex flex-column h-100 px-0">
                <DisabledUsers
                  setDisabledModal={this.setDisabledModal}
                  toggleDisabledModal={this.toggleDisabledModal}
                  disabledModalShown={this.state.disabledModalShown}
                  updateUser={this.updateUser}
                  users={this.state.users.filter((user) => user.disabled)}
                />
                {(() => {
                  switch (this.state.categorySelected) {
                    case "Chadmin":
                      return (
                        <ChadminInfoModal
                          toggleInfoModal={this.toggleInfoModal}
                          setInfoModal={this.setInfoModal}
                          updateUser={this.updateUser}
                          userSelected={this.state.userSelected}
                          infoModalShown={this.state.infoModalShown}
                        />
                      );
                    case "Janny":
                      return (
                        <JannyInfoModal
                          toggleInfoModal={this.toggleInfoModal}
                          setInfoModal={this.setInfoModal}
                          updateUser={this.updateUser}
                          userSelected={this.state.userSelected}
                          infoModalShown={this.state.infoModalShown}
                        />
                      );
                    case "Verified":
                      return (
                        <VerifiedInfoModal
                          toggleInfoModal={this.toggleInfoModal}
                          setInfoModal={this.setInfoModal}
                          updateUser={this.updateUser}
                          userSelected={this.state.userSelected}
                          infoModalShown={this.state.infoModalShown}
                        />
                      );
                    default:
                      return <></>;
                  }
                })()}
                {(() => {
                  switch (this.state.categorySelected) {
                    case "Chadmin":
                      return (
                        <NewChadminModal
                          toggleNewModal={this.toggleNewModal}
                          setNewModal={this.setNewModal}
                          newModalShown={this.state.newModalShown}
                          addUser={this.addUser}
                        />
                      );
                    case "Janny":
                      return (
                        <NewJannyModal
                          toggleNewModal={this.toggleNewModal}
                          setNewModal={this.setNewModal}
                          newModalShown={this.state.newModalShown}
                          addUser={this.addUser}
                        />
                      );
                    case "Verified":
                      return (
                        <NewVerifiedModal
                          toggleNewModal={this.toggleNewModal}
                          setNewModal={this.setNewModal}
                          newModalShown={this.state.newModalShown}
                          addUser={this.addUser}
                        />
                      );
                    default:
                      return <></>;
                  }
                })()}
                <div className="row mx-0 overflow-y-scroll">
                  {(() => {
                    switch (this.state.categorySelected) {
                      case "Chadmin":
                        return (
                          <ChadminHeaders
                            sortUsers={this.sortUsers}
                            setSort={this.setSort}
                            {...this.state}
                          />
                        );
                      case "Janny":
                        return (
                          <JannyHeaders
                            sortUsers={this.sortUsers}
                            setSort={this.setSort}
                            {...this.state}
                          />
                        );
                      case "Verified":
                        return (
                          <VerifiedHeaders
                            sortUsers={this.sortUsers}
                            setSort={this.setSort}
                            {...this.state}
                          />
                        );
                      default:
                        return <></>;
                    }
                  })()}
                </div>
                <div className="row fg-1 mx-0 users-container-dashboard">
                  <MDBListGroup className="h-100 overflow-y-auto pe-0">
                    {(() => {
                      switch (this.state.categorySelected) {
                        case "Chadmin":
                          return (
                            <ChadminList
                              key={JSON.stringify(this.state.users)}
                              page={this.state.page}
                              itemsPerPage={itemsPerPage}
                              sortUsers={this.sortUsers}
                              setSort={this.setSort}
                              {...this.state}
                              selectUser={this.selectUser}
                              users={this.state.users.filter(
                                (user) => !user.disabled
                              )}
                            />
                          );
                        case "Janny":
                          return (
                            <JannyList
                              key={JSON.stringify(this.state.users)}
                              page={this.state.page}
                              itemsPerPage={itemsPerPage}
                              sortUsers={this.sortUsers}
                              setSort={this.setSort}
                              {...this.state}
                              selectUser={this.selectUser}
                              users={this.state.users.filter(
                                (user) => !user.disabled
                              )}
                            />
                          );
                        case "Verified":
                          return (
                            <VerifiedList
                              key={JSON.stringify(this.state.users)}
                              page={this.state.page}
                              itemsPerPage={itemsPerPage}
                              sortUsers={this.sortUsers}
                              setSort={this.setSort}
                              {...this.state}
                              selectUser={this.selectUser}
                              users={this.state.users.filter(
                                (user) => !user.disabled
                              )}
                            />
                          );
                        default:
                          return <></>;
                      }
                    })()}
                  </MDBListGroup>
                </div>
              </MDBContainer>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <LogoLoader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(Users);
