import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { MDBContainer } from "mdb-react-ui-kit";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import {
  route,
  set_image_page,
  set_max_image_pages,
  set_images,
  set_image_sort,
} from "../../../redux/actions";
import ImageNav from "./images/ImageNav";
import ImagePage from "./images/ImagePage";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LogoLoader from "../../../components/LogoLoader";

const itemsPerPage = 40;

class Images extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loaded: Boolean - Whether the initial image list has been loaded
       * leftButtonExit: Object - framer-motion exit animation for the left nav button
       * rightButtonExit: Object - framer-motion exit animation for the right nav button
       * contentExit: Object - framer-motion exit animation for the images
       * contentEnter: Object - framer-motion entrance animation for the images
       */
      loaded: false,
      leftButtonExit: t.fade_out_minimize,
      rightButtonExit: t.fade_out_minimize,
      contentExit: t.fade_out,
      contentEnter: t.fade_out,
    };
  }

  /**
   * Load data once the app renders for the user
   */
  componentDidMount() {
    this.load();
  }

  /**
   * Request images from server
   * Set images and page numbers
   * Set state.loaded true
   * If images were already loaded from a previous visit to the dashboard, set state.loaded true, but reload anyway in background
   */
  load = () => {
    if (!this.props.images_dash.length)
      axios
        .get("/dashboard/user-images")
        .then((res) => {
          this.props.set_max_image_pages(
            Math.ceil(res.data.images.length / itemsPerPage)
          );
          this.props.set_images(res.data.images);
          this.setState({
            ...this.state,
            loaded: true,
          });
        })
        .catch((err) => {
          console.log(err);
          setTimeout(this.load, 1000);
        });
    else
      this.setState(
        {
          ...this.state,
          loaded: true,
        },
        () =>
          axios
            .get("/dashboard/user-images")
            .then((res) => {
              this.props.set_max_image_pages(
                Math.ceil(res.data.images.length / itemsPerPage)
              );
              this.props.set_images(res.data.images);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(this.load, 1000);
            })
      );
  };

  back = () => {
    this.setState(
      {
        /**
         * Sets the correct exit transition for the button and body
         * If stage is greater than 1, go down one stage, otherwise route to the home page
         */
        ...this.state,
        rightButtonExit:
          this.props.imagePage === this.props.totalImagePages
            ? t.fade_out_minimize
            : t.normalize,
        leftButtonExit:
          this.props.imagePage > 2 ? t.bob_left : t.fade_out_minimize,
        contentExit: t.fade_out_right,
        contentEnter: t.fade_out_left,
        softLoading: true,
      },
      () => this.props.set_image_page(this.props.imagePage - 1)
    );
  };

  next = () => {
    this.setState(
      {
        /**
         * Sets the correct exit transition for the button and body
         * If stage is greater than 1, go down one stage, otherwise route to the home page
         */
        ...this.state,
        rightButtonExit:
          this.props.imagePage === this.props.totalImagePages - 1
            ? t.fade_out_minimize
            : t.bob_right,
        leftButtonExit:
          this.props.imagePage === 1 ? t.fade_out_minimize : t.normalize,
        contentExit: t.fade_out_left,
        contentEnter: t.fade_out_right,
        softLoading: true,
      },
      () => this.props.set_image_page(this.props.imagePage + 1)
    );
  };

  /**
   * Navigates to the page that the user selects in the page select popover
   */
  go = () => {
    let pageNumber = document.getElementById("image-page-number").value;
    if (h.isNumeric(pageNumber)) {
      pageNumber = Number(pageNumber);
    } else pageNumber = 1;
    if (this.props.imagePage !== pageNumber)
      this.setState(
        {
          ...this.state,
          rightButtonExit:
            this.props.imagePage > pageNumber
              ? t.normalize
              : pageNumber === this.props.totalImagePages
              ? t.fade_out_minimize
              : t.bob_right,
          leftButtonExit:
            this.props.imagePage > pageNumber
              ? pageNumber === 1
                ? t.fade_out_minimize
                : t.bob_left
              : t.normalize,
          contentExit:
            this.props.imagePage > pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          contentEnter:
            this.props.imagePage < pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          softLoading: true,
        },
        () => this.props.set_image_page(pageNumber)
      );
  };

  render() {
    return (
      <div className="h-100 d-flex flex-column pt-2">
        {this.state.loaded ? (
          <MDBContainer fluid className="h-100 d-flex flex-column px-1">
            {this.props.images_dash.length ? (
              <>
                {this.props.totalImagePages > 1 ? (
                  <StaticRouter location={`/${this.props.imagePage}`}>
                    <AnimatePresence exitBeforeEnter>
                      <Switch key={this.props.imagePage}>
                        <Route exact path="/:page">
                          <ImageNav
                            page={this.props.imagePage}
                            key={this.props.imagePage}
                            leftButtonExit={this.state.leftButtonExit}
                            rightButtonExit={this.state.rightButtonExit}
                            back={this.back}
                            next={this.next}
                            go={this.go}
                            loaded={this.state.loaded}
                          />
                        </Route>
                      </Switch>
                    </AnimatePresence>
                  </StaticRouter>
                ) : (
                  <></>
                )}
                <div className="fg-1 images-container-dashboard">
                  <div
                    style={{ overflowX: "hidden" }}
                    className="h-100 overflow-y-auto px-3 mobile-px-0"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            style={{ fontSize: "1.25rem" }}
                            className="table-sticky-head cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            File
                          </th>
                          <th
                            style={{ fontSize: "1.25rem" }}
                            onClick={() => this.props.set_image_sort("date")}
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            ID - Date
                            {this.props.imageSortBy === "date" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.imageSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            style={{ fontSize: "1.25rem" }}
                            onClick={() => this.props.set_image_sort("views")}
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            Views
                            {this.props.imageSortBy === "views" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.imageSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            style={{ fontSize: "1.25rem" }}
                            onClick={() =>
                              this.props.set_image_sort("comments")
                            }
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            Comments
                            {this.props.imageSortBy === "comments" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.imageSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <StaticRouter location={`/${this.props.imagePage}`}>
                        <AnimatePresence exitBeforeEnter>
                          <Switch key={this.props.imagePage}>
                            <Route exact path="/:page">
                              <ImagePage
                                key={this.props.imagePage}
                                contentExit={this.state.contentExit}
                                contentEnter={this.state.contentEnter}
                                imageNav={this.props.imageNav}
                              />
                            </Route>
                          </Switch>
                        </AnimatePresence>
                      </StaticRouter>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <h1 className="mt-5 text-center display-6">
                You have not posted any files
              </h1>
            )}
          </MDBContainer>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <LogoLoader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_image_page,
  set_max_image_pages,
  set_images,
  set_image_sort,
})(Images);
