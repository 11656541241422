import React from "react";
import { connect } from "react-redux";
import { MDBBtn } from "mdb-react-ui-kit";
import AddCommentToast from "./comments/AddCommentToast";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import CommentNav from "./comments/CommentNav";
import CommentPage from "./comments/CommentPage";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import {
  set_profile_comment_page,
  add_profile_comment,
} from "../../redux/actions";

const itemsPerPage = 40;

class Comments extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * showCommentForm: Boolean indicating whether the Add Comment form is shown
       */
      showCommentForm: false,
    };
  }

  openCommentForm = () =>
    this.setState({
      ...this.state,
      showCommentForm: true,
    });

  hideCommentForm = () =>
    this.setState({
      ...this.state,
      showCommentForm: false,
    });

  /**
   * Fired when the user clicks the left nav button in the comment section
   */
  back = () => {
    this.setState(
      {
        ...this.state,
        rightButtonExit:
          this.props.profileCommentsPage ===
          this.props.totalProfileCommentsPages
            ? t.fade_out_minimize
            : t.normalize,
        leftButtonExit:
          this.props.profileCommentsPage > 2 ? t.bob_left : t.fade_out_minimize,
        contentExit: t.fade_out_right,
        contentEnter: t.fade_out_left,
        softLoading: true,
      },
      () =>
        this.props.set_profile_comment_page(this.props.profileCommentsPage - 1)
    );
  };

  /**
   * Fired when the user clicks the right nav button in the comment section
   */
  next = () => {
    this.setState(
      {
        ...this.state,
        rightButtonExit:
          this.props.profileCommentsPage ===
          this.props.totalProfileCommentsPages - 1
            ? t.fade_out_minimize
            : t.bob_right,
        leftButtonExit:
          this.props.profileCommentsPage === 1
            ? t.fade_out_minimize
            : t.normalize,
        contentExit: t.fade_out_left,
        contentEnter: t.fade_out_right,
        softLoading: true,
      },
      () =>
        this.props.set_profile_comment_page(this.props.profileCommentsPage + 1)
    );
  };

  /**
   *
   * @param {Number} index - Index of the page selector popover
   *
   * Fired when the user clicks the Go button in the page selector
   * Navigates to the page that was entered in the page selector input
   */
  go = (index) => {
    let pageNumber = document.getElementById(
      `profile-comment-page-number-${index}`
    ).value;
    if (h.isNumeric(pageNumber)) {
      pageNumber = Number(pageNumber);
    } else pageNumber = 1;
    if (this.props.profileCommentsPage !== pageNumber)
      this.setState(
        {
          ...this.state,
          rightButtonExit:
            this.props.profileCommentsPage > pageNumber
              ? t.normalize
              : pageNumber === this.props.totalProfileCommentsPages
              ? t.fade_out_minimize
              : t.bob_right,
          leftButtonExit:
            this.props.profileCommentsPage > pageNumber
              ? pageNumber === 1
                ? t.fade_out_minimize
                : t.bob_left
              : t.normalize,
          contentExit:
            this.props.profileCommentsPage > pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          contentEnter:
            this.props.profileCommentsPage < pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          softLoading: true,
        },
        () => this.props.set_profile_comment_page(pageNumber)
      );
  };

  /**
   *
   * @param {Object} comment - Comments document
   *
   * Fired when the user adds a new profile comment
   * Adds the new comment into application state
   */
  addProfileComment = (comment) =>
    this.setState(
      {
        ...this.state,
        contentExit: t.fade_out_right,
        contentEnter: t.fade_out_left,
        rightButtonExit:
          this.props.profileCommentsPage ===
          this.props.totalProfileCommentsPages
            ? t.fade_out_minimize
            : t.normalize,
        leftButtonExit: t.fade_out_minimize,
      },
      () => this.props.add_profile_comment(comment, itemsPerPage, true)
    );

  /**
   *
   * @param {Number} comment - ref Comments.comment_id
   * @param {Number} currentPage - The comment page that the user is currently on
   *
   * Scrolls to the comment if it is already in the document
   * If the comment is not already in the document, switches to the page where the comment is located, then scrolls to it
   */
  goToComment = (comment, currentPage) => {
    window.location.hash = `#comment-${comment.comment_id}`;
    const element = document.getElementById(`comment-${comment.comment_id}`);
    if (element) element.scrollIntoView();
    else {
      const index = this.props.profileComments.indexOf(comment) + 1;
      const page = Math.ceil(index / itemsPerPage);
      this.setState(
        {
          ...this.state,
          rightButtonExit:
            currentPage > page
              ? t.normalize
              : page === this.props.totalProfileCommentsPages
              ? t.fade_out_minimize
              : t.bob_right,
          leftButtonExit:
            currentPage > page
              ? page === 1
                ? t.fade_out_minimize
                : t.bob_left
              : t.normalize,
          contentExit: currentPage > page ? t.fade_out_right : t.fade_out_left,
          contentEnter: currentPage < page ? t.fade_out_right : t.fade_out_left,
        },
        () => {
          this.props.set_profile_comment_page(page);
          this.scrollToComment(comment.comment_id);
        }
      );
    }
  };

  /**
   *
   * @param {Number} commentID - ref Comments.comment_id
   *
   * Scrolls to a comment if it is in the document
   * Otherwise, wait a split second and try again (sometimes necessary if triggered during framer-motion animations)
   */
  scrollToComment = (commentID) =>
    setTimeout(() => {
      const comment = document.getElementById(`comment-${commentID}`);
      if (comment) comment.scrollIntoView();
      else this.scrollToComment(commentID);
    }, 400);

  render() {
    return (
      <div>
        <AddCommentToast
          showCommentForm={this.state.showCommentForm}
          googleReCaptchaProps={this.props.googleReCaptchaProps}
          setClickCommentID={(click) => (this.clickCommentID = click)}
          hideCommentForm={this.hideCommentForm}
          socket={this.props.socket}
          addProfileComment={this.addProfileComment}
          openCommentForm={this.openCommentForm}
        />
        <div
          className="d-flex justify-content-between align-items-center"
          id="profile-comment-row-nav"
        >
          <div id="profile-comment-nav" className="d-flex align-items-center">
            {this.props.profileComments.length ? (
              <h5 className="display-6 fs-4 my-0 mx-4">
                {h.numberWithCommas(this.props.profileComments.length)} Message
                {this.props.profileComments.length === 1 ? "" : "s"}
              </h5>
            ) : (
              <></>
            )}
            {this.props.totalProfileCommentsPages > 1 ? (
              <StaticRouter location={`/${this.props.profileCommentsPage}`}>
                <AnimatePresence exitBeforeEnter>
                  <Switch key={this.props.profileCommentsPage}>
                    <Route exact path="/:page">
                      <CommentNav
                        page={this.props.profileCommentsPage}
                        key={this.props.profileCommentsPage}
                        leftButtonExit={this.state.leftButtonExit}
                        rightButtonExit={this.state.rightButtonExit}
                        back={this.back}
                        next={this.next}
                        go={this.go}
                        loaded={this.state.loaded}
                        index={1}
                      />
                    </Route>
                  </Switch>
                </AnimatePresence>
              </StaticRouter>
            ) : (
              <></>
            )}
          </div>
          <MDBBtn
            id="profile-comment-add-btn"
            color="primary"
            onClick={this.openCommentForm}
          >
            <i className="fas fa-plus me-2"></i>Add Message
          </MDBBtn>
        </div>
        <hr></hr>
        <div style={{ overflowX: "hidden" }}>
          <StaticRouter location={`/${this.props.profileCommentsPage}`}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={this.props.profileCommentsPage}>
                <Route exact path="/:page">
                  <CommentPage
                    key={this.props.profileCommentsPage}
                    contentExit={this.state.contentExit}
                    contentEnter={this.state.contentEnter}
                    profileContentNav={this.props.profileContentNav}
                    socket={this.props.socket}
                    goToComment={this.goToComment}
                    clickCommentID={this.clickCommentID}
                  />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </div>
        {this.props.totalProfileCommentsPages > 1 ? (
          <StaticRouter location={`/${this.props.profileCommentsPage}`}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={this.props.profileCommentsPage}>
                <Route exact path="/:page">
                  <CommentNav
                    page={this.props.profileCommentsPage}
                    key={this.props.profileCommentsPage}
                    leftButtonExit={this.state.leftButtonExit}
                    rightButtonExit={this.state.rightButtonExit}
                    back={this.back}
                    next={this.next}
                    go={this.go}
                    loaded={this.state.loaded}
                    index={2}
                  />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_profile_comment_page,
  add_profile_comment,
})(Comments);
