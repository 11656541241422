import { store } from "../";
import axios from "axios";
// import request from 'request';

export const SET_HISTORY = "SET_HISTORY";
export const set_history = (location, history) => ({
  type: SET_HISTORY,
  data: {
    location: location,
    history: history,
  },
});

export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const UPLOAD_END = "UPLOAD_END";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const upload_images = (fd) => {
  axios
    .post("/images/upload", fd, {
      onUploadProgress: (e) => {
        /**
         * Progress bar will move quicker as it gets closer to 100
         */
        [].slice
          .call(document.getElementsByClassName("progress-bar"))
          .forEach(
            (p) =>
              (p.style.transitionDuration = `${(
                0.6 -
                0.6 * (e.loaded / e.total)
              ).toFixed(4)}s`)
          );
        store.dispatch({
          type: UPLOAD_PROGRESS,
          data: ((e.loaded / e.total) * 100).toFixed(2),
        });
      },
      progress: (e) => console.log(e),
    })
    .then((res) => {
      /**
       * If single image, destination is image page.
       * If multiple images, destination is a series.
       * If user is on home page, route to image/series.
       * Otherwise, open in new tab
       */
      if (res.data.imageID) {
        if (window.location.pathname === "/")
          store.dispatch({
            type: ROUTE,
            data: {
              prevRoute: "/",
              destination: `/file/${res.data.imageID}`,
              newImage: true,
            },
          });
        else window.open(`/file/${res.data.imageID}`);
      } else {
        store.dispatch({
          type: SET_SERIES,
          data: res.data.series,
        });
        setTimeout(() => {
          if (window.location.pathname === "/")
            store.dispatch({
              type: ROUTE,
              data: {
                prevRoute: "/",
                destination: `/series/${res.data.series._id}`,
                newImage: res.data.newImage,
              },
            });
          else window.open(`/series/${res.data.series._id}`);
        });
      }

      if (res.data.alreadyPosted)
        document.getElementById("already-posted-ref").click();
      else if (res.data.alreadyPostedMultiple)
        document.getElementById("already-posted-multiple-ref").click();
      setTimeout(() => {
        store.dispatch({
          type: UPLOAD_END,
        });
      }, 500);
      setTimeout(
        () =>
          store.dispatch({
            type: UPLOAD_PROGRESS,
            data: 0,
          }),
        1500
      );
    })
    .catch((err) => {
      console.log(err);
      alert(
        "There was an error uploading your file(s). Please try again later."
      );
      if (window.location.pathname === "/") window.location.reload();
      else {
        store.dispatch({
          type: UPLOAD_END,
        });
        setTimeout(
          () =>
            store.dispatch({
              type: UPLOAD_PROGRESS,
              data: 0,
            }),
          500
        );
      }
    });
  return {
    type: UPLOAD_IMAGES,
  };
};

export const SET_SERIES = "SET_SERIES";
export const set_series = (series) => ({
  type: SET_SERIES,
  data: series,
});

export const SET_USER = "SET_USER";
export const set_user = (userInfo) => ({
  type: SET_USER,
  data: userInfo,
});

export const SELECT_DASHBOARD_ITEM = "SELECT_DASHBOARD_ITEM";
export const select_dashboard_item = (item) => ({
  type: SELECT_DASHBOARD_ITEM,
  data: item,
});

export const PURGE_USER = "PURGE_USER";
export const purge_user = () => ({
  type: PURGE_USER,
});

export const NEW_IMAGE_RESET = "NEW_IMAGE_RESET";
export const new_image_reset = () => ({
  type: NEW_IMAGE_RESET,
});

export const PREVIOUS_COMMENTS = "PREVIOUS_COMMENTS";
export const previous_comments = () => ({
  type: PREVIOUS_COMMENTS,
});

export const NEXT_COMMENTS = "NEXT_COMMENTS";
export const next_comments = () => ({
  type: NEXT_COMMENTS,
});

export const DASHBOARD_NAV = "DASHBOARD_NAV";
export const dashboard_nav = (item) => ({
  type: DASHBOARD_NAV,
  data: item,
});

export const SET_COMMENT_PAGE = "SET_COMMENT_PAGE";
export const set_comment_page = (page) => ({
  type: SET_COMMENT_PAGE,
  data: page,
});

export const ROUTE = "ROUTE";
export const route = (destination) => {
  /**
   * Get state from reducer
   * Get current route
   * If user logged in and going to login, redirect to dashboard and vice-versa
   * Route user, then add most recent route to history stack in the reducer
   */

  console.log("route", destination);

  let state = store.getState();
  const prevRoute = state.history.location.pathname;
  if (destination === "/dashboard" && !state.userInfo.username)
    destination = "/login";
  else if (destination === "/login" && state.userInfo.username)
    destination = "/dashboard";

  switch (destination.split("/")[1]) {
    case "login":
    case "create-account":
    case "forgot-password":
      /**
       * If requesting the login page but already logged in, redirect to the dashboard
       */
      document.title = "Feed Nana";
      if (state.userInfo.username) destination = "/dashboard";
      break;
    case "dashboard":
      /**
       * If requesting the dashboard but not logged in, redirect to login
       */
      document.title = "Feed Nana";
      if (!state.userInfo.username) destination = "/login";
      break;
    case "file":
      document.title = `${destination.split("/")[2].split("#")[0]} - Feed Nana`;
      break;
    default:
      document.title = "Feed Nana";
  }

  return {
    type: "ROUTE",
    data: {
      prevRoute: prevRoute,
      destination: destination,
      newImage: false,
    },
  };
};

export const SET_REPORT_PAGE = "SET_REPORT_PAGE";
export const set_report_page = (page) => ({
  type: SET_REPORT_PAGE,
  data: page,
});

export const SET_REPORT_SORT = "SET_REPORT_SORT";
export const set_report_sort = (sortOption) => ({
  type: SET_REPORT_SORT,
  data: sortOption,
});

export const SET_SHOW_REPORT_TOAST = "SET_SHOW_REPORT_TOAST";
export const set_show_report_toast = (option) => ({
  type: SET_SHOW_REPORT_TOAST,
  data: option,
});

export const SET_MAX_REPORT_PAGES = "SET_MAX_REPORT_PAGES";
export const set_max_report_pages = (number) => ({
  type: SET_MAX_REPORT_PAGES,
  data: number,
});

export const SET_REPORTS = "SET_REPORTS";
export const set_reports = (reports) => ({
  type: SET_REPORTS,
  data: reports,
});

export const SELECT_REPORT = "SELECT_REPORT";
export const select_report = (reportID) => ({
  type: SELECT_REPORT,
  data: reportID,
});

export const SET_IMAGE_PAGE = "SET_IMAGE_PAGE";
export const set_image_page = (page) => ({
  type: SET_IMAGE_PAGE,
  data: page,
});

export const SET_IMAGE_SORT = "SET_IMAGE_SORT";
export const set_image_sort = (sortOption) => ({
  type: SET_IMAGE_SORT,
  data: sortOption,
});

export const SET_SHOW_IMAGE_TOAST = "SET_SHOW_IMAGE_TOAST";
export const set_show_image_toast = (option) => ({
  type: SET_SHOW_IMAGE_TOAST,
  data: option,
});

export const SET_MAX_IMAGE_PAGES = "SET_MAX_IMAGE_PAGES";
export const set_max_image_pages = (number) => ({
  type: SET_MAX_IMAGE_PAGES,
  data: number,
});

export const SET_IMAGES = "SET_IMAGES";
export const set_images = (images) => ({
  type: SET_IMAGES,
  data: images,
});

export const SELECT_IMAGE = "SELECT_IMAGE";
export const select_image = (imageID) => ({
  type: SELECT_IMAGE,
  data: imageID,
});

export const SET_IMAGE = "SET_IMAGE";
export const set_image = (image) => ({
  type: SET_IMAGE,
  data: image,
});

export const SET_NSFW_MODAL = "SET_NSFW_MODAL";
export const set_nsfw_modal = (option) => ({
  type: SET_NSFW_MODAL,
  data: option,
});

export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const update_image = (image) => ({
  type: UPDATE_IMAGE,
  data: image,
});

export const ADD_COMMENT = "ADD_COMMENT";
export const add_comment = (comment) => ({
  type: ADD_COMMENT,
  data: comment,
});

export const ADD_PROFILE_COMMENT = "ADD_PROFILE_COMMENT";
export const add_profile_comment = (comment, itemsPerPage, selfAdded) => ({
  type: ADD_PROFILE_COMMENT,
  data: {
    comment: comment,
    itemsPerPage: itemsPerPage,
    selfAdded: selfAdded,
  },
});

export const ADD_IMAGE_VIEW = "ADD_IMAGE_VIEW";
export const add_image_view = () => ({
  type: ADD_IMAGE_VIEW,
});

export const SET_IMAGE_COUNT = "SET_IMAGE_COUNT";
export const set_image_count = (count) => ({
  type: SET_IMAGE_COUNT,
  data: count,
});

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const update_comment = (comment) => ({
  type: UPDATE_COMMENT,
  data: comment,
});

export const UPDATE_PROFILE_COMMENT = "UPDATE_PROFILE_COMMENT";
export const update_profile_comment = (comment) => ({
  type: UPDATE_PROFILE_COMMENT,
  data: comment,
});

export const SET_MOD_LOG_PAGE = "SET_MOD_LOG_PAGE";
export const set_mod_log_page = (page) => ({
  type: SET_MOD_LOG_PAGE,
  data: page,
});

export const SET_MOD_LOG_SORT = "SET_MOD_LOG_SORT";
export const set_mod_log_sort = (sortOption) => ({
  type: SET_MOD_LOG_SORT,
  data: sortOption,
});

export const SET_SHOW_MOD_LOG_TOAST = "SET_SHOW_MOD_LOG_TOAST";
export const set_show_mod_log_toast = (option) => ({
  type: SET_SHOW_MOD_LOG_TOAST,
  data: option,
});

export const SET_MAX_MOD_LOG_PAGES = "SET_MAX_MOD_LOG_PAGES";
export const set_max_mod_log_pages = (number) => ({
  type: SET_MAX_MOD_LOG_PAGES,
  data: number,
});

export const SET_MOD_LOGS = "SET_MOD_LOGS";
export const set_mod_logs = (mod_logs) => ({
  type: SET_MOD_LOGS,
  data: mod_logs,
});

export const SELECT_MOD_LOG = "SELECT_MOD_LOG";
export const select_mod_log = (mod_logID) => ({
  type: SELECT_MOD_LOG,
  data: mod_logID,
});

export const SET_PROFILE_CONTENT_PAGE = "SET_PROFILE_CONTENT_PAGE";
export const set_profile_content_page = (page) => ({
  type: SET_PROFILE_CONTENT_PAGE,
  data: page,
});

export const SET_PROFILE_CONTENT_SORT = "SET_PROFILE_CONTENT_SORT";
export const set_profile_content_sort = (sortOption) => ({
  type: SET_PROFILE_CONTENT_SORT,
  data: sortOption,
});

export const SET_SHOW_PROFILE_CONTENT_TOAST = "SET_SHOW_PROFILE_CONTENT_TOAST";
export const set_show_profile_content_toast = (option) => ({
  type: SET_SHOW_PROFILE_CONTENT_TOAST,
  data: option,
});

export const SET_MAX_PROFILE_CONTENT_PAGES = "SET_MAX_PROFILE_CONTENT_PAGES";
export const set_max_profile_content_pages = (number) => ({
  type: SET_MAX_PROFILE_CONTENT_PAGES,
  data: number,
});

export const SET_PROFILE_CONTENT = "SET_PROFILE_CONTENT";
export const set_profile_content = (profile_contents) => ({
  type: SET_PROFILE_CONTENT,
  data: profile_contents,
});

export const SET_PROFILE_CONTENT_ALL = "SET_PROFILE_CONTENT_ALL";
export const set_profile_content_all = (profile_contents) => ({
  type: SET_PROFILE_CONTENT_ALL,
  data: profile_contents,
});

export const SELECT_PROFILE_CONTENT = "SELECT_PROFILE_CONTENT";
export const select_profile_content = (profile_contentID) => ({
  type: SELECT_PROFILE_CONTENT,
  data: profile_contentID,
});

export const SET_PROFILE_COMMENT_PAGE = "SET_PROFILE_COMMENT_PAGE";
export const set_profile_comment_page = (page) => ({
  type: SET_PROFILE_COMMENT_PAGE,
  data: page,
});

export const SET_PROFILE_COMMENT_SORT = "SET_PROFILE_COMMENT_SORT";
export const set_profile_comment_sort = (sortOption) => ({
  type: SET_PROFILE_COMMENT_SORT,
  data: sortOption,
});

export const SET_SHOW_PROFILE_COMMENT_TOAST = "SET_SHOW_PROFILE_COMMENT_TOAST";
export const set_show_profile_comment_toast = (option) => ({
  type: SET_SHOW_PROFILE_COMMENT_TOAST,
  data: option,
});

export const SET_MAX_PROFILE_COMMENT_PAGES = "SET_MAX_PROFILE_COMMENT_PAGES";
export const set_max_profile_comment_pages = (number) => ({
  type: SET_MAX_PROFILE_COMMENT_PAGES,
  data: number,
});

export const SET_PROFILE_COMMENTS = "SET_PROFILE_COMMENTS";
export const set_profile_comments = (profile_comments) => ({
  type: SET_PROFILE_COMMENTS,
  data: profile_comments,
});

export const SELECT_PROFILE_COMMENT = "SELECT_PROFILE_COMMENT";
export const select_profile_comment = (profile_commentID) => ({
  type: SELECT_PROFILE_COMMENT,
  data: profile_commentID,
});

export const SET_PROFILE_INFO = "SET_PROFILE_INFO";
export const set_profile_info = (profileInfo) => ({
  type: SET_PROFILE_INFO,
  data: profileInfo,
});
