import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { route } from "../../redux/actions";
import t from "../../utilities/transitions";
import h from "../../utilities/helpers";
import { MDBContainer, MDBBadge, MDBRipple } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import LogoLoader from "../../components/LogoLoader";

class BrowsePage extends React.Component {
  constructor(props) {
    super();
    /**
     * Browse page number is passed in as props, declared here, and never changes
     */
    this.page = props.page;
  }

  /**
   *
   * @param {Object} image - Image object
   * @returns User and badge, if applicable
   */
  getBadge = (image) => {
    switch (image.badge) {
      case "Chadmin":
        return (
          <span className="name-chadmin">
            <span style={{ color: "var(--mdb-yellow)" }}>{image.user}</span>
            <MDBBadge className="ms-2 badge-chadmin">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/meltrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Chadmin</p>
                {image.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Janny":
        return (
          <span className="name-janny">
            <span style={{ color: "var(--mdb-green)" }}>{image.user}</span>
            <MDBBadge className="ms-2 badge-janny">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/thomastrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Janny</p>
                {image.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Verified":
        return (
          <span className="name-verified">
            <span style={{ color: "rgb(28, 202, 255)" }}>{image.user}</span>
            <MDBBadge className="ms-2 badge-verified">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/verifiedlogotrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Verified</p>
                {image.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      default:
        console.log("oob badge", image.badge);
        return <></>;
    }
  };

  /**
   *
   * @param {Event} e - Click event object
   * @param {String} route - Endpoint
   *
   * Prevent default behavior when routing, use redux route method instead
   */
  browseRoute = (e, route) => {
    e.preventDefault();
    this.props.route(route);
  };

  getFileThumbnail = (file) => {
    switch (file.type) {
      case "audio":
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route cursor-pointer skip-ripple">
            <i className="fas fa-volume-up fa-3x d-block no-route skip-ripple text-default"></i>
          </div>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route skip-ripple cursor-pointer">
            <h6 className="file-labels-generic no-route skip-ripple text-default">
              {String(
                file.filename
                  .split(".")
                  [file.filename.split(".").length - 1].split("?")[0]
              ).toUpperCase()}
            </h6>
          </div>
        );
    }
  };

  render() {
    return (
      <motion.div
        className="browse-container-random"
        transition={t.transition}
        exit={this.props.contentExit}
        animate={t.normalize}
        initial={this.props.contentEnter}
      >
        <MDBContainer className="px-0" fluid>
          <div className="row pt-4 browse-row mx-0">
            {(() => {
              const images = this.props.images.filter(
                (image) => image.pageNumber === this.page
              );
              if (images.length)
                return images.map((image, i) => {
                  return (
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
                      <Link
                        onClick={(e) =>
                          this.browseRoute(e, `/file/${image.image_id}`)
                        }
                        to={`/file/${image.image_id}`}
                      >
                        <MDBRipple
                          rippleTag="div"
                          rippleColor="primary"
                          className="mx-auto d-flex flex-column justify-content-between h-100 cursor-pointer hover-generic shadow-3-strong py-2"
                        >
                          <h5 className="text-default text-center text-gb my-2 text-browse">
                            #{image.image_id}
                          </h5>
                          {image.thumbnail ? (
                            <img
                              className="d-block mx-auto image-thumbnails-series"
                              src={`${process.env.REACT_APP_IMAGE_HOST}/thumbnails/${image.thumbnail}`}
                              alt={`Thumbnail image ${image.image_id}`}
                              style={{ maxWidth: "95%" }}
                            />
                          ) : (
                            <div
                              style={{ maxWidth: "95%" }}
                              className="d-block mx-auto image-thumbnails-series"
                            >
                              {this.getFileThumbnail(image)}
                            </div>
                          )}
                          <div>
                            {image.manifesto ? (
                              <p
                                style={{ fontSize: "1.2rem" }}
                                className="my-3 text-center text-default display-6 text-break text-browse"
                              >
                                {h.abbreviatedText(image.manifesto)}
                              </p>
                            ) : (
                              <></>
                            )}
                            <div className="d-flex justify-content-center mt-2">
                              <h5 className="text-center text-browse">
                                {image.badge ? (
                                  this.getBadge(image)
                                ) : (
                                  <>
                                    <span className="text-secondary">
                                      {image.user}
                                    </span>{" "}
                                    (ID:{" "}
                                    <span
                                      className="text-light"
                                      style={{
                                        backgroundColor: `var(${image.poster_css_color})`,
                                      }}
                                    >
                                      {image.poster_id}
                                    </span>
                                    )
                                  </>
                                )}
                              </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                              <h5
                                className={`text-center text-browse ${
                                  image.comments
                                    ? "text-success"
                                    : "text-default"
                                }`}
                              >
                                {image.comments} Comments
                              </h5>
                            </div>
                          </div>
                        </MDBRipple>
                      </Link>
                    </div>
                  );
                });
              else return <LogoLoader className="my-5" />;
            })()}
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(BrowsePage);
