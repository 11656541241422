import React from "react";
import "./styles.css";
import { changePassword } from "./textFields";
import { change_password_schema } from "../../../../utilities/validations";
import axios from "axios";
import { connect } from "react-redux";
import { route, purge_user } from "../../../../redux/actions";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import Spinner from "../../../../components/Spinner";

/**
 * This is the modal that appears when the user clicks the "Change Password" button on their dashboard settings
 */

class ChangePasswordModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * savingChanges: Boolean indicating whether the password is in the process of being changed
       * notification: Bootstrap toast object that will eventually pop up and say "Password successfully changed"
       */
      savingChanges: false,
      notification: "",
      inputs: changePassword.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: "",
      })),
    };
  }

  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  submit = () => {
    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Hide modal
     * Trigger toast notification
     * Reset inputs
     */
    document
      .getElementById("form_change_password")
      .classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            change_password_schema.validateSync(data, {
              abortEarly: false,
            });
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            axios
              .post("/dashboard/change-password", fd)
              .then((res) => {
                document
                  .getElementById("form_change_password")
                  .classList.remove("was-validated");
                [].slice
                  .call(
                    document.getElementsByClassName("change-password-inputs")
                  )
                  .forEach((i) => (i.value = ""));
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    if (res.data.error) alert(res.data.error);
                    else {
                      this.props.toggleShowModal();
                      this.props.toastRef.click();
                      [].slice
                        .call(
                          document.getElementsByClassName(
                            "inputs-change-password"
                          )
                        )
                        .forEach((e) => (e.value = ""));
                      this.setState(
                        {
                          ...this.state,
                          inputs: changePassword.map((field) => ({
                            id: field.id,
                            error: "",
                            invalid: false,
                            value: "",
                          })),
                        },
                        () => {
                          this.changeHandler({
                            target: {
                              name: "",
                            },
                          });
                        }
                      );
                    }
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    if (err.response.status === 401) {
                      this.props.toggleShowModal();
                      this.props.purge_user();
                      this.props.route("/login");
                      alert("Your session has expired. Please log in again.");
                    } else alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          change_password_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  pressEnter = (e) => {
    // Submit the form if the user presses the enter key
    if (e.key === "Enter") this.submit();
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            staticBackdrop={this.state.savingChanges}
            show={this.props.modalShown}
            setShow={this.props.setShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Change Password</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBContainer className="px-0" fluid>
                    <MDBValidation
                      className="row mx-0"
                      id="form_change_password"
                      name="form_change_password"
                      method="dialog"
                    >
                      {changePassword.map((field) => (
                        <MDBValidationItem
                          key={field.id}
                          className="col-12 mt-4"
                          feedback={
                            this.state.inputs.find(
                              (input) => input.id === field.id
                            ).error
                          }
                          invalid={true}
                        >
                          <MDBInput
                            name={field.id}
                            onChange={this.changeHandler}
                            id={field.id}
                            label={field.text}
                            size="lg"
                            className={`change-password-inputs ${
                              !this.state.inputs.find(
                                (input) => input.id === field.id
                              ).invalid
                                ? "mb-0"
                                : 0
                            }`}
                            type="password"
                            onKeyPress={this.pressEnter}
                          />
                        </MDBValidationItem>
                      ))}
                    </MDBValidation>
                  </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                  {this.state.working ? (
                    <MDBBtn size="lg" disabled color="primary">
                      <Spinner size="sm" className="me-2" />
                      Saving
                    </MDBBtn>
                  ) : (
                    <MDBBtn size="lg" onClick={this.submit} color="primary">
                      <i className="fas fa-save me-2"></i>Save
                    </MDBBtn>
                  )}
                  <MDBBtn
                    size="lg"
                    style={{ backgroundColor: "var(--mdb-gray)" }}
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, purge_user })(
  ChangePasswordModal
);
