import React from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";

/**
 * This is a generic page that the user comes to for various messages
 * * After submitting a contact form
 * * After submitting a password reset request
 * * 404 Page
 */

const Message = ({ message }) => (
  <motion.div
    className="container"
    transition={t.transition}
    exit={t.fade_out_scale_1}
    animate={t.normalize}
    initial={t.fade_out}
  >
    <h1 className="display-5 mt-5 text-center">{message}</h1>
  </motion.div>
);

export default Message;
