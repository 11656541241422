import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { MDBContainer } from "mdb-react-ui-kit";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import {
  route,
  set_report_page,
  set_max_report_pages,
  set_reports,
  set_report_sort,
} from "../../../redux/actions";
import ReportNav from "./reports/ReportNav";
import ReportPage from "./reports/ReportPage";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LogoLoader from "../../../components/LogoLoader";

const itemsPerPage = 40;

class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * loaded: Boolean - Whether the initial image list has been loaded
       * leftButtonExit: Object - framer-motion exit animation for the left nav button
       * rightButtonExit: Object - framer-motion exit animation for the right nav button
       * contentExit: Object - framer-motion exit animation for the mod logs
       * contentEnter: Object - framer-motion entrance animation for the mod logs
       */
      loaded: false,
      leftButtonExit: t.fade_out_minimize,
      rightButtonExit: t.fade_out_minimize,
      contentExit: t.fade_out,
      contentEnter: t.fade_out,
    };
  }

  componentDidMount() {
    this.load();
  }

  /**
   * Request reports from server
   * Set reports and page numbers
   * Set state.loaded true
   * If reports were already loaded from a previous visit to the dashboard, set state.loaded true, but reload anyway in background
   */
  load = () => {
    if (!this.props.reports_dash.length)
      axios
        .get("/dashboard/reports")
        .then((res) => {
          this.props.set_max_report_pages(
            Math.ceil(res.data.reports.length / itemsPerPage)
          );
          this.props.set_reports(res.data.reports);
          this.setState({
            ...this.state,
            loaded: true,
          });
        })
        .catch((err) => {
          console.log(err);
          setTimeout(this.load, 1000);
        });
    else
      this.setState(
        {
          ...this.state,
          loaded: true,
        },
        () =>
          axios
            .get("/dashboard/reports")
            .then((res) => {
              this.props.set_max_report_pages(
                Math.ceil(res.data.reports.length / itemsPerPage)
              );
              this.props.set_reports(res.data.reports);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(this.load, 1000);
            })
      );
  };

  back = () => {
    this.setState(
      {
        /**
         * Sets the correct exit transition for the button and body
         * If stage is greater than 1, go down one stage, otherwise route to the home page
         */
        ...this.state,
        rightButtonExit:
          this.props.reportPage === this.props.totalReportPages
            ? t.fade_out_minimize
            : t.normalize,
        leftButtonExit:
          this.props.reportPage > 2 ? t.bob_left : t.fade_out_minimize,
        contentExit: t.fade_out_right,
        contentEnter: t.fade_out_left,
        softLoading: true,
      },
      () => this.props.set_report_page(this.props.reportPage - 1)
    );
  };

  next = () => {
    this.setState(
      {
        /**
         * Sets the correct exit transition for the button and body
         * If stage is greater than 1, go down one stage, otherwise route to the home page
         */
        ...this.state,
        rightButtonExit:
          this.props.reportPage === this.props.totalReportPages - 1
            ? t.fade_out_minimize
            : t.bob_right,
        leftButtonExit:
          this.props.reportPage === 1 ? t.fade_out_minimize : t.normalize,
        contentExit: t.fade_out_left,
        contentEnter: t.fade_out_right,
        softLoading: true,
      },
      () => this.props.set_report_page(this.props.reportPage + 1)
    );
  };

  /**
   * Navigates to the page that the user selects in the page select popover
   */
  go = () => {
    let pageNumber = document.getElementById("report-page-number").value;
    if (h.isNumeric(pageNumber)) {
      pageNumber = Number(pageNumber);
    } else pageNumber = 1;
    if (this.props.reportPage !== pageNumber)
      this.setState(
        {
          ...this.state,
          rightButtonExit:
            this.props.reportPage > pageNumber
              ? t.normalize
              : pageNumber === this.props.totalReportPages
              ? t.fade_out_minimize
              : t.bob_right,
          leftButtonExit:
            this.props.reportPage > pageNumber
              ? pageNumber === 1
                ? t.fade_out_minimize
                : t.bob_left
              : t.normalize,
          contentExit:
            this.props.reportPage > pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          contentEnter:
            this.props.reportPage < pageNumber
              ? t.fade_out_right
              : t.fade_out_left,
          softLoading: true,
        },
        () => this.props.set_report_page(pageNumber)
      );
  };

  render() {
    return (
      <div className="h-100 d-flex flex-column pt-2">
        {this.state.loaded ? (
          <MDBContainer fluid className="h-100 d-flex flex-column px-1">
            {this.props.reports_dash.length ? (
              <>
                {this.props.totalReportPages > 1 ? (
                  <StaticRouter location={`/${this.props.reportPage}`}>
                    <AnimatePresence exitBeforeEnter>
                      <Switch key={this.props.reportPage}>
                        <Route exact path="/:page">
                          <ReportNav
                            page={this.props.reportPage}
                            key={this.props.reportPage}
                            leftButtonExit={this.state.leftButtonExit}
                            rightButtonExit={this.state.rightButtonExit}
                            back={this.back}
                            next={this.next}
                            go={this.go}
                            loaded={this.state.loaded}
                          />
                        </Route>
                      </Switch>
                    </AnimatePresence>
                  </StaticRouter>
                ) : (
                  <></>
                )}
                <div className="fg-1 actions-container-dashboard">
                  <div
                    style={{ overflowX: "hidden" }}
                    className="h-100 overflow-y-auto px-3 mobile-px-0"
                  >
                    <table className="table table-dashboard">
                      <thead>
                        <tr>
                          <th
                            className="table-sticky-head cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            File/Profile
                          </th>
                          <th
                            onClick={() => this.props.set_report_sort("type")}
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            Type
                            {this.props.reportSortBy === "type" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.reportSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            onClick={() => this.props.set_report_sort("reason")}
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            Reason
                            {this.props.reportSortBy === "reason" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.reportSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            onClick={() => this.props.set_report_sort("id")}
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            ID
                            {this.props.reportSortBy === "id" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.reportSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.props.set_report_sort("timestamp")
                            }
                            className="table-sticky-head text-center cursor-pointer table-text-sm mobile-px-1"
                            scope="col"
                          >
                            Timestamp
                            {this.props.reportSortBy === "timestamp" ? (
                              <i
                                className={`ms-2 fas fa-caret-${
                                  this.props.reportSortDirection === "ascending"
                                    ? "up"
                                    : "down"
                                }`}
                              ></i>
                            ) : (
                              <></>
                            )}
                          </th>
                          <th
                            className="table-sticky-head text-center table-text-sm mobile-px-1 table-button-container"
                            scope="col"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <StaticRouter location={`/${this.props.reportPage}`}>
                        <AnimatePresence exitBeforeEnter>
                          <Switch key={this.props.reportPage}>
                            <Route exact path="/:page">
                              <ReportPage
                                key={this.props.reportPage}
                                contentExit={this.state.contentExit}
                                contentEnter={this.state.contentEnter}
                                imageNav={this.props.imageNav}
                              />
                            </Route>
                          </Switch>
                        </AnimatePresence>
                      </StaticRouter>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <h1 className="mt-5 text-center display-6">
                There are no unread reports
              </h1>
            )}
          </MDBContainer>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <LogoLoader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_report_page,
  set_max_report_pages,
  set_reports,
  set_report_sort,
})(Reports);
