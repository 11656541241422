import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBInputGroup,
  MDBInput,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBChipsInput,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import axios from "axios";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import { motion } from "framer-motion";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function 卐() {
  const captcha = useGoogleReCaptcha();
  const [results, setResults] = useState([]);
  const [resultID, setResultID] = useState("");
  const [resultPage, setResultPage] = useState(0);
  const [resultLength, setResultLength] = useState(0);
  const [endReached, setEndReached] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(false);
  const [userFilter, setUserFilter] = useState(["jax", "jsing"]);

  const search = async () => {
    try {
      if (!searching) {
        if (!captcha.executeRecaptcha)
          return alert(
            "Waiting for connection to captcha server. Please wait and try again. If problem persists, refresh the page or check your internet connection"
          );
        setSearching(true);
        const captchaKey = await h.getRecaptcha(captcha);
        const searchTerm = searchText;
        setSearchTerm(searchTerm);
        axios
          .post("/jackiesinghisaracistliar", {
            term: searchTerm,
            userFilter: userFilter,
            captchaKey: captchaKey,
          })
          .then((res) => {
            setResults(res.data.results);
            setSearched(true);
            setResultID(res.data.resultID);
            setEndReached(res.data.endReached);
            setResultPage(0);
            setResultLength(res.data.resultLength);
          })
          .catch((err) => {
            console.log(err);
            if (err.response?.status === 403)
              alert(
                "Your captcha was rejected. Refresh the page and try again."
              );
            else alert("An error occurred. Please try again later.");
          })
          .finally(() => setSearching(false));
      }
    } catch (err) {
      setSearching(false);
      console.log(err);
      alert("An error occurred. Please try again later.");
    }
  };

  const loadMore = () => {
    if (!endReached && !loadingMore) {
      setLoadingMore(true);
      axios
        .get("/jackiesinghisaracistliar/" + resultID + "/" + resultPage)
        .then((res) => {
          setEndReached(res.data.endReached);
          setResults([...results, ...res.data.results]);
          setResultPage(resultPage + 1);
        })
        .catch((err) => {
          console.log(err);
          alert("An error occurred. Please try again later.");
        })
        .finally(() => setLoadingMore(false));
    }
  };

  const handleScroll = (e) => {
    if (
      !endReached &&
      !loadingMore &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        0.1 * e.target.clientHeight
    )
      loadMore();
  };

  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
      onScroll={handleScroll}
      className="flex-grow-1 h-0 overflow-y-auto"
    >
      <h5 className="text-center my-4 display-6">GNAA/2600 IRC Search</h5>
      <MDBBtn
        className="d-block mx-auto mb-2"
        color="link"
        rippleColor="primary"
        onClick={() =>
          (window.location = "https://f.feednana.com/random/2600.log")
        }
      >
        Full Logs (350MB)
        <i className="fas fa-external-link-alt ms-2" />
      </MDBBtn>
      <MDBInputGroup
        textAfter={
          <MDBBtn
            onClick={search}
            disabled={searching}
            color="success"
            className={`${
              (searchText && searchText !== searchTerm) ||
              searching ||
              userFilter.length
                ? ""
                : "invis"
            } h-100`}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              boxShadow: "none",
            }}
          >
            {searching ? (
              <Spinner size="sm" />
            ) : (
              <i className="fas fa-paper-plane"></i>
            )}
          </MDBBtn>
        }
        textBefore={
          <i
            id="search-icon"
            style={{ transition: "0.2s" }}
            className="fas fa-search"
          ></i>
        }
        className="w-75 mx-auto"
        id="search-group"
        size="lg"
      >
        <MDBInput
          label="Message Text"
          size="lg"
          className="w-100"
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (
              e.key === "Enter" &&
              !searching &&
              searchText !== searchTerm &&
              searchText
            )
              search();
          }}
        />
      </MDBInputGroup>
      <div className="mx-auto w-max-content mt-4">
        <MDBChipsInput
          label="Filter by Author"
          onAdd={(e) => {
            setUserFilter([...userFilter, e]);
            setSearchTerm(false);
          }}
          onDelete={(e) => {
            setSearchTerm(false);
            setUserFilter(userFilter.filter((u) => u !== e));
          }}
          initialValues={userFilter.map((user) => ({ tag: user }))}
        />
      </div>
      {searched && (
        <motion.div
          transition={t.transition}
          initial={t.fade_out}
          animate={t.normalize}
          exit={t.fade_out_scale_1}
        >
          {results?.length ? (
            <MDBContainer>
              <h6>
                <span className="fw-bold">{resultLength}</span> results
              </h6>
              <MDBListGroup light>
                {results.map((result) => (
                  <MDBListGroupItem key={result._id}>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        User:
                        <span className="fw-bold"> {result.user}</span>
                      </p>
                      <p className="m-0">{result.time}</p>
                    </div>
                    {result.message}
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
              {!endReached && (
                <MDBBtn
                  size="lg"
                  className="d-block mx-auto"
                  onClick={loadMore}
                  disabled={loadingMore}
                  color="link"
                >
                  {loadingMore ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Loading More
                    </>
                  ) : (
                    <>Load More</>
                  )}
                </MDBBtn>
              )}
            </MDBContainer>
          ) : (
            <h5 className="text-center mt-4 display-6">No results found</h5>
          )}
        </motion.div>
      )}
    </motion.div>
  );
}
