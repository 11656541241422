import React from "react";
import { motion } from "framer-motion";
import { withRouter } from "react-router-dom";
import axios from "axios";
import t from "../utilities/transitions";
import { MDBBtn } from "mdb-react-ui-kit";
import { route } from "../redux/actions";
import { connect } from "react-redux";
import LogoLoader from "../components/LogoLoader";

/**
 * This is the page the user hits when they cancel a password reset request
 */

class Cancel extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * cancelled: Boolean indicating whether the password reset request has been canceled
       */
      cancelled: false,
    };
  }

  componentDidMount() {
    /**
     * Request to cancel the request
     * Set state.cancelled = true
     */
    axios
      .post("/auth/cancel", {
        uuid: this.props.match.params.id,
      })
      .then(() =>
        this.setState({
          ...this.state,
          cancelled: true,
        })
      )
      .catch((err) => {
        console.log(err);
        alert("An error occurred. Please try again later");
      });
  }

  render() {
    return (
      <motion.div
        className="container"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        {this.state.cancelled ? (
          <>
            <h1 className="display-6 mt-5 text-center">
              Password change request has been canceled.
            </h1>
            <div className="d-flex justify-content-center"></div>
            <MDBBtn
              onClick={() => this.props.route("/")}
              color="primary"
              size="lg"
              className="mt-4 mx-auto d-block"
            >
              <i className="fas fa-home me-2"></i>Return
            </MDBBtn>
          </>
        ) : (
          <>
            <h1 className="text-center mt-5 display-6">Cancelling</h1>
            <div className="mt-4 d-flex justify-content-center">
              <LogoLoader />
            </div>
          </>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(connect(mapStateToProps, { route })(Cancel));
