import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import Spinner from "../components/Spinner";
import { withRouter } from "react-router-dom";
import t from "../utilities/transitions";
import { route, set_series } from "../redux/actions";
import { MDBContainer, MDBTooltip, MDBInputGroup } from "mdb-react-ui-kit";
import axios from "axios";

class Series extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * loaded: Boolean - Whether the initial data has loaded from the server
       * bbCopied: Array of Images._id - Images that have had their bbCode copied to the clipboard
       * directLinkCopied: Array of Images._id - Images that have had their direct link copied to the clipboard
       */
      loaded: props.series._id && props.series._id === props.match.params.id,
      bbCopied: [],
      directLinkCopied: [],
    };
  }

  /**
   * Load the series
   * Set into state
   */
  componentDidMount() {
    if (!this.state.loaded)
      axios
        .get(`/api/series/${this.props.match.params.id}`)
        .then((res) => {
          this.props.set_series(res.data.series);
          this.setState({
            ...this.state,
            loaded: true,
          });
        })
        .catch((err) => {
          console.log(err, err.response, err.response.status);
          if (err.response.status === 404) this.props.route("/not-found");
          else {
            console.log(err);
            alert("An error occurred. Please refresh the page and try again.");
          }
        });
  }

  /**
   *
   * @param {String} id ref Images._id
   *
   * Copies the direct link to clipboard, tallies that image
   */
  copyDirectLink = (id) => {
    const string = document.getElementById(`direct-link-${id}`).value;
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed";
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const copyHotkey = isMac ? "⌘C" : "CTRL+C";
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }

    this.setState({
      ...this.state,
      directLinkCopied: [...this.state.directLinkCopied, id],
    });
  };

  /**
   *
   * @param {String} id ref Images._id
   *
   * Copies the bb code to clipboard, tallies that image
   */
  copyBBCode = (id) => {
    navigator.clipboard.writeText(
      document.getElementById(`bb-code-${id}`).value
    );
    this.setState({
      ...this.state,
      bbCopied: [...this.state.bbCopied, id],
    });
  };

  getFileThumbnail = (file) => {
    switch (file.type) {
      case "audio":
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route cursor-pointer skip-ripple">
            <i className="fas fa-volume-up fa-3x d-block no-route skip-ripple text-default"></i>
          </div>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route skip-ripple cursor-pointer">
            <h6 className="file-labels-generic no-route skip-ripple text-default">
              {String(
                file.filename
                  .split(".")
                  [file.filename.split(".").length - 1].split("?")[0]
              ).toUpperCase()}
            </h6>
          </div>
        );
    }
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        {this.state.loaded ? (
          <MDBContainer fluid>
            <div className="row justify-content-center">
              {this.props.series._id ? (
                <>
                  {this.props.series.images.map((image) => (
                    <div className="col-12 col-md-4 col-lg-3 mt-5">
                      <h5 className="text-center text-default text-gb m-0">
                        #{image.image_id}
                      </h5>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                          onClick={() =>
                            this.props.route(`/file/${image.image_id}`)
                          }
                          className="border border-dark cursor-pointer w-75"
                        >
                          <div style={{ marginTop: "100%" }}></div>
                          {image.thumbnail ? (
                            <div
                              className="fit-images position-absolute top-0 bottom-0 start-0 end-0"
                              style={{
                                backgroundImage: `url("${process.env.REACT_APP_IMAGE_HOST}/thumbnails/${image.thumbnail}")`,
                              }}
                            ></div>
                          ) : (
                            <div className="fit-images position-absolute top-0 bottom-0 start-0 end-0">
                              {this.getFileThumbnail(image)}
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="text-center mb-1">Direct Link</p>
                      <MDBTooltip
                        wrapperClass="d-block mx-auto text-unset text-overflow-ellipsis cursor-pointer"
                        wrapperProps={{
                          onClick: () => this.copyDirectLink(image.image_id),
                        }}
                        tag="div"
                        title={
                          this.state.directLinkCopied.indexOf(
                            image.image_id
                          ) !== -1
                            ? "Copied"
                            : "Click to Copy"
                        }
                      >
                        <MDBInputGroup
                          textAfter={<i className="fas fa-copy" />}
                        >
                          <input
                            id={`direct-link-${image.image_id}`}
                            value={`${process.env.REACT_APP_IMAGE_HOST}/files/${image.filename}`}
                            readOnly
                            className="form-control text-overflow-ellipsis cursor-pointer"
                            type="text"
                            placeholder=""
                          />
                        </MDBInputGroup>
                      </MDBTooltip>

                      {image.type === "image" ? (
                        <>
                          <p className="text-center mb-1 mt-2">BBcode</p>

                          <MDBTooltip
                            wrapperClass="d-block mx-auto text-unset text-overflow-ellipsis cursor-pointer"
                            wrapperProps={{
                              onClick: () => this.copyBBCode(image.image_id),
                            }}
                            tag="div"
                            title={
                              this.state.bbCopied.indexOf(image.image_id) !== -1
                                ? "Copied"
                                : "Click to Copy"
                            }
                          >
                            <MDBInputGroup
                              textAfter={<i className="fas fa-copy" />}
                            >
                              <input
                                id={`bb-code-${image.image_id}`}
                                value={`[img]${process.env.REACT_APP_IMAGE_HOST}/files/${image.filename}[/img]`}
                                readOnly
                                className="form-control text-overflow-ellipsis cursor-pointer"
                                type="text"
                                placeholder=""
                              />
                            </MDBInputGroup>
                          </MDBTooltip>
                        </>
                      ) : (
                        <></>
                      )}
                      <hr></hr>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </MDBContainer>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <Spinner color="indigo" size="xl" />
          </div>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(
  connect(mapStateToProps, { route, set_series })(Series)
);
