import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../../../utilities/transitions";
import {
  MDBRipple,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBTooltip,
  MDBCardText,
  MDBCardTitle,
  MDBCardHeader,
  MDBBadge,
} from "mdb-react-ui-kit";
import axios from "axios";
import h from "../../../utilities/helpers";
import { Link } from "react-router-dom";
import { route } from "../../../redux/actions";
import LogoLoader from "../../../components/LogoLoader";

const itemsPerPage = 40;

class ContentPage extends React.Component {
  constructor(props) {
    super();
    /**
     * this.page: Number - The page of the comments that the user is on
     */
    this.page = props.profileContentPage;
    this.state = {
      /**
       * commentHover: Number - comment_id of quoted comment that the user is hovering over with their mouse
       * tooltipComment: JSX - Content of comment with comment_id state.commentHover
       */
      tooltipComment: <></>,
      commentHover: 0,
    };
  }

  renderCommentAvatar = (comment) => {
    if (comment.thumbnail)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <div
            className="fit-images"
            style={{
              backgroundImage: `url("/api/image-id/${comment.avatar}")`,
            }}
          ></div>
        </div>
      );

    if (comment.avatar_type)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <h6 className="file-labels-generic">{comment.avatar_type}</h6>
        </div>
      );

    if (comment.avatar)
      return (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center square-8 mx-2"
          onClick={() => window.open("/file/" + comment.avatar)}
        >
          <div
            className="fit-images"
            style={{
              backgroundImage: `url("/api/image-id/${comment.avatar}")`,
            }}
          ></div>
        </div>
      );

    return <></>;
  };

  /**
   *
   * @param {Number} postNumber - ref Comments.comment_id
   *
   * Renders a comment that was quoted with the double hashes (##)
   * If the comment is in the comment section, pull the comment data from state and render
   * Else, render a spinner while the comment data is fetched from the server
   */
  renderTooltipComment = (postNumber) => {
    let comment = this.props.profileComments.find(
      (comment) => comment.comment_id === postNumber
    );
    if (comment)
      this.setState({
        ...this.state,
        commentHover: postNumber,
        tooltipComment: (
          <>
            {comment.removed && !h.checkJanny(this.props.userInfo) ? (
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard
                  className={`mx-auto ${comment.removed ? "card-removed" : ""}`}
                >
                  <MDBCardBody className="d-flex">
                    <MDBCardText
                      className={`messages text-break ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      Comment Removed
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            ) : (
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard
                  className={`mx-auto ${comment.removed ? "card-removed" : ""}`}
                >
                  <MDBCardHeader className="d-flex align-items-end">
                    <MDBCardTitle
                      style={{ maxWidth: "80%" }}
                      className={`mb-0 ${comment.removed ? "text-light" : ""}`}
                    >
                      {comment.badge ? (
                        this.getBadge(comment)
                      ) : (
                        <>
                          <span
                            className={
                              comment.removed ? "text-light" : "text-secondary"
                            }
                          >
                            {comment.name}
                          </span>{" "}
                          <span className="text-nowrap me-2">
                            (ID:{" "}
                            <span
                              className="text-light"
                              style={{
                                backgroundColor: `var(${comment.poster_css_color})`,
                              }}
                            >
                              {comment.poster_id}
                            </span>
                            )
                          </span>
                        </>
                      )}
                      <span className="mb-0 text-nowrap">
                        {h.makeDateHR(new Date(comment.timestamp))} at{" "}
                        {h.getTimeHR(new Date(comment.timestamp))}
                      </span>
                    </MDBCardTitle>
                    <MDBCardTitle
                      className={`ms-2 mb-0 text-gb ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      #{comment.comment_id}
                    </MDBCardTitle>
                  </MDBCardHeader>
                  <MDBCardBody className="d-flex">
                    {this.renderCommentAvatar(comment)}
                    <MDBCardText
                      className={`messages text-break ${
                        comment.removed ? "text-light" : ""
                      }`}
                    >
                      {this.parseText_displayOnly(comment.comment_text)}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            )}
          </>
        ),
      });
    else
      this.setState(
        {
          ...this.state,
          commentHover: postNumber,
          tooltipComment: (
            <>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
              >
                <MDBCard className="mx-auto">
                  <MDBCardBody className="d-flex justify-content-center align-items-center">
                    <LogoLoader />
                  </MDBCardBody>
                </MDBCard>
              </motion.div>
            </>
          ),
        },
        () =>
          axios
            .get(`/api/comment/${postNumber}`)
            .then((res) => {
              comment = res.data.comment;
              if (this.state.commentHover === postNumber)
                this.setState({
                  ...this.state,
                  tooltipComment: (
                    <>
                      {comment.removed && !h.checkJanny(this.props.userInfo) ? (
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard
                            className={`mx-auto ${
                              comment.removed ? "card-removed" : ""
                            }`}
                          >
                            <MDBCardBody className="d-flex">
                              <h5 className="text-center text-light p-0">
                                Comment removed
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      ) : (
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard
                            className={`mx-auto ${
                              comment.removed ? "card-removed" : ""
                            }`}
                          >
                            <MDBCardHeader className="d-flex align-items-end">
                              <MDBCardTitle
                                style={{ maxWidth: "80%" }}
                                className={`mb-0 ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                {comment.badge ? (
                                  this.getBadge(comment)
                                ) : (
                                  <>
                                    <span
                                      className={
                                        comment.removed
                                          ? "text-light"
                                          : "text-secondary"
                                      }
                                    >
                                      {comment.name}
                                    </span>{" "}
                                    <span className="text-nowrap me-2">
                                      (ID:{" "}
                                      <span
                                        className="text-light"
                                        style={{
                                          backgroundColor: `var(${comment.poster_css_color})`,
                                        }}
                                      >
                                        {comment.poster_id}
                                      </span>
                                      )
                                    </span>
                                  </>
                                )}
                                <span className="mb-0 text-nowrap">
                                  {h.makeDateHR(new Date(comment.timestamp))} at{" "}
                                  {h.getTimeHR(new Date(comment.timestamp))}
                                </span>
                              </MDBCardTitle>
                              <MDBCardTitle
                                className={`ms-2 mb-0 text-gb ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                #{comment.comment_id}
                              </MDBCardTitle>
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex">
                              {this.renderCommentAvatar(comment)}
                              <MDBCardText
                                className={`messages text-break ${
                                  comment.removed ? "text-light" : ""
                                }`}
                              >
                                {this.parseText_displayOnly(
                                  comment.comment_text
                                )}
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      )}
                    </>
                  ),
                });
            })
            .catch((err) => {
              console.log(err);
              if (this.state.commentHover === postNumber) {
                if (err.response.status === 404)
                  this.setState({
                    ...this.state,
                    tooltipComment: (
                      <>
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard className="mx-auto">
                            <MDBCardBody className="d-flex justify-content-center align-items-center">
                              <h5 className="text-center">
                                Comment does not exist
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      </>
                    ),
                  });
                else
                  this.setState({
                    ...this.state,
                    tooltipComment: (
                      <>
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <MDBCard className="mx-auto">
                            <MDBCardBody className="d-flex justify-content-center align-items-center">
                              <h5 className="text-center">
                                An error occurred while fetching the comment
                              </h5>
                            </MDBCardBody>
                          </MDBCard>
                        </motion.div>
                      </>
                    ),
                  });
              }
            })
      );
  };

  /**
   *
   * @param {Object} comment - Comments document
   * @returns The user's name, along with a badge to indicate their role
   */
  getBadge = (comment) => {
    switch (comment.badge) {
      case "Chadmin":
        return (
          <span className="name-chadmin me-2">
            <span style={{ color: "var(--mdb-yellow)" }}>{comment.name}</span>
            <MDBBadge className="ms-2 badge-chadmin">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/meltrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Chadmin</p>
                {comment.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Janny":
        return (
          <span className="name-janny me-2">
            <span style={{ color: "var(--mdb-green)" }}>{comment.name}</span>
            <MDBBadge className="ms-2 badge-janny">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/thomastrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Janny</p>
                {comment.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      case "Verified":
        return (
          <span className="name-verified me-2">
            <span style={{ color: "rgb(28, 202, 255)" }}>{comment.name}</span>
            <MDBBadge className="ms-2 badge-verified">
              <div className="d-flex align-items-center">
                <div
                  style={{ height: "1.6em", width: "1.6em" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("/assets/images/verifiedlogotrans.png")`,
                    }}
                  ></div>
                </div>
                <p className="m-0">Verified</p>
                {comment.oldfag ? (
                  <div className="position-relative">
                    <MDBBadge
                      className="badge-oldfag"
                      color="danger"
                      notification
                      pill
                    >
                      Oldfag
                    </MDBBadge>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </MDBBadge>
          </span>
        );
      default:
        console.log("oob badge", comment.badge);
    }
  };

  /**
   *
   * @param {Event} e - Javascript click event or similar
   * @param {Number} commentID - ref Comments.comment_id
   *
   * Fired when the user clicks on a quoted comment
   * If the comment is not in the comment section, route to /comment/commentID
   * If the comment is in the comment section and in the document, scroll to it
   * If the comment is in the comment section and not in the document, switch to the appropriate page and scroll to it
   */

  clickQuotedComment = (e, commentID) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.hash = `#comment-${commentID}`;
    commentID = Number(commentID);
    document
      .getElementById(`tooltip-comment-${commentID}`)
      .classList.add("d-none");
    const comment = this.props.profileComments.find(
      (c) => c.comment_id === commentID
    );
    if (comment) this.props.goToComment(comment);
    else this.props.route(`/comment/${commentID}`);
  };

  /**
   *
   * @param {String} text - The text of a comment
   *
   * Parses the string for double hashes (##) which are used to quote other comments
   * Replaces them with links to other comments and tooltips with previews
   *
   * @returns JSX array with parsed comment
   */
  parseText = (text) => {
    let returnArray = [];
    text.split("##").forEach((segment) => {
      if (segment) {
        let postNumber = "";
        let stop = false;
        segment.split("").forEach((character) => {
          if (!stop && h.isNumeric(character)) postNumber += character;
          else stop = true;
        });
        if (postNumber) {
          returnArray.push(
            <MDBTooltip
              tag="span"
              wrapperProps={{
                className:
                  "text-primary links-generic-light text-decoration-none cursor-pointer fw-bold",
              }}
              title={this.state.tooltipComment}
              onShow={() => this.renderTooltipComment(Number(postNumber))}
              className="comment-tooltips ms-2"
              id={`tooltip-comment-${postNumber}`}
            >
              <Link
                to={`/comment/${postNumber}`}
                onClick={(e) => this.clickQuotedComment(e, postNumber)}
              >
                ##
              </Link>
              <span style={{ cursor: "text" }}>{postNumber}</span>
            </MDBTooltip>
          );
          returnArray.push(
            <span>
              {segment
                .split(postNumber)
                .filter((piece, i) => i !== 0)
                .join(postNumber)}
            </span>
          );
        } else returnArray.push(<span>{segment}</span>);
      }
    });
    return returnArray;
  };

  /**
   *
   * @param {String} text - The text of a comment
   *
   * Performs the same task as parseComment, but without the links or tooltips. Visual effects only.
   *
   * @returns JSX array with parsed comment
   */
  parseText_displayOnly = (text) => {
    let returnArray = [];
    text.split("##").forEach((segment) => {
      if (segment) {
        let postNumber = "";
        let stop = false;
        segment.split("").forEach((character) => {
          if (!stop && h.isNumeric(character)) postNumber += character;
          else stop = true;
        });
        if (postNumber) {
          returnArray.push(
            <span className="text-primary links-generic-light text-decoration-none cursor-pointer fw-bold">
              {"##" + postNumber}
            </span>
          );
          returnArray.push(
            <span>
              {segment
                .split(postNumber)
                .filter((piece, i) => i !== 0)
                .join(postNumber)}
            </span>
          );
        } else returnArray.push(<span>{segment}</span>);
      }
    });
    return returnArray;
  };

  /**
   *
   * @param {Event Object} e - Javascript click event or similar
   * @param {String} destination - Endpoint
   */
  route = (e, destination) => {
    e.preventDefault();
    this.props.route(destination);
  };

  /**
   *
   * @param {Array} content - Array of Images and Comments that the user has posted
   * @returns JSX array of links to and previews of content that the user has posted
   */
  renderContent = (content) => {
    if (content.comment_id) {
      let destination = content.user_profile
        ? `/u/${content.user_profile}#comment-${content.comment_id}`
        : `/file/${content.image_id}#comment-${content.comment_id}`;
      return (
        <Link
          onClick={(e) => this.route(e, destination)}
          className="text-default"
          to={`/file/${content.image_id}#comment-${content.comment_id}`}
        >
          <MDBCardHeader className="d-flex align-items-end">
            <h6 className="mb-0">
              Comment #
              <span className="text-gb image-numbers">
                {content.comment_id}
              </span>
            </h6>
            <p className="mb-0 ms-2">
              {h.makeDateHR(new Date(content.timestamp))} -{" "}
              {h.getTimeHR(new Date(content.timestamp))}
            </p>
          </MDBCardHeader>
          <MDBCardBody className="d-flex">
            {this.renderCommentAvatar(content)}
            <p className="mb-0 text-break messages">
              {this.parseText(h.longString(content.comment_text))}
            </p>
          </MDBCardBody>
        </Link>
      );
    } else {
      return (
        <Link
          onClick={(e) => this.route(e, `/file/${content.image_id}`)}
          className="text-default"
          to={`/file/${content.image_id}`}
        >
          <MDBCardBody>
            <MDBContainer fluid className="px-0">
              <div className="row align-items-center">
                <div className="col-lg-4 col-6">
                  <h6>
                    File #
                    <span className="text-gb image-numbers">
                      {content.image_id}
                    </span>
                  </h6>
                  <p className="mb-1">
                    Posted on {h.makeDateHR(new Date(content.timestamp))} at{" "}
                    {h.getTimeHR(new Date(content.timestamp))}
                  </p>
                  <p className="mb-1">
                    {h.numberWithCommas(content.views)} Views
                  </p>
                  <p className="mb-0">
                    {h.numberWithCommas(content.cs.comments)} Comment
                    {content.cs.comments === 1 ? "" : "s"}
                  </p>
                </div>
                <div className="col-lg-4 col-6">
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex justify-content-center align-items-center square-8 mx-auto"
                  >
                    <div
                      className="fit-images"
                      style={{
                        backgroundImage: `url("/api/image-id/${content.image_id}")`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="mb-0 text-break messages">
                    {this.parseText(h.longString(content.cs.manifesto))}
                  </p>
                </div>
              </div>
            </MDBContainer>
          </MDBCardBody>
        </Link>
      );
    }
  };

  render() {
    return (
      <motion.div
        className="pb-4"
        style={{ overflowX: "hidden" }}
        transition={t.transition}
        exit={this.props.contentExit}
        animate={t.normalize}
        initial={this.props.contentEnter}
      >
        {this.props.profileContent
          .sort((a, b) => a.timestamp - b.timestamp)
          .filter((content, i) => {
            if (
              i >= this.page * itemsPerPage - 1 - itemsPerPage &&
              i < this.page * itemsPerPage
            )
              return true;
            else return false;
          })
          .map((content, c) => (
            <MDBCard
              key={content._id}
              className={`mx-2 ${
                c ? "" : "mt-2"
              } cursor-pointer hover-generic mb-4 shadow-3-strong`}
            >
              <MDBRipple rippleColor="primary" key={content._id}>
                {this.renderContent(content)}
              </MDBRipple>
            </MDBCard>
          ))}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(ContentPage);
