import React from "react";

export default function LogoLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem",
      }}
    >
      <img
        style={{
          width: "64px",
          height: "64px",
          display: "block",
          margin: "0 auto",
        }}
        src={`/icons/loading-spinner.png`}
        class="logo-flash"
      />
    </div>
  );
}
