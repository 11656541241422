import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import axios from "axios";
import "./login/login.css";
import { login_schema } from "../utilities/validations";
import { set_user, route } from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import Spinner from "../components/Spinner";

/**
 * This is the login page
 */

class Login extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * working: Boolean indicating whether the user is in the process of logging in
       * exit: framer-motion exit transition
       * inputs: Array - The input data (values, errors, etc)
       */
      working: false,
      exit: this.getExit(props),
      inputs: [
        {
          id: "username",
          error: "",
          invalid: true,
          value: "",
        },
        {
          id: "password",
          error: "",
          invalid: true,
          value: "",
        },
      ],
    };
  }

  componentDidMount() {
    /**
     * Upon mount, set exit transition to default value
     */
    this.setState(
      {
        ...this.state,
        exit: t.fade_out,
      },
      () =>
        this.changeHandler({
          target: {
            name: "",
          },
        })
    );
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  getExit = (props) => {
    /**
     * If user is coming from the Forgot Password page, fade in from the left
     * Otherwise, fade in
     */
    const h = props.historyStack;
    if (h[h.length - 1] === "/forgot-password") return t.fade_out_left;
    else return t.fade_out;
  };

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.submit();
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Set user
   * Navigate to dashboard
   */
  submit = () => {
    document.getElementById("login").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            login_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await h.getRecaptcha(
              this.props.googleReCaptchaProps
            );
            data.captchaKey = captchaKey;
            axios
              .post("/auth/login", data)
              .then((res) => {
                this.props.set_user(res.data);
                this.props.route("/dashboard");
                this.setState((curr) => ({
                  ...curr,
                  working: false,
                }));
              })
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err.response);
                    switch (err.response.status) {
                      case 401:
                        alert("Invalid username or password");
                        break;
                      case 403:
                        alert(err.response.data.message);
                        break;
                      default:
                        alert("An error occurred. Please try again later");
                    }
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
    return false;
  };

  forgotPassword = () =>
    this.setState(
      {
        /**
         * Fired when the user clicks the Forgot Password button
         * Set the component to fade out to the left, then navigate to the Forgot Password page
         */
        ...this.state,
        exit: t.fade_out_left,
      },
      () => this.props.route("/forgot-password")
    );

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          login_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error);
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.submit();
  };

  render() {
    return (
      <motion.div
        className="min-h-100 container py-4"
        transition={t.transition}
        exit={this.state.exit}
        animate={t.normalize}
        initial={this.state.exit}
      >
        <h1 className="display-4 text-center">Login</h1>
        <hr></hr>
        <div className="form-containers">
          <MDBValidation name="login" id="login" method="dialog">
            <MDBValidationItem
              className="pb-4"
              feedback={
                this.state.inputs.find((input) => input.id === "username").error
              }
              invalid={true}
            >
              <MDBInput
                name="username"
                onChange={this.changeHandler}
                id="username"
                label="Username"
                size="lg"
                className={
                  !this.state.inputs.find((input) => input.id === "username")
                    .invalid
                    ? "mb-0"
                    : 0
                }
                onKeyPress={this.pressEnter}
              />
            </MDBValidationItem>
            <MDBValidationItem
              className="pb-4"
              feedback={
                this.state.inputs.find((input) => input.id === "password").error
              }
              invalid={true}
            >
              <MDBInput
                name="password"
                onChange={this.changeHandler}
                id="password"
                label="Password"
                size="lg"
                type="password"
                className={
                  !this.state.inputs.find((input) => input.id === "password")
                    .invalid
                    ? "mb-0"
                    : 0
                }
                onKeyPress={this.pressEnter}
              />
            </MDBValidationItem>
          </MDBValidation>
          {this.state.working ? (
            <MDBBtn color="success" size="lg" className="w-100" block disabled>
              <Spinner size="sm" className="me-2" />
              Working
            </MDBBtn>
          ) : (
            <MDBBtn
              color="success"
              onClick={this.submit}
              size="lg"
              block
              className="w-100"
            >
              <i className="fas fa-paper-plane me-2"></i>Submit
            </MDBBtn>
          )}
          <MDBBtn
            onClick={this.forgotPassword}
            style={{ backgroundColor: "#607D8B" }}
            size="lg"
            className="w-100 mt-4"
            block
          >
            Forgot Password<i className="fas fa-chevron-right ms-2"></i>
          </MDBBtn>
          <p
            onClick={() => this.props.route("/create-account")}
            className="mt-4 mb-0 text-center text-primary cursor-pointer"
          >
            <i className="fas fa-user-plus me-2"></i>Create Account
          </p>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_user, route })(
  withGoogleReCaptcha(Login)
);
