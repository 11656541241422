import React from "react";
import { connect } from "react-redux";
import {
  MDBCollapse,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBBtn,
} from "mdb-react-ui-kit";
import { report_schema } from "../../../utilities/validations";
import axios from "axios";
import { update_image } from "../../../redux/actions";
import Spinner from "../../../components/Spinner";
import h from "../../../utilities/helpers";

const fields = [
  {
    text: "Explain",
    id: "details",
    type: "text",
  },
];

class RemoveCommentSectionForm extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * inputs: Array - The input data (values, errors, etc)
       * working: Boolean - Whether the comment section is in the process of being removed
       */
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      working: false,
    };
  }

  /**
   * Run a blank input change
   */
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in the Other text input
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          report_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document
                .getElementById(input.id + `-remove-comment-section`)
                .setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id + `-remove-comment-section`)
                    .setCustomValidity(input.error);
                else
                  document
                    .getElementById(input.id + `-remove-comment-section`)
                    .setCustomValidity("");
              })
          );
        }
      }
    );

  submit = () => {
    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Reset inputs
     * Emit mod-action event via socket
     * Update comment section in application state
     */
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document
        .getElementById(input.id + `-remove-comment-section`)
        .setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
          removeReason: "other",
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            report_schema.validateSync(data, {
              abortEarly: false,
            });
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append("id", this.props.imageInfo.commentSection);
            fd.append("imageID", this.props.imageInfo.image_id);
            fd.append("reason", "other");
            axios
              .post(
                h.checkJanny(this.props.userInfo)
                  ? "/support/remove/comment-section"
                  : "/support/remove/comment-section-own",
                fd
              )
              .then((res) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                    removeReason: "",
                  },
                  () => {
                    this.props.socket.emit("mod-action");
                    this.props.update_image(res.data.image);
                    this.props.toggleRemoveCommentSectionForm();
                  }
                )
              )
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  render() {
    return (
      <MDBCollapse
        className="pt-3"
        style={{ width: "400px", maxWidth: "90%" }}
        show={this.props.show || this.state.working}
      >
        <MDBValidation id={`remove-comment-section`} onSubmit={this.submit}>
          {fields.map((i) => (
            <MDBValidationItem
              key={i.id}
              feedback={
                this.state.inputs.find((input) => input.id === i.id).error
              }
              invalid={true}
            >
              <MDBTextArea
                name={i.id}
                onChange={this.changeHandler}
                id={i.id + `-remove-comment-section`}
                label={i.text}
                size="lg"
                className={
                  !this.state.inputs.find((input) => input.id === i.id).invalid
                    ? "mb-0"
                    : 0
                }
                onKeyPress={this.pressEnter}
              />
            </MDBValidationItem>
          ))}
        </MDBValidation>
        <div className="d-flex mt-2">
          <MDBBtn
            size="sm"
            onClick={() =>
              document.getElementById(`remove-comment-section`).requestSubmit()
            }
            disabled={this.state.working}
            className="d-block"
            color="dark"
          >
            {this.state.removeReason === "other" ? (
              <>
                <Spinner size="sm" className="me-2"></Spinner>
                Disabling
              </>
            ) : (
              <>Submit</>
            )}
          </MDBBtn>
          {this.state.working ? (
            <></>
          ) : (
            <MDBBtn
              disabled={this.state.working}
              size="sm"
              onClick={this.props.toggleRemoveCommentSectionForm}
              className="d-block ms-2"
              color="light"
            >
              Cancel
            </MDBBtn>
          )}
        </div>
      </MDBCollapse>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { update_image })(
  RemoveCommentSectionForm
);
