import React from "react";
import Settings from "./Settings";
import Messages from "./Messages";
import Users from "./Users";
import Images from "./Images";
import Reports from "./Reports";
import ModLogs from "./ModLogs";

export default [
  {
    category: "Chadmin",
    options: [
      {
        text: "User Info",
        icon: (
          <i
            style={{ color: "var(--mdb-primary)" }}
            className="fas fa-user me-2"
          ></i>
        ),
        id: "settings",
        component: Settings,
      },
      {
        text: "Files",
        icon: (
          <i
            style={{ color: "var(--mdb-success)" }}
            className="fas fa-photo-video me-2"
          ></i>
        ),
        id: "images",
        component: Images,
      },
      {
        text: "Messages",
        icon: (
          <i
            style={{ color: "var(--mdb-pink)" }}
            className="fas fa-envelope me-2"
          ></i>
        ),
        id: "messages",
        component: Messages,
      },
      {
        text: "Users",
        icon: (
          <i
            style={{ color: "var(--mdb-cyan)" }}
            className="fas fa-users me-2"
          ></i>
        ),
        id: "users",
        component: Users,
      },
      {
        text: "Reports",
        icon: (
          <i
            style={{ color: "var(--mdb-warning)" }}
            className="fas fa-flag me-2"
          ></i>
        ),
        id: "reports",
        component: Reports,
      },
      {
        text: "Mod Logs",
        icon: (
          <i
            style={{ color: "var(--mdb-danger)" }}
            className="fas fa-gavel me-2"
          ></i>
        ),
        id: "modlogs",
        component: ModLogs,
      },
    ],
  },
  {
    category: "Janny",
    options: [
      {
        text: "User Info",
        icon: (
          <i
            style={{ color: "var(--mdb-primary)" }}
            className="fas fa-user me-2"
          ></i>
        ),
        id: "settings",
        component: Settings,
      },
      {
        text: "Files",
        icon: (
          <i
            style={{ color: "var(--mdb-success)" }}
            className="fas fa-photo-video me-2"
          ></i>
        ),
        id: "images",
        component: Images,
      },
      {
        text: "Reports",
        icon: (
          <i
            style={{ color: "var(--mdb-warning)" }}
            className="fas fa-flag me-2"
          ></i>
        ),
        id: "reports",
        component: Reports,
      },
    ],
  },
  {
    category: "Verified",
    options: [
      {
        text: "User Info",
        icon: (
          <i
            style={{ color: "var(--mdb-primary)" }}
            className="fas fa-user me-2"
          ></i>
        ),
        id: "settings",
        component: Settings,
      },
      {
        text: "Files",
        icon: (
          <i
            style={{ color: "var(--mdb-success)" }}
            className="fas fa-photo-video me-2"
          ></i>
        ),
        id: "images",
        component: Images,
      },
    ],
  },
];
