import React from "react";
import md5 from "md5";
import axios from "axios";
import pngVideo from "./pngVideo";
import heic2any from "heic2any";

const h = {};

// Hides all tooltips on the page
h.hideToolTips = () => {
  Array.from(document.getElementsByClassName("tooltip")).forEach((e) => {
    // e.classList.remove("show")
    e.style.display = "none";
  });
};

/**
 *
 * @param {Number} size - Size of the file to be measured
 * @returns Size of file in Bytes/KB/MB/etc
 */
h.getFileSize = (size) => {
  size = Number(size);
  const units = ["Bytes", "KB", "MB", "GB"];
  let scale = 0;
  while (size > 900 && scale < 3) {
    size /= 1024;
    scale++;
  }
  return Math.round(size * 100) / 100 + " " + units[scale];
};

/**
 *
 * @param {Number} value - Any number
 *
 * Shortens numbers by compiling them
 * i.e. 1000 bytes -> 1KB
 * 10000 bytes -> 10KB
 *
 *
 * @returns The compiled number
 */
h.compiledNumber = (value) => {
  let compiledNumber = value;
  if (!h.isNumeric(String(compiledNumber))) return compiledNumber;
  compiledNumber = String(compiledNumber);
  if (compiledNumber >= 1000000000 || compiledNumber <= -1000000000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "B"
    );
  else if (compiledNumber >= 1000000 || compiledNumber <= -1000000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "M"
    );
  else if (compiledNumber >= 1000 || compiledNumber <= -1000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "K"
    );
  return String(value);
};

/**
 *
 * @param {File} file - Video file
 *
 * Creates <video>
 * Sets src of video to file
 * Once duration is determined, get VideoSnapshot
 *
 * @returns Thumbnail of video taken halfway through the video
 */
h.getVideoThumbnail = (file) =>
  new Promise((resolve) => {
    if (["video/mp4", "video/webm", "video/quicktime"].includes(file.type)) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        try {
          const blob = new Blob([fileReader.result], { type: file.type });
          const url = URL.createObjectURL(blob);
          const video = document.getElementById("video-thumbnail-temp");
          video.addEventListener("loadeddata", function () {
            try {
              video.currentTime = this.duration / 2;
            } catch (err) {
              console.log("loadeddata error", err);
              resolve(h.dataURLtoFile(pngVideo, "file.png"));
            }
          });
          video.addEventListener("seeked", function () {
            snapImage();
          });
          const snapImage = function () {
            try {
              setTimeout(() => {
                const canvas = document.getElementById("canvas-thumbnail-temp");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas
                  .getContext("2d")
                  .drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.getContext("2d").canvas.toBlob(
                  (blob) => {
                    resolve(new File([blob], "file.png"));
                  },
                  "image/png",
                  1
                );
              }, 200);
            } catch (err) {
              console.log("snapImage error", err);
              resolve(h.dataURLtoFile(pngVideo, "file.png"));
            }
          };
          video.preload = "metadata";
          video.src = url;
          video.muted = true;
          video.playsInline = true;

          video.play();
        } catch (err) {
          console.log("onload error", err);
          resolve(h.dataURLtoFile(pngVideo, "file.png"));
        }
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      resolve(h.dataURLtoFile(pngVideo, "file.png"));
    }
  });

/**
 *
 * @param {String} dataURL - DataURL
 * @param {String} filename - File name
 *
 * Converts dataURL to Uint8Array, then feeds Uint8Array, filename, and mimetype into File constructor
 *
 * @returns JavaScript file object
 */
h.dataURLtoFile = (dataURL, filename) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

h.getMultipartRequests = (files) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      files: [],
    };

    files.forEach((file) => {
      let folder;
      const type = (file.type || h.getFileType(file.file))
        .split("/")[0]
        .toLowerCase();
      switch (type) {
        case "audio":
          folder = "audio";
          break;
        case "video":
          folder = "videos";
          break;
        case "image":
          folder = "images";
          break;
        default:
          folder = "other";
      }
      requestBody.files.push({
        name: file.file.name,
        type: file.type,
        folder,
        size: Number(file.file.size),
        md5: file.md5,
      });
      if (folder === "videos")
        requestBody.files.push({
          name: file.thumbnail.name,
          type: file.thumbnail.type
            ? file.thumbnail.type.split("/")[0].toLowerCase()
            : h.getFileType(file.thumbnail).split("/")[0].toLowerCase(),
          folder: "thumbnails",
          size: Number(file.thumbnail.size),
          md5: file.md5,
        });
    });

    axios
      .post("/images/initialize-multipart-upload", requestBody)
      .then((res) => {
        resolve(res.data.requests);
      })
      .catch(reject);
  });

h.processThumbnail = (file) =>
  new Promise((resolve, reject) =>
    axios
      .post("/images/thumbnail", { file })
      .then((res) => {
        resolve(res.data.thumbnail);
      })
      .catch(reject)
  );

h.convertToJpeg = (file) =>
  new Promise(async (resolve, reject) => {
    try {
      const extension = file.name
        .split(".")
        [file.name.split(".").length - 1].toLowerCase();
      switch (extension) {
        case "heic":
        case "heif":
          file = await h.heicToJpeg(file);
          break;
        default:
          console.log("oob convertToJpeg", file, extension);
      }
      resolve(file);
    } catch (err) {
      console.log("convertToJpeg error", err);
      reject(err);
    }
  });

h.heicToJpeg = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = function () {
        try {
          const arrayBuffer = this.result;
          const blob = new Blob([arrayBuffer], { type: file.type });
          heic2any({ blob, toType: "image/jpeg" })
            .then((resultBlob) => {
              resolve(
                new File([resultBlob], "file.jpg", { type: "image/jpeg" })
              );
            })
            .catch((err) => {
              console.log("error", err);
              reject(err);
            });
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (err) {
      reject(err);
    }
  });

h.cleanBuffer = (arrayBuffer) => {
  let dataView = new DataView(arrayBuffer);
  const exifMarker = 0xffe1; // EXIF marker for TIFF and JPEG
  let offset = 2; // Skip the first two bytes (0xFFD8)

  while (offset < dataView.byteLength) {
    if (dataView.getUint16(offset) === exifMarker) {
      // Found an EXIF marker
      const segmentLength = dataView.getUint16(offset + 2, false) + 2;

      // Update the arrayBuffer and dataView
      arrayBuffer = h.removeSegment(arrayBuffer, offset, segmentLength);
      dataView = new DataView(arrayBuffer);
    } else {
      // Move to the next marker
      offset += 2 + dataView.getUint16(offset + 2, false);
    }
  }

  return arrayBuffer;
};

h.removeSegment = (buffer, offset, length) => {
  // Create a new buffer without the specified segment
  const modifiedBuffer = new Uint8Array(buffer.byteLength - length);
  modifiedBuffer.set(new Uint8Array(buffer.slice(0, offset)), 0);
  modifiedBuffer.set(new Uint8Array(buffer.slice(offset + length)), offset);

  return modifiedBuffer.buffer;
};

h.removeExifData = (file) => {
  return new Promise((resolve) => {
    if (file && file.type.startsWith("image/")) {
      const fr = new FileReader();
      fr.onload = function () {
        const cleanedBuffer = h.cleanBuffer(this.result);
        const blob = new Blob([cleanedBuffer], { type: file.type });
        const newFile = new File([blob], file.name, { type: file.type });
        resolve(newFile);
      };
      fr.readAsArrayBuffer(file);
    } else resolve(file);
  });
};

h.stripExif = (file) =>
  new Promise(async (resolve) => {
    try {
      const removeExit = await h.removeExifData(file);
      resolve(removeExit);
    } catch (err) {
      console.log("stripExif error", err);
      resolve(file);
    }
    return;
    const newFile = file;
    try {
      const fr = new FileReader();
      fr.onload = async function () {
        try {
          const dv = new DataView(this.result);
          let offset = 0,
            recess = 0;
          let pieces = [];
          let i = 0;
          if (dv.getUint16(offset) == 0xffd8) {
            offset += 2;
            let app1 = dv.getUint16(offset);
            offset += 2;
            while (offset < dv.byteLength) {
              if (app1 == 0xffe1) {
                pieces[i] = { recess: recess, offset: offset - 2 };
                recess = offset + dv.getUint16(offset);
                i++;
              } else if (app1 == 0xffda) {
                break;
              }
              offset += dv.getUint16(offset);
              app1 = dv.getUint16(offset);
              offset += 2;
            }
            if (pieces.length > 0) {
              const newPieces = [];
              pieces.forEach(function (v) {
                newPieces.push(this.result.slice(v.recess, v.offset));
              }, this);
              newPieces.push(this.result.slice(recess));
              const br = new Blob(newPieces, { type: "image/jpeg" });
              resolve(new File([br], file.name, { type: "image/jpeg" }));
            }
          } else resolve(file);
        } catch (err) {
          resolve(file);
        }
      };
      fr.readAsArrayBuffer(file);
    } catch (err) {
      console.log("stripExif error", err);
      resolve(newFile);
    }
  });

h.getFileType = (file) => {
  switch (
    file.name
      .split(".")
      [file.name.split(".").length - 1].split("?")[0]
      .toLowerCase()
  ) {
    case "txt":
    case "log":
    case "text":
      return "text/plain";
    case "html":
      return "text/html";
    case "css":
      return "text/css";
    case "js":
      return "text/javascript";
    case "xml":
      return "text/xml";
    case "csv":
      return "text/csv";
    case "md":
      return "text/markdown";
    case "bin":
      return "application/octet-stream";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "tiff":
      return "image/tiff";
    case "ico":
      return "image/x-icon";
    case "svg":
      return "image/svg+xml";
    case "midi":
      return "audio/midi";
    case "m4a":
      return "audio/m4a";
    case "aac":
      return "audio/aac";
    case "mp3":
      return "audio/mpeg";
    case "wav":
    case "wave":
      return "audio/wav";
    case "ogg":
      return "audio/ogg";
    case "avi":
      return "video/avi";
    case "mkv":
      return "video/x-matroska";
    case "mp4":
      return "video/mp4";
    case "mov":
    case "qt":
      return "video/quicktime";
    case "sfw":
      return "application/x-shockwave-flash";
    case "webm":
      return "video/webm";
    case "xl":
    case "xls":
    case "xlsx":
      return "application/excel";
    case "zip":
      return "application/zip";
    case "pdf":
      return "application/pdf";
    case "doc":
    case "docx":
      return "application/msword";
    case "tar":
      return "application/x-tar";
    case "gz":
      return "application/x-gzip";
    case "ttf":
      return "font/ttf";
    case "ppt":
    case "pttx":
      return "application/powerpoint";
    case "otf":
      return "font/otf";
    default:
      return "application/octet-stream";
  }
};

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
  let months = date.getMonth() + 1;
  let days = date.getDate();
  let years = date.getFullYear();
  return months + "/" + days + "/" + years;
};

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = (date) => {
  date = new Date(date);
  let meridian = "AM";
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours >= 12) meridian = "PM";
  if (!hours) hours = 12;
  if (hours > 12) {
    hours -= 12;
  }
  if (String(minutes).length === 1) minutes = `0${minutes}`;
  return hours + ":" + minutes + meridian;
};

/**
 * @param {String | Number} num - A number (i.e. 1000000)
 * @returns String - Number with commas appended (i.e. 1,000,000)
 */
h.numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

h.dolHR = (n) => {
  /**
   * Returns human readable dollar amount from mongo value which is stored in pennies
   */
  n = Number(n);
  n = (n / 100).toFixed(2);
  n = h.numberWithCommas(n);
  if (n === "-0.00") {
    n = "0.00";
  }
  return n;
};

/**
 *
 * @param {String} string
 * @returns The first up to 99 characters of that string
 */
h.shortString = (string) => {
  string = String(string);
  if (string.length > 100) return string.substring(0, 99) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns Boolean - Whether the string is a number.
 */
h.isNumeric = (string) => {
  if (typeof string != "string") return false;
  return !isNaN(string) && !isNaN(parseFloat(string));
};

/**
 * Fixes MDB bug in which labels on inputs with text input are not properly floated
 * Floats the labels
 */
h.floatLabels = () =>
  setTimeout(
    () =>
      [].slice
        .call(document.getElementsByClassName("form-control"))
        .forEach((e) => {
          if (e.value && !e.classList.contains("active"))
            e.classList.add("active");
        }),
    250
  );

/**
 *
 * @param {File} file
 * @returns md5 hash of the file
 */
h.getMD5 = (file) =>
  md5(
    JSON.stringify({
      lastModified: file.lastModified,
      name: file.name,
      type: file.type,
      size: file.size,
    })
  );

/**
 * Executes a captcha challenge and generates a key a key
 * Will hang until connected to captcha servers
 */
h.getRecaptcha = (reCaptchaProps) =>
  new Promise((resolve, reject) => {
    if (reCaptchaProps.executeRecaptcha)
      reCaptchaProps
        .executeRecaptcha()
        .then((res) => resolve(res))
        .catch((err) => {
          console.log("captcha error", err);
          alert("Recaptcha error. Refresh the page and try again.");
          return reject();
        });
    else {
      alert(
        "There was an error connecting to the Recaptcha servers. Refresh the page and try again."
      );
      return reject();
    }
  });

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Janny privileges
 */
h.checkJanny = (userInfo) => {
  if (
    userInfo &&
    userInfo.role &&
    ["Janny", "Chadmin"].indexOf(userInfo.role) !== -1
  )
    return true;
  else return false;
};

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Chadmin privileges
 */
h.checkChadmin = (userInfo) => {
  if (userInfo && userInfo.role && userInfo.role === "Chadmin") return true;
  else return false;
};

/**
 *
 * @param {String} code - Removal code
 * @returns Removal label
 */
h.getRemovedReason = (code) => {
  switch (code) {
    case "fed":
      return "Terrorism/Fedposting";
    case "porn":
      return "Porn";
    case "spam":
      return "Spam";
    default:
      console.log(code);
      return "Other";
  }
};

/**
 *
 * @param {String} string
 * @returns The first up to 100 characters of that string
 */
h.abbreviatedText = (text) => {
  text = String(text);
  if (text.length > 100) return text.substring(0, 100) + "...";
  else return text;
};

/**
 *
 * @param {String} string
 * @returns The first up to 1000 characters of that string
 */
h.longString = (text) => {
  text = String(text);
  if (text.length > 1000) return text.substring(0, 1000) + "...";
  else return text;
};

export default h;
