import React from "react";
import {
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
  MDBRipple,
  MDBCheckbox,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import { comment_schema } from "../../../utilities/validations";
import axios from "axios";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import { connect } from "react-redux";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { add_comment } from "../../../redux/actions";
import AddCommentToast from "./AddCommentToast";
import EmojiPicker from "../../../components/EmojiPicker";
import Spinner from "../../../components/Spinner";
import Count from "../../../components/Count";
import { Collapse } from "@mui/material";
import { Uploader } from "../../../utilities/upload";

const fields = [
  {
    text: "Name (Optional)",
    id: "name",
    type: "text",
  },
  {
    text: "Message",
    id: "body",
    type: "textarea",
  },
];

const MAX_TOTAL_FILE_SIZE = 1572864000;
const MAX_FILE_COUNT = 15;

class AddCommentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * inputs: Array - The input data (values, errors, etc)
       * working: Boolean - Whether the comment is in the process of being submitted
       * commentFormOpen: Boolean - Whether the comment form is open
       * toastShown: Boolean - Whether the Add Comment MDB Toast is displayed
       * reset: Boolean - Used to force fix certain ui bugs
       * imageOptions: Object - The image options that the user can toggle when uploading an image with the comment
       */
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: "",
      })),
      working: false,
      file: false,
      commentFormOpen: false,
      toastShown: false,
      reset: false,
      imageOptions: {
        commentsDisabled: false,
        nsfw: false,
        hidden: false,
      },
    };
    this.clickCommentID = this.clickCommentID.bind(this);
  }

  /**
   * Bind the clickCommentID function
   * Run empty changeHandler
   */
  componentDidMount() {
    this.props.setClickCommentID(this.clickCommentID);
    this.changeHandler({
      target: {
        value: "",
        name: "",
      },
    });
  }

  /**
   *
   * @param {Number} id Comment number that was just clicked
   *
   * This will add the comment into the comment body, to be quoted, and show the Add Comment toast
   */
  clickCommentID = (id) => {
    this.setState({
      ...this.state,
      inputs: this.state.inputs.map((input) => {
        if (input.id === "body")
          return {
            ...input,
            value: input.value + `##${id}\n`,
          };
        else return input;
      }),
      toastShown: true,
    });
  };

  /**
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = () => {
    h.hideToolTips();
    if (!this.state.working && !this.state.selectingFiles) {
      let input = document.createElement("input");
      input.type = "file";
      input.style.visibility = "hidden";
      input.style.position = "fixed";
      document.body.appendChild(input);
      input.onchange = (e) => this.processFiles(e, input);
      input.click();
    }
  };

  processFiles = (e, input) =>
    this.setState(
      (curr) => ({
        ...curr,
        selectingFiles: true,
      }),
      async () => {
        try {
          const file = e.target.files[0];
          if (file.size > MAX_TOTAL_FILE_SIZE) throw "Max size exceeded";
          if (!file.type) {
            file = new File([file], file.name, {
              type: h.getFileType(file),
            });
          }
          const md5 = await h.getMD5(file);
          const thumbnail = file.type.includes("video")
            ? await h.getVideoThumbnail(file)
            : false;
          this.setState(
            (curr) => ({
              ...curr,
              file: {
                name: file.name,
                file: file,
                path: URL.createObjectURL(file),
                md5: md5,
                size: file.size,
                type: file.type,
                thumbnail,
              },
              processingFiles: false,
              selectingFiles: false,
            }),
            () => {
              if (input) document.body.removeChild(input);
              this.setState(
                (curr) => ({
                  ...curr,
                  commentFormOpen: false,
                }),
                () =>
                  this.setState((curr) => ({
                    ...curr,
                    commentFormOpen: true,
                  }))
              );
            }
          );
        } catch (err) {
          console.log("select files error", err);
          this.setState(
            (curr) => ({
              ...curr,
              selectingFiles: false,
            }),
            () => alert(err)
          );
        }
      }
    );

  /**
   *
   * @param {String} md5 - md5 hash of the file selected
   *
   * Triggered when the user clicks an audio or video file
   * Stop the file that is currently playing, if any
   * Play the file
   */
  selectMedia = (md5, e) => {
    e?.stopPropagation();
    if (this.state.mediaPlaying)
      document.getElementById(this.state.mediaPlaying)?.pause();
    this.setState(
      (curr) => ({
        ...curr,
        mediaPlaying: this.state.mediaPlaying === md5 ? "" : md5,
      }),
      () => {
        if (this.state.mediaPlaying)
          document.getElementById(this.state.mediaPlaying)?.play();
      }
    );
  };

  /**
   *
   * @param {String} md5 - md5 of the image hovered
   *
   */
  setImageHovered = (md5) =>
    this.setState({
      ...this.state,
      imageHovered: md5,
    });

  progressHandler = (e) => {
    /**
     * Progress bar will move quicker as it gets closer to 100
     */
    this.setState((curr) => ({
      ...curr,
      processingFiles: {
        ...curr.processingFiles,
        progress: Number(e.percentage),
      },
    }));
  };

  /**
   *
   * @param {File} file - Javascript file object
   * @returns A file thumbnail/preview depending on the type of file that it is
   */
  getFileThumbnail = (file) => {
    switch (file.type.split("/")[0]) {
      case "image":
        return (
          <div
            className="fit-images position-relative"
            style={{ backgroundImage: `url("${file.path}")` }}
          >
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white mb-2">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>
                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      case "video":
        const videoPlayable = [
          "video/mp4",
          "video/webm",
          "video/quicktime",
        ].includes(file.type);
        return (
          <MDBRipple
            onClick={
              videoPlayable ? (e) => this.selectMedia(file.md5, e) : () => {}
            }
            className={`h-100 w-100 d-flex justify-content-center align-items-center position-relative ${
              this.state.processingFiles || !videoPlayable
                ? ""
                : "cursor-pointer"
            } position-relative`}
          >
            {videoPlayable ? (
              <video
                className="position-absolute max-w-100 max-h-100"
                src={file.path}
                id={file.md5}
              ></video>
            ) : (
              <>
                {file.thumbnail ? (
                  <img
                    className="position-absolute h-100 d-block mx-auto top-0 start-0 end-0 bottom-0"
                    src={URL.createObjectURL(file.thumbnail)}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>

                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {videoPlayable ? (
                  <>
                    {this.state.mediaPlaying === file.md5 ? (
                      <>
                        {this.state.imageHovered === file.md5 ? (
                          <motion.i
                            transition={t.transition}
                            exit={t.fade_out_scale_1}
                            animate={t.normalize}
                            initial={t.fade_out}
                            style={{
                              color: "rgba(255, 255, 255, 0.5)",
                              zIndex: 30,
                            }}
                            className="fas fa-pause fa-5x"
                          ></motion.i>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        style={{
                          color: "rgba(255, 255, 255, 0.5)",
                          zIndex: 30,
                        }}
                        className="fas fa-play fa-5x"
                      ></motion.i>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </MDBRipple>
        );
      case "audio":
        return (
          <>
            <audio src={file.path} id={file.md5}></audio>
            <MDBRipple
              onClick={(e) => this.selectMedia(file.md5, e)}
              className={`h-100 d-flex w-100 justify-content-center align-items-center ${
                this.state.processingFiles ? "" : "cursor-pointer"
              } position-relative`}
            >
              {this.state.processingFiles ? (
                <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                  {this.state.processingFiles.processed.includes(file.md5) ? (
                    <motion.i
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                      className="fas fa-check-circle text-success fa-2x"
                    ></motion.i>
                  ) : (
                    <>
                      {this.state.processingFiles?.current === file.md5 ? (
                        <motion.div
                          transition={t.transition}
                          initial={t.fade_out}
                          animate={t.normalize}
                          exit={t.fade_out_scale_1}
                          className="w-100 d-flex flex-column justify-content-center align-items-center"
                        >
                          <h5 className="text-center text-white">
                            {this.state.processingFiles.progress >= 100 ? (
                              <motion.span
                                transition={t.transition}
                                initial={t.fade_out}
                                animate={t.normalize}
                                exit={t.fade_out}
                              >
                                Processing
                              </motion.span>
                            ) : (
                              <>
                                <Count
                                  value={this.state.processingFiles.progress}
                                />
                                %
                              </>
                            )}
                          </h5>

                          <MDBProgress
                            className={`w-80 ${
                              this.state.processingFiles.progress >= 100
                                ? "opacity-flash scale-1"
                                : ""
                            }`}
                            style={{
                              height: "3px",
                              transitionDuration: `${
                                0.6 - 0.6 * this.state.processingFiles.progress
                              }`,
                            }}
                          >
                            <MDBProgressBar
                              width={this.state.processingFiles.progress}
                              valuemin={0}
                              valuemax={100}
                              bgColor={
                                this.state.processingFiles.progress >= 100
                                  ? "success"
                                  : "primary"
                              }
                            />
                          </MDBProgress>
                        </motion.div>
                      ) : (
                        <motion.i
                          transition={t.transition}
                          initial={t.fade_out}
                          animate={t.normalize}
                          exit={t.fade_out_scale_1}
                          className="fas fa-ellipsis-h text-light fa-2x"
                        ></motion.i>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {this.state.mediaPlaying === file.md5 ? (
                    <motion.i
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="fas fa-pause fa-5x"
                    ></motion.i>
                  ) : (
                    <motion.i
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                      className="fas fa-play fa-5x"
                    ></motion.i>
                  )}
                </>
              )}
            </MDBRipple>
          </>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center position-relative">
            {this.state.processingFiles ? (
              <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                {this.state.processingFiles.processed.includes(file.md5) ? (
                  <motion.i
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    className="fas fa-check-circle text-success fa-2x"
                  ></motion.i>
                ) : (
                  <>
                    {this.state.processingFiles?.current === file.md5 ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="w-100 d-flex flex-column justify-content-center align-items-center"
                      >
                        <h5 className="text-center text-white">
                          {this.state.processingFiles.progress >= 100 ? (
                            <motion.span
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out}
                            >
                              Processing
                            </motion.span>
                          ) : (
                            <>
                              <Count
                                value={this.state.processingFiles.progress}
                              />
                              %
                            </>
                          )}
                        </h5>

                        <MDBProgress
                          className={`w-80 ${
                            this.state.processingFiles.progress >= 100
                              ? "opacity-flash scale-1"
                              : ""
                          }`}
                          style={{
                            height: "3px",
                            transitionDuration: `${
                              0.6 - 0.6 * this.state.processingFiles.progress
                            }`,
                          }}
                        >
                          <MDBProgressBar
                            width={this.state.processingFiles.progress}
                            valuemin={0}
                            valuemax={100}
                            bgColor={
                              this.state.processingFiles.progress >= 100
                                ? "success"
                                : "primary"
                            }
                          />
                        </MDBProgress>
                      </motion.div>
                    ) : (
                      <motion.i
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="fas fa-ellipsis-h text-light fa-2x"
                      ></motion.i>
                    )}
                  </>
                )}
              </div>
            ) : (
              <h6 className="file-labels-generic">
                {
                  file.name
                    .split(".")
                    [file.name.split(".").length - 1].split("?")[0]
                }
              </h6>
            )}
          </div>
        );
    }
  };

  /**
   *
   *
   * Fired when the user clicks a red trash can on any of the selected files
   */
  removeFile = () => {
    this.setState({
      ...this.state,
      file: false,
    });
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        this.state.inputs.forEach((input) => {
          if (input.value) {
            document
              .getElementById(input.id + "-main")
              .dispatchEvent(new Event("input"));
            const toastInput = document.getElementById(input.id + "-toast");
            if (toastInput) toastInput.dispatchEvent(new Event("input"));
          }
        });
        try {
          comment_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id + "-main").setCustomValidity("");
              const toastInput = document.getElementById(input.id + "-toast");
              if (toastInput) toastInput.setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () => {
              this.state.inputs.forEach((input) => {
                if (input.invalid) {
                  document
                    .getElementById(input.id + "-main")
                    .setCustomValidity(input.error);
                  const toastInput = document.getElementById(
                    input.id + "-toast"
                  );
                  if (toastInput) toastInput.setCustomValidity(input.error);
                } else {
                  document
                    .getElementById(input.id + "-main")
                    .setCustomValidity("");
                  const toastInput = document.getElementById(
                    input.id + "-toast"
                  );
                  if (toastInput) toastInput.setCustomValidity("");
                }
              });
            }
          );
        }
      }
    );
  };

  processFile = (file, thumbnail) =>
    new Promise(async (resolve, reject) => {
      try {
        const handleError = (e) => {
          console.log("error", e);
          reject();
        };

        const handleComplete = (key) => {
          resolve(key.split("/")[key.split("/").length - 1]);
        };

        new Uploader({
          file: thumbnail ? file.thumbnail : file.file,
          handleProgress: thumbnail ? () => {} : this.progressHandler,
          handleError,
          handleComplete,
          thumbnail,
          initializeResponse: file.initializeResponse,
        }).start();
      } catch (err) {
        console.log("processFile Error", err);
        reject();
      }
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Emit new-comment event via socket
   * Reset inputs
   * Hide submission forms
   */
  submit = () => {
    document.getElementById("comment_form_main").classList.add("was-validated");
    const toastForm = document.getElementById("comment_form_toast");
    if (toastForm) toastForm.classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) => {
      document
        .getElementById(input.id + "-main")
        .setCustomValidity(input.error);
      const toastInput = document.getElementById(input.id + "-toast");
      if (toastInput) toastInput.setCustomValidity(input.error);
    });
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          const body = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            comment_schema.validateSync(body, {
              abortEarly: false,
            });
            const captchaKey = await h.getRecaptcha(
              this.props.googleReCaptchaProps
            );
            body.captchaKey = captchaKey;
            body.image_id = this.props.imageInfo.image_id;

            if (this.state.file) {
              const file = this.state.file;
              body.commentsDisabled = this.state.imageOptions.commentsDisabled;
              body.nsfw = this.state.imageOptions.nsfw;
              body.hidden = this.state.imageOptions.hidden;

              this.setState((curr) => ({
                ...curr,
                processingFiles: {
                  indicator: (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Processing Files
                    </>
                  ),
                  processed: [],
                  current: file.md5,
                  progress: 0,
                },
              }));
              const multipartRequests = await h.getMultipartRequests([file]);
              const processed = {};

              const type = file.type.split("/")[0].toLowerCase();
              processed.mimeType = file.type;
              switch (type) {
                case "audio":
                  processed.type = "audio";
                  break;
                case "video":
                  processed.type = "video";
                  break;
                case "image":
                  processed.type = "image";
                  break;
                default:
                  processed.type = "other";
              }
              try {
                const initializeResponse = multipartRequests.find(
                  (request) =>
                    request.md5 === file.md5 &&
                    !request.fileKey.includes("thumbnails")
                );
                processed.main = await this.processFile({
                  ...file,
                  initializeResponse,
                });

                if (type === "video") {
                  const initializeResponse = multipartRequests.find(
                    (request) =>
                      request.md5 === file.md5 &&
                      request.fileKey.includes("thumbnails")
                  );
                  processed.thumbnail = await this.processFile(
                    {
                      ...file,
                      initializeResponse,
                    },
                    true
                  );
                } else if (type === "image") {
                  processed.thumbnail = await h.processThumbnail(
                    `${processed.type}${
                      ["video", "image"].includes(processed.type) ? "s" : ""
                    }/${processed.main}`
                  );
                }
                body.image = processed;
                this.setState((curr) => ({
                  ...curr,
                  processingFiles: {
                    ...curr.processingFiles,
                    indicator: (
                      <>
                        <Spinner className="me-2" size="sm" />
                        Sending
                      </>
                    ),
                    current: "",
                    progress: 0,
                    processed: [file.md5],
                  },
                }));
              } catch (err) {
                console.log("file error", err);
              }
            }

            axios
              .post("/comments", body)
              .then((res) => {
                if (res.data.error)
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => alert(res.data.error)
                  );
                else
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                      inputs: fields.map((field) => ({
                        id: field.id,
                        error: "",
                        invalid: false,
                        value: "",
                      })),
                      file: false,
                      toastShown: false,
                      imageOptions: {
                        commentsDisabled: false,
                        nsfw: false,
                        hidden: false,
                      },
                    },
                    () => {
                      this.props.socket.emit("comment-send", res.data.comment);
                      document
                        .getElementById("comment_form_main")
                        .classList.remove("was-validated");
                      const toastForm =
                        document.getElementById("comment-form-toast");
                      if (toastForm)
                        toastForm.classList.remove("was-validated");
                      this.props.add_comment(res.data.comment);
                      this.changeHandler({
                        target: {
                          value: "",
                          name: "",
                        },
                      });
                      [].slice
                        .call(document.getElementsByClassName("comment-inputs"))
                        .forEach((e) => (e.value = ""));
                      setTimeout(() => {
                        this.closeCommentForm();
                      }, 250);
                      setTimeout(() => {
                        document
                          .getElementById(
                            `comment-${res.data.comment.comment_id}`
                          )
                          .scrollIntoView();
                      }, 500);
                    }
                  );
              })
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  toggleCommentForm = () =>
    this.setState(
      {
        ...this.state,
        commentFormOpen: !this.state.commentFormOpen,
      },
      () => setTimeout(this.fixMDBInputs, 250)
    );

  closeCommentForm = () =>
    this.setState({
      ...this.state,
      commentFormOpen: false,
    });

  hideToast = () =>
    this.setState({
      ...this.state,
      toastShown: false,
    });

  /**
   * Hacky fix for known mdb ui bug that will probably be fixed in later versions
   */
  fixMDBInputs = () => {
    [].slice
      .call(document.getElementsByClassName("comment-inputs"))
      .forEach((e) => {
        if (!e.value && e.classList.contains("active"))
          e.classList.remove("active");
        else if (e.value && !e.classList.contains("active"))
          e.classList.add("active");
      });
    this.setState({
      ...this.state,
      reset: !this.state.reset,
    });
  };

  /**
   * Add emoji to the comment body if selected
   */
  selectEmoji = (e) => {
    if (!this.state.working)
      this.changeHandler({
        target: {
          value: this.state.inputs.find((i) => i.id === "body").value + e.char,
          name: "body",
        },
      });
  };

  /**
   *
   * @param {CheckboxEvent} e
   *
   * Fired when the user toggles one of the options when uploading a file with their comment
   */
  changeImageOptions = (e) =>
    this.setState({
      ...this.state,
      imageOptions: {
        ...this.state.imageOptions,
        [e.target.name]: e.target.checked,
      },
    });

  render() {
    return (
      <>
        <AddCommentToast
          changeHandler={this.changeHandler}
          inputs={this.state.inputs}
          fields={fields}
          submit={this.submit}
          working={this.state.working}
          file={this.state.file}
          selectAvatar={this.selectFile}
          toastShown={this.state.toastShown}
          hideToast={this.hideToast}
          fixMDBInputs={this.fixMDBInputs}
          selectEmoji={this.selectEmoji}
          imageOptions={this.state.imageOptions}
          changeImageOptions={this.changeImageOptions}
        />
        <MDBBtn
          onClick={this.toggleCommentForm}
          color="primary"
          size="lg"
          className="mt-4 d-block mx-auto"
        >
          <i className="fas fa-comments me-2"></i>
          Add Comment
        </MDBBtn>
        <Collapse in={this.state.commentFormOpen}>
          <div className="form-containers mt-4">
            <MDBValidation
              id="comment_form_main"
              method="dialog"
              name="comment_form_main"
            >
              {fields.map((i) => (
                <MDBValidationItem
                  key={i.id + "-main"}
                  className="pb-4"
                  feedback={
                    this.state.inputs.find((input) => input.id === i.id).error
                  }
                  invalid={true}
                >
                  {(() => {
                    switch (i.type) {
                      case "text":
                        return (
                          <MDBInput
                            name={i.id}
                            onChange={this.changeHandler}
                            id={i.id + "-main"}
                            label={i.text}
                            size="lg"
                            type={i.type}
                            className={`comment-inputs ${
                              !this.state.inputs.find(
                                (input) => input.id === i.id
                              ).invalid
                                ? "mb-0"
                                : 0
                            }`}
                            value={
                              this.state.inputs.find(
                                (input) => input.id === i.id
                              ).value
                            }
                          />
                        );
                      case "textarea":
                        return (
                          <>
                            <MDBTextArea
                              name={i.id}
                              onChange={this.changeHandler}
                              id={i.id + "-main"}
                              label={i.text}
                              size="lg"
                              className={`comment-inputs ${
                                !this.state.inputs.find(
                                  (input) => input.id === i.id
                                ).invalid
                                  ? "mb-0"
                                  : 0
                              }`}
                              style={{ minHeight: "10rem" }}
                              value={
                                this.state.inputs.find(
                                  (input) => input.id === i.id
                                ).value
                              }
                            />
                            <div className="d-flex justify-content-between p-2">
                              <div className="d-flex align-items-center">
                                <EmojiPicker
                                  emojiID={0}
                                  onEmojiSelect={this.selectEmoji}
                                />
                                <MDBBtn
                                  color="link"
                                  rippleColor="primary"
                                  onClick={this.selectFile}
                                  className="ms-2"
                                >
                                  <i
                                    style={{ fontSize: "1.75em" }}
                                    className="fas fa-photo-video fa-lg"
                                  ></i>
                                </MDBBtn>
                              </div>
                              <p className="text-end m-0">
                                <span
                                  className={
                                    this.state.inputs.find(
                                      (i) => i.id === "body"
                                    ).value.length > 10000
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  {
                                    this.state.inputs.find(
                                      (i) => i.id === "body"
                                    ).value.length
                                  }
                                </span>
                                /10000
                              </p>
                            </div>
                          </>
                        );
                      default:
                        console.log("oob comment form", i.type);
                        return <></>;
                    }
                  })()}
                </MDBValidationItem>
              ))}
            </MDBValidation>
            {this.state.file ? (
              <div className="w-100 d-flex pb-2">
                <div className="w-50">
                  <MDBRipple
                    rippleTag="div"
                    style={{ cursor: "pointer" }}
                    onClick={this.selectFile}
                    className="border border-dark p-2 d-flex justify-content-center align-items-center square-8 mx-auto"
                  >
                    <div
                      onMouseEnter={() =>
                        this.setImageHovered(this.state.file?.md5)
                      }
                      onMouseLeave={() => this.setImageHovered("")}
                      className={`mx-auto p-2 d-flex justify-content-center align-items-center square-15 position-relative ${
                        !this.state.processingFiles &&
                        this.state.imageHovered === this.state.file?.md5
                          ? "image-hover"
                          : ""
                      } ${
                        this.state.processingFiles ? "file-labels-dark" : ""
                      }`}
                    >
                      {this.getFileThumbnail(this.state.file)}
                      <motion.div
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        className="file-labels-dark position-absolute top-0 m-0 w-100"
                      >
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "80%",
                          }}
                          className="ms-1 my-1 text-nowrap text-light"
                        >
                          {this.state.file?.name}
                        </p>
                      </motion.div>
                      {this.state.imageHovered === this.state.file?.md5 &&
                      !this.state.processingFiles ? (
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_scale_1}
                          animate={t.normalize}
                          initial={t.fade_out}
                          className="position-absolute top-0 end-0 m-0 w-100"
                        >
                          <MDBBtn
                            onClick={(e) =>
                              this.removeFile(this.state.file?.md5, e)
                            }
                            className="text-danger p-2 ms-auto d-block"
                            color="link"
                            style={{ zIndex: 20 }}
                            size="lg"
                            disabled={
                              this.state.processingFiles || this.state.working
                            }
                          >
                            <i className="far fa-trash-alt fa-lg" />
                          </MDBBtn>
                        </motion.div>
                      ) : (
                        <></>
                      )}
                      <motion.p
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        style={{ textOverflow: "ellipsis" }}
                        className="file-labels-dark position-absolute bottom-0 m-0 w-100 text-center text-light"
                      >
                        {h.getFileSize(this.state.file?.size)}
                      </motion.p>
                    </div>
                  </MDBRipple>
                </div>
                <div className="w-50">
                  <div className="mt-2">
                    <MDBCheckbox
                      onChange={this.changeImageOptions}
                      checked={this.state.imageOptions.commentsDisabled}
                      className="check-commentsDisabled"
                      id="check-commentsDisabled-main"
                      name="commentsDisabled"
                      label="Disable Comments"
                      labelClass="mb-0"
                    />
                    <MDBCheckbox
                      className="check-nsfw"
                      id="check-nsfw-main"
                      onChange={this.changeImageOptions}
                      checked={this.state.imageOptions.nsfw}
                      name="nsfw"
                      label="Mark NSFW"
                      labelClass="mb-0"
                    />
                    <MDBCheckbox
                      className="check-hidden"
                      id="check-hidden-main"
                      onChange={this.changeImageOptions}
                      checked={this.state.imageOptions.hidden}
                      name="hidden"
                      label="Hide from Browse"
                      labelClass="mb-0"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {this.state.working ? (
              <MDBBtn size="lg" block disabled color="success">
                <Spinner size="sm" className="me-2"></Spinner>Sending
              </MDBBtn>
            ) : (
              <MDBBtn onClick={this.submit} size="lg" block color="success">
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
          </div>
        </Collapse>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { add_comment })(
  withGoogleReCaptcha(AddCommentForm)
);
