import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import axios from "axios";
import { contact_form_schema } from "../utilities/validations";
import { route } from "../redux/actions";
import "./contact/styles.css";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import Spinner from "../components/Spinner";

/**
 * This is the Contact page
 * Users can submit site messages here
 */

const fields = [
  {
    id: "name",
    text: "Name",
    type: "input",
    col: "4",
  },
  {
    id: "email",
    text: "Email",
    type: "input",
    col: "4",
  },
  {
    id: "subject",
    text: "Subject",
    type: "input",
    col: "4",
  },
  {
    id: "message",
    text: "Message",
    type: "textarea",
    col: "12",
  },
];

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean indicating whether the form is in the process of being submitted
       */
      working: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
    };
  }

  componentDidMount() {
    //document.getElementById('form').addEventListener('submit', this.submit);
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      {
        ...this.state,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      },
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          contact_form_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState({
            ...this.state,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          });
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            {
              ...this.state,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            },
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error);
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Make request to server
   * Redirect to "Thank you" page
   */
  submit = () => {
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        {
          ...this.state,
          working: true,
        },
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            contact_form_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await h.getRecaptcha(
              this.props.googleReCaptchaProps
            );
            const fd = new FormData();
            for (const key in data) {
              fd.append(key, data[key]);
            }
            fd.append("captchaKey", captchaKey);
            axios
              .post("/contact/contact-form", fd)
              .then((res) => {
                if (res.data.error)
                  this.setState(
                    {
                      ...this.state,
                      working: false,
                    },
                    () => alert(res.data.error)
                  );
                else this.props.route("/received");
              })
              .catch((err) =>
                this.setState(
                  {
                    ...this.state,
                    working: false,
                  },
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              {
                ...this.state,
                working: false,
              },
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    console.log(e, e.key);
    if (e.key === "Enter") this.submit();
  };

  render() {
    return (
      <motion.div
        className="min-h-100 py-4"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBContainer>
          <h1 className="display-4 text-center">Contact</h1>
          <hr></hr>
          <MDBValidation
            id="form"
            className="row mt-4"
            method="dialog"
            name="form"
          >
            {fields.map((i) => (
              <MDBValidationItem
                key={i.id}
                className={`pb-4 col-12 col-md-${i.col}`}
                feedback={
                  this.state.inputs.find((input) => input.id === i.id).error
                }
                invalid={true}
              >
                {i.type === "input" ? (
                  <MDBInput
                    name={i.id}
                    onChange={this.changeHandler}
                    id={i.id}
                    label={i.text}
                    size="lg"
                    className={
                      !this.state.inputs.find((input) => input.id === i.id)
                        .invalid
                        ? "mb-0"
                        : 0
                    }
                    onKeyPress={this.pressEnter}
                  />
                ) : (
                  <MDBTextArea
                    name={i.id}
                    onChange={this.changeHandler}
                    id={i.id}
                    label={i.text}
                    size="lg"
                    className={
                      !this.state.inputs.find((input) => input.id === i.id)
                        .invalid
                        ? "mb-0"
                        : 0
                    }
                    style={{ minHeight: "10rem" }}
                  />
                )}
              </MDBValidationItem>
            ))}
          </MDBValidation>
          <div className="row">
            <div className="col-12">
              {this.state.working ? (
                <MDBBtn color="primary" size="lg" disabled>
                  <Spinner size="sm" className="me-2" />
                  Sending
                </MDBBtn>
              ) : (
                <MDBBtn
                  onClick={this.submit}
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  <i className="fas fa-paper-plane me-2"></i>Send
                </MDBBtn>
              )}
            </div>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(
  withGoogleReCaptcha(Contact)
);
